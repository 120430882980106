<template>
  <span v-if="time_counter > 0">{{ minutes }}:{{ seconds }}</span>
  <span v-else>Время вышло</span>
</template>
<script>
export default {
  props: ["start_time", "autostart"],
  data() {
    return {
      time_counter: 0,
      timer: null,
    }
  },
  computed: {
    minutes() {
      return Math.floor(this.time_counter / 60)
    },
    seconds() {
      const secondsInt = this.time_counter % 60
      return secondsInt < 10 ? `0${secondsInt}` : secondsInt
    },
  },
  mounted() {
    this.time_counter = this.start_time
    if (this.autostart) {
      this.startTimer()
    }
  },
  beforeUnmount() {
    this.stopTimer()
  },
  methods: {
    startTimer() {
      if (this.timer) {
        return
      }
      this.timer = setInterval(() => {
        this.time_counter--
        if (this.time_counter <= 0) {
          this.stopTimer()
        }
      }, 1000)
    },
    stopTimer() {
      clearInterval(this.timer)
      this.timer = null
    },
  },
}
</script>

<style></style>
