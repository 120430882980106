<template>
  <Layout url_prefix="admin">
    <template #page_header>
      <div v-if="!editMode" class="h1">{{ title }}</div>
      <div v-else style="padding-bottom: 20px">
        <input class="h1" type="text" v-model="title" />
      </div>
    </template>

    <template #page_content>
      <div v-if="editMode">
        <div class="buttons-container" v-if="userProfile?.group == 'admins'">
          <Button @click="saveContent">Сохранить</Button>
          <Button @click="editMode = false" class="button_purple"
            >Отменить редактирование</Button
          >
        </div>
        <div class="content">
          <div class="editor-left-column">
            <QuillEditor
              v-if="contentIsReady == true"
              toolbar="full"
              theme="snow"
              v-model:content="leftColumn"
              contentType="html"
            />
          </div>
          <div class="editor-right-column">
            <QuillEditor
              v-if="contentIsReady == true"
              class="right-column"
              toolbar="full"
              theme="snow"
              v-model:content="rightColumn"
              contentType="html"
            />
          </div>
        </div>
      </div>

      <div v-else class="ql-editor content-container">
        <div class="buttons-container" v-if="userProfile?.group == 'admins'">
          <Button @click="editMode = true">Редактировать</Button>
        </div>
        <div class="content">
          <div
            class="left-column"
            ref="leftColumn"
            v-html="renderedLeftColumn"
            @click.prevent="handleAnchorClick"
          ></div>
          <div
            class="right-column"
            ref="rightColumn"
            v-html="renderedRightColumn"
          ></div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import { content } from "@/api"

import Button from "@/components/UI/Button.vue"
import Layout from "@/components/Layouts/Layout.vue"
import { mapState } from "vuex"

import "@vueup/vue-quill/dist/vue-quill.snow.css"
import "@vueup/vue-quill/dist/vue-quill.core.css"
import { QuillEditor } from "@vueup/vue-quill"

export default {
  components: {
    Layout,
    Button,
    QuillEditor,
  },
  data() {
    return {
      title: "",
      leftColumn: "",
      rightColumn: "",
      renderedLeftColumn: "",
      renderedRightColumn: "",
      contentIsReady: false,
      editMode: false,
    }
  },
  mounted() {
    this.fetchContent()
    this.fetchEditorContent()
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  methods: {
    async fetchContent() {
      const response = await content.get(true)
      this.title = response.title
      this.renderedLeftColumn = response.leftColumn
      this.renderedRightColumn = response.rightColumn
    },
    async fetchEditorContent() {
      const response = await content.get()
      this.title = response.title
      this.leftColumn = response.leftColumn
      this.rightColumn = response.rightColumn
      this.contentIsReady = true
    },
    async saveContent() {
      const response = await content.set(
        this.title,
        this.leftColumn,
        this.rightColumn
      )
      this.fetchContent()
      this.editMode = false
    },
    scrollToRef(ref_name) {
      // this.$refs[ref_name].scrollIntoView({ behavior: "smooth" })
      const rightColumn = this.$refs.rightColumn
      const el = rightColumn.querySelector("#" + ref_name)
      el.scrollIntoView({ behavior: "smooth" })
    },
    handleAnchorClick(event) {
      const ref_name = event.target.href.split("#")[1]
      this.scrollToRef(ref_name)
    },
  },
  watch: {},
}
</script>

<style scoped>
.buttons-container {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  gap: 20px;
}
.content {
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 150px;
}
.content .left-column {
  min-width: 310px;
}
.content .right-column {
}
.ql-editor {
  height: unset !important;
}
.content >>> .ql-editor {
  height: unset !important;
}
.ql-editor >>> a {
  color: var(--greenz_link);
}
</style>
