<template>
  <div class="answers_form">
    <div class="answers_form__header">Ответы:</div>
    <div class="answers_hint">
      <div class="answers_hint_text">
        Введите правильный ответ (знаки . и , равнозначны)
      </div>
    </div>
    <div class="answer_row">
      <input
        class="answer_input"
        type="number"
        v-model="correct"
        @keyup="emitUpdate"
        @change="emitUpdate"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["question"],
  emits: ["update"],
  data() {
    return {
      correct: "",
    }
  },
  mounted() {
    if (this.question.correct && !Array.isArray(this.question.correct)) {
      this.correct = this.question.correct
    }
  },
  methods: {
    render() {
      return {
        correct: this.correct,
      }
    },
    emitUpdate() {
      this.$emit("update", this.render())
    },
  },
}
</script>

<style scoped>
.answer_input {
  width: 100%;
  font-size: 17px;
}
.answer_row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 242px;
  padding-bottom: 10px;
}
.answers_hint_text {
  padding-right: 10px !important;
}
</style>
