<template>
  <Layout url_prefix="teacher">
    <template #page_header>
      <div class="h1">Создать ученика</div>
    </template>
    <div class="right_shifted_form">
      <StudentForm @form_data_changed="formDataChanged" @enter_pressed="createStudent" ref="studentCreate"></StudentForm>
      <div class="form_buttons">
        <div class="buttons__container__without_grow">
          <Button class="button_top" @click="createStudent"
            >Создать ученика</Button
          >
          <Button
            class="button_gray"
            @click="$router.push({ name: 'teacher-index' })"
            >Выйти без сохранения</Button
          >
        </div>
      </div>
    </div>
    <Alert ref="alert" />
    <div class="spacer200"></div>
  </Layout>
</template>

<script>
import { students } from "@/api"
import Button from "@/components/UI/Button"
import Layout from "@/components/Layouts/Layout"
import StudentForm from "@/components/Forms/StudentForm.vue"
import Alert from "@/components/Functional/Alert.vue"

export default {
  components: {
    Layout,
    Button,
    StudentForm,
    Alert,
  },
  data() {
    return {
      student: null,
    }
  },
  methods: {
    formDataChanged(data) {
      this.student = data
    },
    async createStudent() {
      const form_checked = this.$refs.studentCreate.checkFields()
      if (this.student) {
        try {
          const student = await students.create(this.student)
          this.$store.dispatch("fetchStudents")
          this.$refs.alert.alert("Студент создан", () => {
            this.$router.push({
              name: "teacher-student-edit",
              params: { id: student.id },
            })
          })
        } catch (error) {
          console.log(error)
          if (
            error.response.data.email &&
            error.response.data.email == "Email already exists"
          ) {
            this.$refs.alert.show(
              "Не удалось создать ученика. Пользователь с таким email уже существует в базе данных."
            )
          } else {
            this.$refs.alert.alert(
              "Не удалось создать ученика. Проверьте правильность заполнения всех полей."
            )
          }
        }
      }
    },
  },
}
</script>

<style scoped>
.right_shifted_form >>> .centered_form {
  margin-left: -303px;
  margin-bottom: 15px;
}
</style>
