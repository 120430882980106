<template>
  <div class="test_filter">
    <div
      :class="{
        'test_filter__tab_header-active': activeTestsTab == 'base_tests',
      }"
      class="test_filter__tab_header"
      @click="onTabChanged('base_tests')"
    >
      <span class="test_filter__tab_header__counter">{{ base_tests_count }}</span>
      Каталог тестов
    </div>
    <div
      :class="{
        'test_filter__tab_header-active': activeTestsTab == 'school_tests',
      }"
      class="test_filter__tab_header"
      @click="onTabChanged('school_tests')"
    >
      <span class="test_filter__tab_header__counter">{{ school_tests_count }}</span>
      Тесты школы
    </div>
    <div class="test_filter__grades">
      <div 
        v-for="grade_number in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
        :class="{'test_filter__grade-active': selectedGrade == grade_number}" 
        @click="onGradeFilter(grade = grade_number)" 
        class="test_filter__grade"
      >{{ grade_number }}</div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex"

export default {
  props: {
    base_tests_count: 0,
    school_tests_count: 0,
    active_tab: null
  },
  data() {
    return {
        activeTestsTab: 'school_tests',
        selectedGrade: 1,
    }
  },
  mounted() {
    if (this.active_tab){
      // console.log("active_tab " + this.active_tab) ;
      this.activeTestsTab = this.active_tab
    } else {
      // console.log("!active_tab " + this.$store.state.activeTestTab);
      this.activeTestsTab = this.$store.state.activeTestTab
    }
    this.selectedGrade = this.$store.state.selectedGrade
    this.$emit("gradeSelected", this.selectedGrade)
  },
  methods: {
    onTabChanged(tab_name) {
        this.activeTestsTab = tab_name
        this.$store.commit("setActiveTestTab", {'active_tab_name': tab_name})
        this.$emit("tabChanged", tab_name)
    },
    onGradeFilter(grade) {
        this.selectedGrade = grade
        this.$store.commit("setTestGrade", {'active_grade': grade})
        this.$emit("gradeSelected", grade)
    },
  },
}
</script>

<style>
.test_filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}
.test_filter__tab_header {
  font-size: 21px;
  font-weight: 700;
  line-height: 21px;
  height: fit-content;
  cursor: pointer;
  position: relative;
  border-bottom: 2px dashed;
  color: var(--greenz_link);
}
.test_filter__tab_header__counter {
  display: block;
  position: absolute;
  top: -10px;
  right: -33px;
  font-size: 11px;
  width: 30px;
  color: var(--gray3);
}

.test_filter__tab_header-active {
  border-bottom: none;
  color: var(--gray1);
}

.test_filter__grades {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.test_filter__grades:before {
  content: "Класс:";
}
.test_filter__grade {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray6);
  border-radius: 20px;
  font-size: 21px;
  font-weight: 700;
  cursor: pointer;
  color: var(--gray3);
}
.test_filter__grade-active,
.test_filter__grade:hover {
  background-color: var(--greenz);
  color: var(--gray1);
}
</style>
