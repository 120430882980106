export function convertToCamelCase(str, firstLetterUppercase) {
  let result = ""
  let words = str.split("_")
  firstLetterUppercase = firstLetterUppercase || false

  for (let i = 0; i < words.length; i++) {
    let word = words[i]
    if (i === 0 && !firstLetterUppercase) {
      result += word
    } else {
      result += word.charAt(0).toUpperCase() + word.slice(1)
    }
  }

  return result
}
