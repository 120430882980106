<template>
  <Layout url_prefix="student">
    <template #page_header>
      <div class="h1">
        Профиль ученика <br />
        <!-- {{ userProfile.fio }} -->
        {{ userProfile.fio }}, {{ userProfile.grade.full_name }} класс
        <!-- [Имя+фамилия], «[текущий класс]» класс -->
      </div>
    </template>

    <template #page_content>
      <div class="right_shifted_form">
        <div class="form school_form">
          <div class="centered_form__input_row">
            <label for="email">Адрес почты</label>
            <input
              type="email"
              name="email"
              v-model="email"
              class="profile_input"
            />
          </div>
          <div class="centered_form__input_row">
            <label for="password">Новый пароль</label>
            <input
              type="password"
              name="password"
              v-model="password"
              class="profile_input"
            />
          </div>
          <div class="centered_form__input_row">
            <label for="password_repeat">Повторите пароль</label>
            <input
              type="password"
              name="password_repeat"
              v-model="password_repeat"
              class="profile_input"
            />
          </div>
          <div class="form_buttons">
            <div class="buttons__container__without_grow">
              <Button class="button_top" @click="save">Сохранить</Button>
              <Button @click="exit" class="button_gray"
                >Выйти без сохранения</Button
              >
            </div>
          </div>
        </div>
      </div>
    </template>
    <Alert ref="alert" />
  </Layout>
</template>

<script>
import { changeOwnPassword, refreshProfile } from "@/api/auth"
import { students } from "@/api"
import { mapState } from "vuex"

import Button from "@/components/UI/Button"
import Layout from "@/components/Layouts/Layout"
import Alert from "@/components/Functional/Alert.vue"

export default {
  components: {
    Layout,
    Button,
    Alert,
  },
  data() {
    return {
      email: "",
      password: "",
      password_repeat: "",
    }
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  methods: {
    async save() {
      if (this.password && this.password !== this.password_repeat) {
        this.$refs.alert.alert("Пароли не совпадают")
        return
      }

      try {
        await students.update(this.userProfile.id, {
          fio: this.fio,
          email: this.email,
        })
      } catch (e) {
        this.$refs.alert.alert(
          "Возникла ошибка. Возможно, адрес электронной почты уже занят."
        )
        return
      }

      if (this.password) {
        try {
          await changeOwnPassword(this.password)
        } catch (e) {
          this.$refs.alert.alert("Возникла ошибка при сохранении пароля.")
          return
        }
      }

      await refreshProfile()
      this.$refs.alert.alert("Данные сохранены.", () => {
        this.$router.push({ name: "student-index" })
      })
    },
    exit() {
      this.$router.push({ name: "student-index" })
    },
  },
  watch: {
    userProfile: {
      handler(profile) {
        if (profile) {
          this.email = profile.email
        }
      },
      immediate: true,
    },
  },
}
</script>

<!-- <style scoped>
.profile_input {
  width: 380px !important;
  margin-right: -50px;
}
</style> -->
<style scoped>
.centered_form__input_row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile_input {
  margin-right: 20px;
  width: 200px;
}
.form_buttons {
  margin-left: 330px;
  width: 600px;
  padding-top: 10px;
}
</style>
