<template>
  <Layout url_prefix="director">
    <template #page_header>
      <div class="h1">Профиль</div>
    </template>

    <template #page_content>
      <div class="right_shifted_form">
        <div class="form school_form">
          <div class="centered_form__input_row">
            <label for="fio">ФИО</label>
            <input type="text" name="fio" v-model="fio" class="profile_input" />
          </div>
          <div class="centered_form__input_row">
            <label for="email">Адрес почты</label>
            <input
              type="email"
              name="email"
              v-model="email"
              class="profile_input"
            />
          </div>
          <div class="centered_form__input_row">
            <label for="password">Новый пароль</label>
            <input
              type="password"
              name="password"
              v-model="password"
              class="profile_input"
            />
          </div>
          <div class="centered_form__input_row">
            <label>Фото</label>
            <SelectAvatarInput
              @imageLoaded="setAvatar"
              @imageRemoved="removeAvatar"
              :base64_image="avatar_base64"
              style="width: 275px"
            ></SelectAvatarInput>
          </div>
          <div class="centered_form__input_row">
            <label for="password_repeat">Повторите пароль</label>
            <input
              type="password"
              name="password_repeat"
              v-model="password_repeat"
              class="profile_input"
            />
          </div>
          <div class="form_buttons">
            <div class="buttons__container__without_grow">
              <Button class="button_top" @click="save">Сохранить</Button>
              <Button @click="exit" class="button_gray"
                >Выйти без сохранения</Button
              >
            </div>
          </div>
        </div>
      </div>
      <Alert ref="alert" />
    </template>
  </Layout>
</template>

<script>
import { changeOwnPassword, refreshProfile } from "@/api/auth"
import { directors } from "@/api"
import { mapState } from "vuex"

import Button from "@/components/UI/Button"
import Layout from "@/components/Layouts/Layout"
import Alert from "@/components/Functional/Alert.vue"
import SelectAvatarInput from "@/components/UI/SelectAvatarInput.vue"

export default {
  components: {
    Layout,
    Button,
    Alert,
    SelectAvatarInput,
  },
  data() {
    return {
      fio: "",
      email: "",
      password: "",
      password_repeat: "",
      avatar_base64: "",
    }
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  methods: {
    async save() {
      if (this.password && this.password !== this.password_repeat) {
        this.$refs.alert.alert("Пароли не совпадают")
        return
      }

      try {
        await directors.update(this.userProfile.id, {
          fio: this.fio,
          email: this.email,
          avatar_base64: this.avatar_base64,
        })
      } catch (e) {
        this.$refs.alert.alert(
          "Возникла ошибка. Возможно, адрес электронной почты уже занят."
        )
        return
      }

      if (this.password) {
        try {
          await changeOwnPassword(this.password)
        } catch (e) {
          this.$refs.alert.alert("Возникла ошибка при сохранении пароля.")
          return
        }
      }

      await refreshProfile()
      this.$refs.alert.alert("Данные сохранены.", () => {
        this.$router.push({ name: "director-index" })
      })
    },
    exit() {
      this.$router.push({ name: "director-index" })
    },
    setAvatar(base64) {
      this.avatar_base64 = base64
    },
    removeAvatar() {
      this.avatar_base64 = null
    },
  },
  watch: {
    userProfile: {
      handler(profile) {
        if (profile) {
          this.fio = profile.fio
          this.email = profile.email
          this.avatar_base64 = profile.avatar_base64
        }
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
/* .centered_form__input_row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.profile_input {
  width: 200px;
}
.school_form {
  margin-left: -303px;
}
.form_buttons {
  margin-left: 330px;
  width: 600px;
  padding-top: 10px;
}
</style>
