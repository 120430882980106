<template>
  <Layout url_prefix="teacher">
    <template #page_header>
      <div class="h1">Редактировать класс</div>
    </template>

    <div class="right_shifted_form">
      <GradeForm
        v-if="grade"
        @form_data_changed="formDataChanged"
        @removeStudent="removeStudent"
        @addNewStudent="addNewStudent"
        :grade="grade"
        :students="students"
        :hide_grade="true"
      ></GradeForm>
      <div class="form_buttons">
        <p>
          После нажатия на “Создать класс” в системе будет сформирована карточка
          преподавателя, а на указанный адрес почты отправятся реквизиты для
          входа.
        </p>
        <div class="buttons__container__without_grow">
          <Button class="button_top" @click="save">Сохранить класс</Button>
          <Button
            class="button_gray"
            @click="$router.push({ name: 'teacher-index' })"
            >Выйти без сохранения</Button
          >
        </div>
      </div>
    </div>
    <Alert ref="alert" />
    <div class="spacer200"></div>
  </Layout>
</template>

<script>
import { grades } from "@/api"
import { students } from "@/api"

import Alert from "@/components/Functional/Alert.vue"
import Button from "@/components/UI/Button"
import Layout from "@/components/Layouts/Layout"
import GradeForm from "@/components/Forms/GradeForm.vue"

export default {
  props: ["id"],
  components: {
    Alert,
    Layout,
    Button,
    GradeForm,
  },
  data() {
    return {
      grade: null,
      students: null,
      form_data: null,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.getStudents()
      await this.getGrade()
    },
    async getStudents() {
      const response = await students.list({ grade_id: this.id })
      this.students = response
    },
    async getGrade() {
      const response = await grades.get(this.id)
      this.grade = response
    },
    formDataChanged(data) {
      this.form_data = data
    },
    async removeStudent(student_id) {
      await students.delete(student_id)
      await this.getStudents()
      this.$store.dispatch("fetchStudents")
    },
    async addNewStudent(student) {
      student.grade = this.grade.id
      try {
        await students.create(student)
      } catch (error) {
        if (error.response.data.email) {
          this.$refs.alert.alert(
            "Ученик с таким адресом электронной почты уже зарегистрирован в системе."
          )
        } else {
          this.$refs.alert.alert(
            "Не удалось создать студента. Пожалуйста, проверьте правильность заполнения всех полей."
          )
        }
        return
      }
      await this.getStudents()
      this.$store.dispatch("fetchStudents")
    },
    async save() {
      await grades.update(this.grade.id, this.form_data)
      this.$router.push({ name: "teacher-index" })
    },
  },
}
</script>

<style scoped>
.form_buttons p {
  margin-top: 20px;
  margin-bottom: 30px;
}
</style>
