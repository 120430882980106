<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div v-click-outside="close" class="modal-container">
          <img
            @click="close"
            class="close_button"
            src="@/assets/img/close_icon.svg"
          />
          <slot></slot>
          <!-- <div class="modal-header">
            <slot name="header">default header!</slot>
          </div>

          <div class="modal-body">
            <slot name="body">default body</slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              default footer
              <button
                class="modal-default-button"
                @click="$emit('need_to_close')"
              >
                OK
              </button>
            </slot>
          </div> -->
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import vClickOutside from "click-outside-vue3"
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    // search_results_to_display() {
    //   return map(this.search_results, (search_result) =>
    //     pick(search_result, this.fields_to_display)
    //   )
    // },
  },
  methods: {
    onClickOutside(event) {
      this.$emit("need_to_close")
      // this.state_visible = false
    },
    close() {
      this.show = false
    },
    open() {
      this.show = true
    },
  },
}
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  position: relative;
  width: 600px;
  margin: 0px auto;
  padding: 60px 45px;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.close_button {
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
<style scoped>
.modal-container >>> .buttons__container__without_grow {
  justify-content: center !important;
}
.modal-container >>> .buttons__container {
  justify-content: center !important;
}

.text_editor_container >>> .tox.tox-tinymce{
  border-radius: 0 0 25px 25px;
  margin-bottom: 20px;
  width: 100%;
  /* border: 1px solid #ccc; */
}

.text_editor_container >>> .tox-editor-header {
  /* border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc; */
}
.text_editor_container >>> .tox .tox-edit-area__iframe {
  background-color: #f9f9f9;
}
</style>
