export function cropToBox(base64, width, height) {
  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = () => {
      const aspectRatio = img.width / img.height
      let sourceWidth = img.width
      let sourceHeight = img.height
      let sourceX = 0
      let sourceY = 0

      // if image is already fits the box, return it
      if (sourceWidth <= width && sourceHeight <= height) {
        resolve(base64)
        return
      }

      if (aspectRatio > 1) {
        sourceWidth = img.height
        sourceX = (img.width - sourceWidth) / 2
      } else {
        sourceHeight = img.width
        sourceY = (img.height - sourceHeight) / 2
      }

      const canvas = document.createElement("canvas")
      canvas.width = width
      canvas.height = height

      const ctx = canvas.getContext("2d")
      ctx.drawImage(
        img,
        sourceX,
        sourceY,
        sourceWidth,
        sourceHeight,
        0,
        0,
        width,
        height
      )

      resolve(canvas.toDataURL())
    }

    img.onerror = reject

    img.src = base64
  })
}

export function scaleToBox(base64, width, height) {
  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = () => {
      const aspectRatio = img.width / img.height
      let sourceWidth = img.width
      let sourceHeight = img.height
      let targetWidth = width
      let targetHeight = height

      // if image is already fits the box, return it
      if (sourceWidth <= width && sourceHeight <= height) {
        resolve(base64)
        return
      }

      // resizes the image to fit the box
      if (aspectRatio > 1) {
        targetHeight = width / aspectRatio
      } else {
        targetWidth = height * aspectRatio
      }

      const canvas = document.createElement("canvas")
      canvas.width = targetWidth
      canvas.height = targetHeight

      const ctx = canvas.getContext("2d")
      ctx.drawImage(img, 0, 0, targetWidth, targetHeight)

      resolve(canvas.toDataURL())
    }

    img.onerror = reject

    img.src = base64
  })
}

export function resize(base64, width, height) {
  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = () => {
      const canvas = document.createElement("canvas")
      canvas.width = width
      canvas.height = height

      const ctx = canvas.getContext("2d")
      ctx.drawImage(img, 0, 0, width, height)

      resolve(canvas.toDataURL())
    }

    img.onerror = reject

    img.src = base64
  })
}

export function convertToJpeg(base64) {
  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = () => {
      const canvas = document.createElement("canvas")
      canvas.width = img.width
      canvas.height = img.height

      const ctx = canvas.getContext("2d")
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

      resolve(canvas.toDataURL("image/jpeg"))
    }
    img.onerror = reject
    img.src = base64
  })
}

export function cropAndConvertAllImagesInHtml(html, width, height) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, "text/html")

  const images = doc.querySelectorAll("img")

  const promises = []

  for (let i = 0; i < images.length; i++) {
    const img = images[i]
    const src = img.getAttribute("src")

    if (src.startsWith("data:")) {
      promises.push(
        scaleToBox(src, width, height)
          .then((cropped) => {
            // if cropped image is not of type of jpeg, convert it
            if (!cropped.startsWith("data:image/jpeg")) {
              console.log("needs to convert")
              return convertToJpeg(cropped)
            }
            return cropped
          })
          .then((converted) => {
            img.setAttribute("src", converted)
          })
      )
    }
  }

  return Promise.all(promises).then(() => doc.body.innerHTML)
}
