<template>
  <div class="answers_form">
    <div class="answers_form__header">Ответы:</div>
    <div class="answers_hint">
      <div class="answers_hint_text">задайте правильные пары соответствий</div>
    </div>
    <div class="answer_hint_2">
      (ответы будут показаны ученикам в случайном порядке)
    </div>
    <div class="answer_row" v-for="pair in pairs" :key="pair.id">
      <AnswerField
        :hideRemoveAdd="true"
        :answer="pair.left"
        @change="emitUpdate"
      />
      <div class="equal_sign">=</div>
      <AnswerField
        :answer="pair.right"
        @remove="remove(pair)"
        @add="add"
        @change="emitUpdate"
        class="match_answerfield"
      />
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash"
import AnswerField from "@/components/Forms/TestForm/AnswerField.vue"

export default {
  components: {
    AnswerField,
  },
  props: ["question"],
  emits: ["update"],
  data() {
    return {
      pairs: [
        { id: "1", left: { text: "" }, right: { text: "" } },
        { id: "2", left: { text: "" }, right: { text: "" } },
        { id: "3", left: { text: "" }, right: { text: "" } },
      ],
    }
  },
  mounted() {
    if (this.question.left && Array.isArray(this.question.correct)) {
      let id = 1
      this.pairs = []
      this.question.left.forEach((left) => {
        const correct = this.question.correct.find((c) => c[0] == left.id)
        const right = this.question.right.find((r) => r.id == correct[1])
        this.pairs.push({
          id: left.id,
          left: left,
          right: right,
        })
        id++
      })
    }
  },
  methods: {
    render() {
      const pairs = cloneDeep(this.pairs)
      const ids = pairs.map((p) => p.id)

      // randomize ids order
      ids.sort(() => Math.random() - 0.5)

      // assign new ids to pairs
      pairs.forEach((a, index) => {
        a.id = ids[index]
      })

      const left = []
      const right = []
      const correct = []

      pairs.forEach((pair, index) => {
        const left_id = (index + 1).toString()
        const right_id = pair.id
        left.push({ ...pair.left, id: left_id })
        right.push({ ...pair.right, id: right_id })
        correct.push([left_id, right_id])
      })

      left.sort(() => Math.random() - 0.5)
      right.sort(() => Math.random() - 0.5)
      correct.sort(() => Math.random() - 0.5)

      return {
        left: left,
        right: right,
        correct: correct,
      }
    },
    emitUpdate() {
      this.$emit("update", this.render())
    },
    getMaxId() {
      const maxId = this.pairs.reduce(
        (max, pair) => Math.max(max, parseInt(pair.id)),
        0
      )
      return maxId
    },
    createPair() {
      return {
        id: (this.getMaxId() + 1).toString(),
        left: { text: "" },
        right: { text: "" },
      }
    },
    add() {
      this.pairs.push(this.createPair())
      this.emitUpdate()
    },
    remove(pair) {
      this.pairs = this.pairs.filter((p) => p.id !== pair.id)
      this.emitUpdate()
    },
  },
}
</script>

<style scoped>
.answers_form {
  width: 100%;
}
.answers_hint_text {
  padding-right: 10px !important;
}
.answer_row {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 242px;
  padding-bottom: 10px;
  gap: 5px;
}
.answer_hint_2 {
  position: absolute;
  max-width: 225px;
  text-align: right;
}
.equal_sign {
  padding-top: 16px;
}
.match_answerfield >>> .answer_video iframe,
.match_answerfield >>> .answer_photo {
  width: 240px !important;
}
</style>
