<template>
  <span>{{ timeString }}</span>
</template>
<script>
export default {
  props: { time: Number, format: { type: String, default: "%mm:%ss" } },
  computed: {
    minutes() {
      return Math.floor(this.time / 60)
    },
    seconds() {
      const secondsInt = this.time % 60
      return secondsInt < 10 ? `0${secondsInt}` : secondsInt
    },
    timeString() {
      return this.format
        .replace("%ss", this.seconds)
        .replace("%mm", this.minutes)
    },
  },
}
</script>
