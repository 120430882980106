<template>
  <Layout url_prefix="student">
    <template #page_header v-if="session && schema && currentQuestionId">
      <div class="h1">
        <div class="test_badge">тест</div>
        {{ session.assignment.test.name }}, вар. {{ session.variant }}
      </div>
    </template>

    <template v-if="session && schema && currentQuestionId" #page_content>
      <div class="test_container">
        <div class="question_container" v-if="session.status == 'P'">
          <div class="h3">Тестирование на паузе</div>
        </div>
        <div
          class="question_container"
          :class="'question_container__' + currentQuestion.type"
          v-else
        >
          <div class="current_question">
            Вопрос {{ currentQuestionIndex + 1 }} из {{ lastQuestionIndex + 1 }}
          </div>

          <div class="question_text h3" v-html="currentQuestion.question"></div>

          <div
            class="question_description"
            v-html="currentQuestion.description"
            v-if="currentQuestion.type != 'skips'"
          ></div>

          <!-- Single -->
          <template v-if="currentQuestion.type == 'single'">
            <div class="answer_header">Выберите правильный вариант ответа:</div>
            <div
              :class="[
                'answer_variants',
                'keyboard_' + currentQuestionKeyboardType,
              ]"
            >
              <template
                v-for="answer in currentQuestion.answers"
                :key="answer.id"
              >
                <Button
                  v-if="answer.text || answer.photo_id || answer.video_url"
                  @click="answerSingle(answer.id)"
                  :class="{
                    selected:
                      answers[currentQuestionId] == answer.id.toString(),
                  }"
                >
                  {{ answer.text }}
                  <div class="match_img_container" v-if="answer.photo_id">
                    <img
                      class="match_answer_photo"
                      :src="media_url + answer.photo_url"
                    />
                  </div>
                  <div class="mt10" v-if="answer.video_url">
                    <YouTubePlayer
                      :src="answer.video_url"
                      :width="240"
                    ></YouTubePlayer>
                  </div>
                </Button>
              </template>
            </div>
          </template>

          <!-- Multiple -->
          <template v-if="currentQuestion.type == 'multiple'">
            <div class="answer_header">
              Выберите правильные варианты ответов:
            </div>
            <div
              :class="[
                'answer_variants',
                'keyboard_' + currentQuestionKeyboardType,
              ]"
            >
              <template
                v-for="answer in currentQuestion.answers"
                :key="answer.id"
              >
                <Button
                  v-if="answer.text || answer.photo_id || answer.video_url"
                  @click="answerMultiple(answer.id)"
                  :class="{
                    selected:
                      answers[currentQuestionId] &&
                      answers[currentQuestionId].indexOf(answer.id.toString()) >
                        -1,
                  }"
                >
                  {{ answer.text }}
                  <div class="match_img_container" v-if="answer.photo_id">
                    <img
                      class="match_answer_photo"
                      :src="media_url + answer.photo_url"
                    />
                  </div>
                  <div class="mt10" v-if="answer.video_url">
                    <YouTubePlayer
                      :src="answer.video_url"
                      :width="240"
                    ></YouTubePlayer>
                  </div>
                </Button>
              </template>
            </div>
          </template>

          <!-- Match -->
          <template v-if="currentQuestion.type == 'match'">
            <div class="answer_header">
              Составьте правильные пары с помощью перетаскивания вариантов
            </div>

            <div class="match_container">
              <div class="left_match_column">
                <ul class="left_match_list">
                  <li v-for="left in currentQuestion.left" :key="left.id">
                    <div class="border_holder"></div>
                    <div>{{ left.text }}</div>
                    <div class="match_img_container" v-if="left.photo_id">
                      <img
                        class="match_answer_photo"
                        :src="media_url + left.photo_url"
                      />
                    </div>
                    <div class="mt10" v-if="left.video_url">
                      <YouTubePlayer
                        :src="left.video_url"
                        :width="240"
                      ></YouTubePlayer>
                    </div>
                    <span class="match_answer_arrow icon-purple-arrow"></span>
                  </li>
                </ul>
              </div>
              <div class="right_match_column">
                <draggable
                  tag="ul"
                  class="match_draggable_list right_match_list"
                  :list="getMatchList(currentQuestionId)"
                  item-key="id"
                  @update="answerMatch"
                >
                  <template #item="{ element, index }">
                    <li title="Перетащите на правильное место">
                      <div class="border_holder"></div>
                      <div class="item_text">
                        <div>{{ element.text }}</div>
                        <div
                          class="match_img_container"
                          v-if="element.photo_id"
                        >
                          <img
                            class="match_answer_photo"
                            :src="media_url + element.photo_url"
                          />
                        </div>
                        <div class="mt10" v-if="element.video_url">
                          <YouTubePlayer
                            :src="element.video_url"
                            :width="240"
                          ></YouTubePlayer>
                        </div>
                      </div>
                      <div class="match_handler"></div>
                      <!-- <img
                        class="match_handler"
                        src="@/assets/img/handler.svg"
                      /> -->
                    </li>
                  </template>
                </draggable>
              </div>
            </div>
            <!-- <select @change="answerMatch(left.id, $event)">
                <option
                  :value="null"
                  disabled
                  :selected="
                    !answers[currentQuestionId] ||
                    !answers[currentQuestionId][left.id.toString()]
                  "
                >
                  Выберите правильный вариант ответа
                </option>
                <option
                  v-for="right in currentQuestion.right"
                  :value="right.id"
                  :key="right.id"
                  :selected="
                    answers[currentQuestionId] &&
                    answers[currentQuestionId][left.id.toString()] ==
                      right.id.toString()
                  "
                >
                  {{ right.text }}
                </option>
              </select> -->
          </template>

          <!-- Sequence -->
          <template v-if="currentQuestion.type == 'sequence'">
            <draggable
              tag="ul"
              class="sequence_list"
              :list="getSequenceList(currentQuestion.answers)"
              item-key="id"
              @update="answerSequence"
            >
              <template #item="{ element, index }">
                <li>
                  <div class="answer_text">
                    {{ element.text }}
                    <div class="match_img_container" v-if="element.photo_id">
                      <img
                        class="match_answer_photo"
                        :src="media_url + element.photo_url"
                      />
                    </div>
                    <div class="mt10" v-if="element.video_url">
                      <YouTubePlayer
                        :src="element.video_url"
                        :width="280"
                      ></YouTubePlayer>
                    </div>
                  </div>
                  <div class="handler">
                    <img src="@/assets/img/handler.svg" />
                  </div>
                </li>
              </template>
            </draggable>
          </template>

          <!-- String -->
          <template v-if="currentQuestion.type == 'string'">
            <div class="answer_header">Введите правильный ответ:</div>
            <div class="answer_variants">
              <input
                type="text"
                v-model="answers[currentQuestionId]"
                @keyup.stop
              />
            </div>
          </template>

          <!-- Number -->
          <template v-if="currentQuestion.type == 'number'">
            <div class="answer_header">Введите правильный ответ (число):</div>
            <div class="answer_variants">
              <input
                type="number"
                v-model="answers[currentQuestionId]"
                @keyup.stop
              />
            </div>
          </template>

          <!-- Skips -->
          <template v-if="currentQuestion.type == 'skips'">
            <div v-html="currentQuestion.description"></div>
            <p class="skips_text">
              <template
                v-for="(part, index) in splitSkipsText(
                  currentQuestion.skipstext || ''
                )"
                :key="index"
              >
                <span>{{ part }}</span>
                <PixelionSelect
                  v-if="
                    index <
                    splitSkipsText(currentQuestion.skipstext || '').length - 1
                  "
                  class="answer_skip_select"
                  label="text"
                  v-model="answers[currentQuestionId][index]"
                  :options="currentQuestion.answers"
                  @option:selected="sendAnswer(currentQuestionId)"
                  :searchable="false"
                  :nullable="false"
                />
              </template>
            </p>
          </template>

          <!-- Table -->
          <template v-if="currentQuestion.type == 'table'">
            <div class="answer_header">Заполните пропуски в таблице:</div>
            <div
              v-for="(group, groupIndex) in currentQuestion.groups"
              :key="group.name"
              class="table_group"
            >
              <div class="table_group_name">{{ group.name }}</div>
              <template v-for="rowName in group.rows">
                <div class="table_group_row">
                  <span class="table_group_row_name">{{ rowName }}</span>
                  <input
                    @keyup.stop="answerTable(groupIndex, rowName, $event)"
                    :value="answers[currentQuestionId][groupIndex][rowName]"
                  />
                </div>
              </template>
            </div>
          </template>

          <!-- MatchSelect -->
          <template v-if="currentQuestion.type == 'match_select'">
            <div class="answer_header">
              Выберите правильный вариант в каждой строке:
            </div>

            <div class="match_container">
              <div class="left_match_column">
                <ul class="left_match_list">
                  <li v-for="group in currentQuestion.groups" :key="group.id">
                    <div class="border_holder"></div>
                    <div>{{ group.text }}</div>
                    <div class="match_img_container" v-if="group.photo_id">
                      <img
                        class="match_answer_photo"
                        :src="media_url + group.photo_url"
                      />
                    </div>
                    <div class="mt10" v-if="group.video_url">
                      <YouTubePlayer
                        :src="group.video_url"
                        :width="240"
                      ></YouTubePlayer>
                    </div>
                    <span class="match_answer_arrow icon-purple-arrow"></span>
                  </li>
                </ul>
              </div>
              <div class="right_match_column">
                <ul class="right_match_list">
                  <li v-for="group in currentQuestion.groups" :key="group.id">
                    <!-- <PixelionSelect
                      :options="group.answers"
                      :reduce="(option) => option.text"
                      
                    /> -->
                    <div class="schooltest_select">
                      <select @change="answerMatchSelect(group.id, $event)">
                        <option
                          value=""
                          :selected="
                            !answers[currentQuestionId] ||
                            answers[currentQuestionId][group.id.toString()] ==
                              ''
                          "
                        >
                          Не выбрано
                        </option>

                        <option
                          v-for="answer in group.answers"
                          :value="answer.id"
                          :key="answer.id"
                          :selected="
                            answers[currentQuestionId] &&
                            answers[currentQuestionId][group.id.toString()] ==
                              answer.id.toString()
                          "
                        >
                          {{ answer.text }}
                        </option>
                      </select>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </div>
      </div>

      <!-- --- Controls --- -->
      <div class="controll_panel">
        <div
          class="test_control green"
          v-if="currentQuestionIndex != 0 && session.status != 'P'"
          @click="previousQuestion"
        >
          <span class="icon-arrow-left"></span>
          <div :class="{ muted: currentQuestionIndex == 0 }">
            <div>Предыдущий вопрос</div>
          </div>
        </div>

        <div class="test_control cursor_unset">
          <div>
            Осталось
            <TimeRemaining
              ref="time_remaining"
              :start_time="session.time_remaining"
              :autostart="true"
            ></TimeRemaining>
          </div>
        </div>

        <!-- Pause / Resume -->
        <div
          class="test_control purple"
          v-if="session.status != 'P'"
          @click="pauseSession"
        >
          <span class="icon-pause"></span>
          <div>Пауза</div>
        </div>
        <div class="test_control purple" v-else @click="resumeSession">
          <span class="icon-play"></span>
          <div>Продолжить</div>
        </div>

        <div
          class="test_control green"
          v-if="
            currentQuestionIndex != lastQuestionIndex && session.status != 'P'
          "
          @click="nextQuestion"
        >
          <!-- Next / Finish -->
          <div>Следующий вопрос</div>
          <span class="icon-arrow-right"></span>
        </div>
        <div
          class="test_control green"
          v-else-if="session.status != 'P'"
          @click="finishSession"
        >
          <!-- Next / Finish -->
          <!-- <div>ЗАВЕРШИТЬ</div> -->
          <Button class="button_top">ЗАВЕРШИТЬ</Button>
        </div>
      </div>

      <div class="questions_nav">
        <div v-for="(question, index) in schema" :key="question.id">
          <!-- Текущий вопрос -->
          <span
            class="questions_nav__item questions_nav__item-current"
            v-if="index == currentQuestionIndex"
            >{{ index + 1 }}</span
          >
          <!-- Отвеченный вопрос -->
          <span
            class="questions_nav__item questions_nav__item-answered"
            v-else-if="isAnswered(question.id)"
            @click="goToQuestion(index)"
            >{{ index + 1 }}</span
          >
          <!-- Неотвеченный вопрос -->
          <span
            class="questions_nav__item questions_nav__item-unanswered"
            v-else
            @click="goToQuestion(index)"
            >{{ index + 1 }}</span
          >
        </div>
      </div>

      <div class="progress">
        <div>Пройдено:</div>
        <ProgressBar
          class="status-bar"
          width="411px"
          height="10px"
          :value="progressInPercents"
          bg_color="#fff"
          bar_color="#823BDC"
        ></ProgressBar>
        <div>{{ progressInPercents }}%</div>
      </div>

      <div class="spacer100"></div>
    </template>
  </Layout>
</template>

<script>
import { cloneDeep, filter } from "lodash"
import draggable from "vuedraggable"
import { io } from "socket.io-client"

import { sessions } from "@/api"
import YouTubePlayer from "@/components/Functional/YouTubePlayer.vue"
import Button from "@/components/UI/Button"
import Layout from "@/components/Layouts/Layout"
import TimeRemaining from "@/components/UI/TimeRemaining.vue"
import PixelionSelect from "@/components/UI/PixelionSelect.vue"
import ProgressBar from "@/components/UI/ProgressBar"

export default {
  props: ["id"],
  components: {
    Layout,
    Button,
    YouTubePlayer,
    TimeRemaining,
    PixelionSelect,
    draggable,
    ProgressBar,
  },
  data() {
    return {
      session: null,
      currentQuestionIndex: null,
      lastQuestionIndex: null,
      answers: {},
      skipsCache: {},
      sequenceClones: {},
      matchRightClones: {},
      socket: null,
      media_url: process.env.VUE_APP_MEDIA_URL,
      answers_sent: {},
    }
  },
  computed: {
    currentQuestion() {
      if (this.currentQuestionIndex == null) return null
      return this.schema[this.currentQuestionIndex]
    },
    currentQuestionId() {
      if (this.currentQuestionIndex == null) return null
      return this.currentQuestion.id
    },
    currentQuestionKeyboardType() {
      if (this.currentQuestionIndex == null) return "simple"
      if (!this.currentQuestion.keyboard_type) return "simple"
      return this.currentQuestion.keyboard_type
    },
    schema() {
      // Session's schema_without_correct_answers filtered by variant
      if (!this.session) return {}
      return filter(
        this.session.assignment.schema_without_correct_answers,
        (question) => {
          return question.variant == this.session.variant
        }
      )
    },
    progressInPercents() {
      const result =
        (100 / this.schema.length) * Object.keys(this.answers_sent).length
      return result.toFixed(0)
    },
  },
  mounted() {
    this.getSession().then(this.init)
    document.addEventListener("keyup", this.onKeyUp)
  },
  beforeUnmount() {
    this.socket.close()
    document.removeEventListener("keyup", this.onKeyUp)
  },
  methods: {
    init() {
      // Initializing indexes
      this.currentQuestionIndex = 0
      this.lastQuestionIndex = this.schema.length - 1

      // Marking already answered answers as "sent"
      this.answers = this.session.answers
      Object.keys(this.answers).forEach((question_id) => {
        this.answers_sent[question_id] = true
      })

      // Initializing complex question types
      this.schema.forEach((question, index) => {
        if (question.type == "sequence") {
          this.initializeSequence(index)
        }
        if (question.type == "skips") {
          this.initializeSkips(index)
        }
        if (question.type == "match") {
          this.initializeMatch(index)
        }
        if (question.type == "table") {
          this.initializeTable(index)
        }
      })

      // If session is paused, stop the timer
      if (this.session.status == "P") {
        setImmediate(() => {
          this.$refs.time_remaining.stopTimer()
        })
      }

      // Ensure correct heights of left-right matches (if any)
      this.$nextTick(() => {
        this.setEqualHeightWithDelays()
      })

      // Initialize websockets to live data update
      this.initWebsockets()
    },

    //
    // Keyboard control
    //
    onKeyUp(e) {
      switch (e.key) {
        case "ArrowLeft":
          if (!this.previousQuestion()) this.goToLastQuestion()
          break
        case "ArrowRight":
          if (!this.nextQuestion()) this.goToFirstQuestion()
          break
      }

      const keyIsCountable = !isNaN(parseInt(e.key)) && parseInt(e.key) > 0
      const questionTypeAllowsKeyboard =
        this.currentQuestion.type == "single" ||
        this.currentQuestion.type == "multiple"

      if (keyIsCountable && questionTypeAllowsKeyboard) {
        const index = parseInt(e.key) - 1
        if (index >= this.currentQuestion.answers.length) return
        const answerId = this.currentQuestion.answers[index].id
        if (this.currentQuestion.type == "single") {
          if (this.answers[this.currentQuestionId] == answerId.toString()) {
            this.answerSingle("")
          } else {
            this.answerSingle(answerId)
          }
        }
        if (this.currentQuestion.type == "multiple") {
          this.answerMultiple(answerId)
        }
      }
    },

    //
    // Moving through the questions
    //
    goToQuestion(index) {
      this.sendAnswer(this.currentQuestionId)
      this.currentQuestionIndex = index
      this.setEqualHeightWithDelays()
      return true
    },
    nextQuestion() {
      if (this.currentQuestionIndex == this.lastQuestionIndex) return false
      return this.goToQuestion(this.currentQuestionIndex + 1)
    },
    previousQuestion() {
      if (this.currentQuestionIndex == 0) return false
      return this.goToQuestion(this.currentQuestionIndex - 1)
    },
    goToFirstQuestion() {
      return this.goToQuestion(0)
    },
    goToLastQuestion() {
      return this.goToQuestion(this.lastQuestionIndex)
    },

    //
    // Initializers for complex types of questions
    //
    initializeSequence(index) {
      const question_id = this.schema[index].id
      if (!this.answers[question_id]) {
        // Если ответ на sequence ещё не дан, то по умолчанию отвечаем тем порядком, что в вопросе,
        // иначе если ответы в вопросе выстроены в правильном порядке и студент не перемещал их,
        // то никакого ответа на сервер не будет отправлено вообще
        this.sequenceClones[question_id] = cloneDeep(this.schema[index].answers)
        this.answers[question_id] = this.sequenceClones[question_id].map(
          (item) => item.id.toString()
        )
      } else {
        // ... а если дан, то приводим к нужному порядку
        this.sequenceClones[question_id] = cloneDeep(this.schema[index].answers)
        this.sequenceClones[question_id].sort((a, b) => {
          const aIndex = this.answers[question_id].indexOf(a.id.toString())
          const bIndex = this.answers[question_id].indexOf(b.id.toString())
          return aIndex - bIndex
        })
      }
    },
    initializeSkips(index) {
      const question_id = this.schema[index].id
      if (!this.answers[question_id]) {
        this.answers[question_id] = []
      }
    },
    initializeMatch(index) {
      const question_id = this.schema[index].id
      if (!this.answers[question_id]) {
        this.matchRightClones[question_id] = cloneDeep(this.schema[index].right)
        this.answers[question_id] = this.matchRightClones[question_id].map(
          (item, answerIndex) => [
            this.schema[index].left[answerIndex].id,
            item.id.toString(),
          ]
        )
      } else {
        this.matchRightClones[question_id] = cloneDeep(this.schema[index].right)
        this.matchRightClones[question_id].sort((a, b) => {
          const aObject = this.answers[question_id].find(
            (item) => item[1] == a.id
          )
          const bObject = this.answers[question_id].find(
            (item) => item[1] == b.id
          )
          const aIndex = this.answers[question_id].indexOf(aObject)
          const bIndex = this.answers[question_id].indexOf(bObject)
          return aIndex - bIndex
        })
      }
    },
    initializeTable(index) {
      const question = this.schema[index]
      if (!this.answers[question.id]) {
        this.answers[question.id] = []
        question.groups.forEach((group) => {
          this.answers[question.id].push({})
        })
      }
    },

    //
    // Getters for sorted clones of lists
    //
    getMatchList(question_id) {
      return this.matchRightClones[question_id]
    },
    getSequenceList(answers) {
      if (this.sequenceClones[this.currentQuestionId]) {
        return this.sequenceClones[this.currentQuestionId]
      }
      this.sequenceClones[this.currentQuestionId] = cloneDeep(answers)
      return this.sequenceClones[this.currentQuestionId]
    },

    //
    // Answer preprocessing for complex types of questions
    //
    answerSingle(answer_id) {
      this.answers[this.currentQuestionId] = answer_id.toString()
      this.sendAnswer(this.currentQuestionId)
    },
    answerMultiple(answer_id) {
      if (!Array.isArray(this.answers[this.currentQuestionId])) {
        this.answers[this.currentQuestionId] = []
      }
      // Toggle answer selection
      const index = this.answers[this.currentQuestionId].indexOf(
        answer_id.toString()
      )
      if (index > -1) {
        this.answers[this.currentQuestionId].splice(index, 1)
      } else {
        this.answers[this.currentQuestionId].push(answer_id.toString())
      }
      this.sendAnswer(this.currentQuestionId)
    },
    answerMatch() {
      if (typeof this.answers[this.currentQuestionId] !== "object") {
        this.answers[this.currentQuestionId] = {}
      }
      this.answers[this.currentQuestionId] = this.matchRightClones[
        this.currentQuestionId
      ].map((item, answerIndex) => [
        this.currentQuestion.left[answerIndex].id,
        item.id.toString(),
      ])
      this.sendAnswer(this.currentQuestionId)
      this.setEqualHeight()
    },
    answerSequence() {
      const list = this.sequenceClones[this.currentQuestionId].map((item) =>
        item.id.toString()
      )
      this.answers[this.currentQuestionId] = list
      this.sendAnswer(this.currentQuestionId)
    },
    answerSkips(questionId) {
      this.sendAnswer(questionId)
    },
    answerMatchSelect(group_id, event) {
      if (typeof this.answers[this.currentQuestionId] !== "object") {
        this.answers[this.currentQuestionId] = {}
      }
      this.answers[this.currentQuestionId][group_id] = event.target.value
      this.sendAnswer(this.currentQuestionId)
    },
    answerTable(groupIndex, rowName, event) {
      this.answers[this.currentQuestionId][groupIndex][rowName] =
        event.target.value
      // this.sendAnswer(this.currentQuestionId)
    },

    //
    // Session actions (get, pause, resume, finish) + sending an answer
    //
    async getSession() {
      this.session = await sessions.get(this.id)
    },
    async pauseSession() {
      await sessions.pause(this.session.id)
      this.session.status = "P"
      this.$refs.time_remaining.stopTimer()
    },
    async resumeSession() {
      await sessions.resume(this.session.id)
      this.session.status = "S"
      this.$refs.time_remaining.startTimer()
      this.setEqualHeight()
    },
    async finishSession() {
      await this.sendAnswer(this.currentQuestionId)
      await sessions.finish(this.session.id)
      this.$router.push({
        name: "student-test-result",
        params: { id: this.session.id },
      })
    },
    async sendAnswer(questionId) {
      const secret = this.session.secret
      const question_id = questionId
      const answer = this.answers[questionId]
      await sessions.answer(secret, question_id, JSON.stringify(answer))
      if (this.answers[questionId]) {
        this.answers_sent[questionId] = true
      }
    },

    //
    // WebSockets
    //
    initWebsockets() {
      const ws_url = process.env.VUE_APP_WS_URL
      const ws_path = process.env.VUE_APP_WS_PATH
      const socket = io(ws_url, {
        path: ws_path,
        transports: ["websocket"],
        withCredentials: true,
      })

      this.socket = socket

      socket.on("connect", (s) => {
        socket.emit("join", this.session.secret)
      })
      socket.on("message", (data) => {
        data = JSON.parse(data)
        const action = data["action"]

        switch (action) {
          case "answer":
            break
          case "pause":
            this.session.status = "P"
            this.$refs.time_remaining.stopTimer()
            break
          case "resume":
            this.session.status = "S"
            this.$refs.time_remaining.startTimer()
            break
          case "finish":
            this.$router.push({
              name: "student-test-result",
              params: { id: this.session.id },
            })
            break
        }
      })
    },

    //
    // Utilities
    //
    isAnswered(questionId) {
      return this.answers[questionId] !== undefined
    },
    splitSkipsText(text) {
      // For "skips" type of question
      // Returns parts of text, splitted by []s to insert input fields there
      if (this.skipsCache[this.currentQuestionId]) {
        return this.skipsCache[this.currentQuestionId]
      }
      const parts = text.split(/\[\s*\]/g)
      this.skipsCache[this.currentQuestionId] = parts
      return parts
    },
    setEqualHeight() {
      let left_side_items =
        document.getElementsByClassName("left_match_list")[0]?.children
      let right_side_items =
        document.getElementsByClassName("right_match_list")[0]?.children

      if (!left_side_items || !right_side_items) return

      const list_length = left_side_items.length
      for (var i = 0; i < list_length; i++) {
        left_side_items[i].style.height = "auto"
        right_side_items[i].style.height = "auto"
        var max_height = Math.max.apply(null, [
          left_side_items[i].clientHeight,
          right_side_items[i].clientHeight,
        ])
        left_side_items[i].style.height = `${max_height}px`
        right_side_items[i].style.height = `${max_height}px`
      }
    },
    setEqualHeightWithDelays() {
      this.$nextTick(() => {
        this.setEqualHeight()
        setTimeout(() => {
          this.setEqualHeight()
        }, 500)
      })
    },
  },
}
</script>

<style scoped>
.question_container {
  display: flex;
  flex-flow: column;
  width: 600px;
  align-items: center;
}
.question_container >>> table {
  width: 100%;
}
.answer_variants {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 240px;
  gap: 10px;
  padding-top: 30px;
  padding-bottom: 50px;
}
.answer_variants .button {
  width: 100%;
  background-color: #f9f9f9 !important;
  color: #333333;
  background: #f9f9f9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  height: fit-content !important;
}
.answer_variants .button.selected {
  width: 100%;
  background-color: var(--gray3) !important;
  color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  font-weight: bold;
}
.answer_variants input {
  width: 489px;
}
.answer_variants[class*=" keyboard_"]:not(.keyboard_simple)
  >>> .button::before {
  content: "";
  width: 50px;
  height: 50px;
  left: -70px;
  position: absolute;
}
.answer_variants[class*=" keyboard_"]:not(.keyboard_simple) >>> .button {
  position: relative;
  margin-left: 70px;
}
.answer_variants[class*=" keyboard_fruits"] >>> .button:nth-child(1)::before {
  background-image: url("@/assets/img/keyboards/fruits/apple.svg");
}
.answer_variants[class*=" keyboard_fruits"] >>> .button:nth-child(2)::before {
  background-image: url("@/assets/img/keyboards/fruits/pear.svg");
}
.answer_variants[class*=" keyboard_fruits"] >>> .button:nth-child(3)::before {
  background-image: url("@/assets/img/keyboards/fruits/cherry.svg");
}
.answer_variants[class*=" keyboard_fruits"] >>> .button:nth-child(4)::before {
  background-image: url("@/assets/img/keyboards/fruits/banana.svg");
}
.answer_variants[class*=" keyboard_fruits"] >>> .button:nth-child(5)::before {
  background-image: url("@/assets/img/keyboards/fruits/grape.svg");
}
.answer_variants[class*=" keyboard_fruits"] >>> .button:nth-child(6)::before {
  background-image: url("@/assets/img/keyboards/fruits/pineapple.svg");
}

.answer_variants[class*=" keyboard_shapes"] >>> .button:nth-child(6)::before {
  background-image: url("@/assets/img/keyboards/figures/rect.svg");
}
.answer_variants[class*=" keyboard_shapes"] >>> .button:nth-child(1)::before {
  background-image: url("@/assets/img/keyboards/figures/circle.svg");
}
.answer_variants[class*=" keyboard_shapes"] >>> .button:nth-child(2)::before {
  background-image: url("@/assets/img/keyboards/figures/triangle.svg");
}
.answer_variants[class*=" keyboard_shapes"] >>> .button:nth-child(3)::before {
  background-image: url("@/assets/img/keyboards/figures/line.svg");
}
.answer_variants[class*=" keyboard_shapes"] >>> .button:nth-child(4)::before {
  background-image: url("@/assets/img/keyboards/figures/star.svg");
}
.answer_variants[class*=" keyboard_shapes"] >>> .button:nth-child(5)::before {
  background-image: url("@/assets/img/keyboards/figures/rhombus.svg");
}

.current_question,
.answer_header {
  color: var(--gray3);
  font-size: 14px;
  line-height: 20px;
}
.controll_panel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--gray3);
  padding-top: 14px;
  margin-top: 26px;
  border-top: 2px solid var(--gray6);
}
.controll_panel div {
  cursor: pointer;
}
.controll_panel .muted {
  color: #ddd;
}
.h1 {
  position: relative;
}
.question_text {
  /* padding-top: 16px;
  padding-bottom: 27px; */
  font-weight: bold;
  text-align: center;
}
.question_text >>> p {
  font-family: "PTSansWebBold";
}

.question_container >>> td {
  padding: 5px;
}
.test_badge {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 67px;
  background-color: var(--greenz);
  border-radius: 25px;
  text-transform: uppercase;
  top: -50px;
}

.test_control {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.test_control img {
  height: 50px;
}
select {
  background: #f9f9f9;
  box-shadow: inset 0px 2px 4px rgb(0 0 0 / 10%);
  border-radius: 25px;
  /* outline: none; */
  width: 117px;
  height: 50px;
  left: 650px;
  border: none;
  top: 334px;
  padding-left: 10px;
  padding-right: 10px;
}
.test_control .button {
  width: 150px;
}
.sequence_list {
  list-style: none;
  width: 100%;
}
.sequence_list li {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  border-bottom: 1px solid var(--gray5);
}
.sequence_list li:last-child {
  border-bottom: none;
}
.test_container {
  display: flex;
  justify-content: center;
}
.match_container {
  display: flex;
  flex-basis: 50%;
  width: 100%;
}
.match_container ul {
  list-style: none;
  padding: 0;
  padding-right: 60px;
  margin: 0;
}
.match_container li {
  margin-top: 10px;
  padding-bottom: 10px;
}

.left_match_column {
  width: 100%;
}
.left_match_column li {
  /* background-color: #aca; */
}
.right_match_column {
  width: 100%;
}
.right_match_column li {
  /* background-color: aqua; */
  position: relative;
}
.right_match_column li .match_handler::after {
  content: url("@/assets/img/handler.svg");
}
.right_match_column li:hover .match_handler::after {
  content: url("@/assets/img/handler_light.svg");
}
.right_match_column li:hover .border_holder {
  /* background-color: var(--gray3); */
}
.drag_handle {
  width: 126%;
  height: 105%;
  position: absolute;
  z-index: 1;
}
.left_match_column li,
.right_match_column li {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  /* border-bottom: 1px solid var(--gray5); */
  min-height: 50px;
}

/* Styled select */
*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --select-border: #777;
  --select-focus: var(--greenz);
  --select-arrow: var(--select-border);
}

.schooltest_select select option:checked {
  /* background-color: var(--greenz) !important; */
}

.schooltest_select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  /* width: 100%; */
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;

  width: 250px;
  padding-left: 20px;
  padding-right: 20px;

  /* background: #f9f9f9;
  box-shadow: inset 0px 2px 4px rgb(0 0 0 / 10%);
  border-radius: 25px;
  outline: none;
  
  height: 50px;
  left: 650px;
  border: none;
  top: 334px;
  padding-left: 10px;
  padding-right: 10px; */
}
.schooltest_select select::-ms-expand {
  display: none;
}

.schooltest_select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  cursor: pointer;
  /* background-image: linear-gradient(to top, #f9f9f9, #fff 33%); */
}
.schooltest_select select,
.schooltest_select::after {
  grid-area: select;
}
.schooltest_select:not(.schooltest_select--multiple)::after {
  content: "";
  justify-self: end;
  width: 0.8em;
  height: 0.5em;
  background-color: var(--select-arrow);
  -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}

select[multiple] {
  padding-right: 0;
  /*
   * Safari will not reveal an option
   * unless the select height has room to 
   * show all of it
   * Firefox and Chrome allow showing 
   * a partial option
   */
  height: 6rem;
  /* 
   * Experimental - styling of selected options
   * in the multiselect
   * Not supported crossbrowser
   */
}
select[multiple] option {
  white-space: normal;
  outline-color: var(--select-focus);
}

.schooltest_select--disabled {
  cursor: not-allowed;
  background-color: #eee;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}

label {
  font-size: 1.125rem;
  font-weight: 500;
}

.schooltest_select + label {
  margin-top: 2rem;
}

/* /Styled select */

.cursor_unset {
  cursor: none;
}

.icon-pause,
.icon-play,
.icon-arrow-left,
.icon-arrow-right {
  font-size: 50px;
}
.test_control.purple:hover {
  color: var(--purple);
}
.test_control.green:hover {
  color: var(--greenz);
}
.icon-play:before,
.icon-pause:before,
.icon-arrow-right:before,
.icon-arrow-left:before {
  color: inherit !important;
}

.match_answer_photo {
  max-width: 250px;
  margin-top: 10px;
}
.match_answer_arrow {
  font-size: 23px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 32px;
  right: -45px;
}
.match_handler {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 50px;
  /* right: -60px; */
  right: 0;
}
.right_match_list {
  padding-right: 0 !important;
}
.border_holder {
  /* background-color: #aca; */
  width: 100%;
  height: 100%;
  position: absolute;
  margin-bottom: -10px;
  border-bottom: 1px solid var(--gray5);
}
.right_match_list .border_holder {
  width: 80%;
}
.answer_skip_select {
  width: fit-content;
  min-width: 150px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.skips_text span {
  display: block;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
  font-size: 24px;
}
.skips_text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
}
.match_img_container {
  padding-top: 5px;
  /* padding-bottom: 5px; */
}
.match_img_container img {
  max-width: 240px;
  max-height: 240px;
}
.question_description {
  padding-bottom: 20px;
  width: 100%;
}
.question_description >>> img {
  /* max-width: 240px; */
}
.question_description >>> p:has(img) {
  text-align: center;
}
.questions_nav {
  padding-top: 25px;
  display: flex;
  justify-content: center;
  gap: 15px;
}
.questions_nav__item {
  cursor: pointer;
}
.questions_nav__item-current {
  color: var(--greenz);
}
.questions_nav__item-answered {
  color: var(--gray3);
}
.questions_nav__item-unanswered {
  text-decoration: underline;
  color: var(--gray1);
}
.progress {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 28px;
  align-items: center;
  color: var(--gray3);
}

.table_group {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}
.table_group_name {
  font-weight: bold;
  padding-bottom: 10px;
}
.table_group_row {
  margin-bottom: 5px;
  display: flex;
}
.table_group_row input {
  flex: 1;
}
.table_group_row_name {
  display: inline-block;
  color: #777;
  font-size: 80%;
  margin-right: 20px;
  margin-top: 13px;
}
</style>
