<template>
  <div class="answers_form">
    <div class="answers_form__header">Ответы:</div>
    <div class="answers_hint">
      <div class="answers_hint_text">Отметьте правильный вариант</div>
      <img class="answers_hint_icon" src="@/assets/img/curved_arrow.png" />
    </div>
    <div class="answer_hint_2">
      (ответы будут показаны ученикам в случайном порядке)
    </div>
    <template v-if="answers">
      <div class="answer_row" v-for="answer in answers">
        <AnswerField
          :answer="answer"
          @remove="remove"
          @add="add"
          @change="emitUpdate"
          :checked="correct == answer.id"
        />
        <CheckMark
          :checked="correct == answer.id"
          @click="clickCheckMark(answer)"
          :title="
            correct == answer.id ? 'Правильный вариант' : 'Неправильный вариант'
          "
        />
      </div>
    </template>
  </div>
</template>

<script>
import CheckMark from "../CheckMark.vue"
import AnswerField from "@/components/Forms/TestForm/AnswerField.vue"

export default {
  props: ["question"],
  emits: ["update"],
  components: {
    AnswerField,
    CheckMark,
  },
  data() {
    return {
      answers: [
        { id: "1", text: "" },
        { id: "2", text: "" },
        { id: "3", text: "" },
      ],
      correct: "1",
    }
  },
  mounted() {
    if (this.question.answers) {
      this.answers = this.question.answers
    }
    if (this.question.correct) {
      if (Array.isArray(this.question.correct)) {
        this.correct = this.question.correct[0]
      } else {
        this.correct = this.question.correct
      }
    }
  },
  methods: {
    render() {
      return {
        answers: this.answers,
        correct: this.correct,
      }
    },
    emitUpdate() {
      this.$emit("update", this.render())
    },
    clickCheckMark(answer) {
      this.correct = answer.id
      this.emitUpdate()
    },
    remove(answer) {
      // Disallow to remove the only answer
      if (this.answers.length == 1) return
      // Remove answer from list
      this.answers = this.answers.filter((a) => a.id !== answer.id)
      // Remove answer from correct list
      if (this.correct == answer.id) {
        this.correct = this.answers[0].id
      }
      this.emitUpdate()
    },
    add(answer) {
      const idsList = this.answers.map((a) => parseInt(a.id))
      const newId = "" + (Math.max(...idsList) + 1)
      const newIndex = this.answers.indexOf(answer) + 1
      this.answers.splice(newIndex, 0, { id: newId, text: "" })
      this.emitUpdate()
    },
  },
}
</script>

<style scoped>
.answer_row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 242px;
  padding-right: 21px;
  padding-bottom: 10px;
}
.answer_hint_2 {
  position: absolute;
  max-width: 225px;
  text-align: right;
}
</style>
