<template>
  <div
    class="multi-select"
    :class="disabled ? 'disabled' : ''"
    v-click-outside="closeOptions"
  >
    <div class="selected-items" @click="toggleOptions">
      <span v-if="selectedItems.length == 0">{{ placeholder }}</span>
      <span v-else>Выбрано: {{ selectedItems.length }}</span>

      <span class="arrow" :class="showOptions ? 'vs--open' : 'vs--close'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="10"
          role="presentation"
          class="vs__open-indicator"
        >
          <path
            d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"
          ></path>
        </svg>
      </span>
    </div>
    <div class="options vs__dropdown-menu" v-show="showOptions">
      <div
        v-for="(option, index) in options"
        :key="option[valueName]"
        class="option"
        :class="selectedItems.indexOf(option) != -1 ? 'selected' : ''"
      >
        <div v-if="hasVariants" class="variant_selector">
          <div
            @click="setVariant(option, 1)"
            :class="selectedVariants[option[valueName]] == 1 ? 'selected' : ''"
          >
            вар. 1
          </div>
          <div
            @click="setVariant(option, 2)"
            :class="selectedVariants[option[valueName]] == 2 ? 'selected' : ''"
          >
            вар. 2
          </div>
        </div>
        <input
          type="checkbox"
          :id="'option-' + index"
          :value="option"
          v-model="selectedItems"
          class="custom-checkbox"
        />
        <label
          :for="'option-' + index"
          @click="setDefaultVariant(option[valueName])"
          >{{ option[labelName] }}</label
        >
      </div>
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash"

export default {
  name: "MultiSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {},
    reduce: {
      type: Function,
      default: (item) => item.id,
    },
    labelName: {
      type: String,
      default: "label",
    },
    valueName: {
      type: String,
      default: "id",
    },
    hasVariants: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItems: [],
      selectedVariants: {},
      showOptions: false,
    }
  },
  mounted() {
    if (!this.modelValue) return
    if (!this.options) return
    const result = this.options.filter(
      (option) => this.modelValue.indexOf(option[this.valueName]) != -1
    )
    this.selectedItems = result
  },
  methods: {
    closeOptions() {
      this.showOptions = false
    },
    toggleOptions() {
      if (this.disabled && this.showOptions == false) {
        return
      }
      this.showOptions = !this.showOptions
    },
    setVariant(object, variant) {
      const value = object[this.valueName]
      this.selectedVariants[value] = variant
      if (this.selectedItems.indexOf(object) === -1) {
        this.selectedItems.push(object)
      }
      this.emitModelValue()
    },
    setDefaultVariant(objectValue) {
      this.selectedVariants[objectValue] = 1
    },
    emitModelValue() {
      // Map the new value to an array of selected items.
      let result = this.selectedItems.map((item) => item[this.valueName])

      // Iterate over the selected variants and remove any that are no longer selected.
      for (let r of Object.keys(this.selectedVariants)) {
        if (result.indexOf(parseInt(r)) === -1) {
          delete this.selectedVariants[r]
        }
      }
      // If the component has variants, format the result as an array of objects.
      if (this.hasVariants) {
        result = Object.entries(this.selectedVariants).map(([id, variant]) => {
          return { id, variant }
        })
      }
      // Emit an event to update the model value with the new result.
      this.$emit("update:modelValue", result)
    },
  },
  watch: {
    selectedItems(new_value, old_value) {
      // Check if the new and old values are equal. If they are, do nothing.
      if (isEqual(new_value, old_value)) {
        return
      }
      this.emitModelValue()
    },
    modelValue(new_value, old_value) {
      if (isEqual(new_value, old_value)) {
        return
      }
      let filterArray = new_value
      // check if new_value is an object (in the case it has variants)
      if (new_value.length > 0 && typeof new_value[0] == "object") {
        filterArray = new_value.map((item) => parseInt(item[this.valueName]))
      }
      // filter options list
      const result = this.options.filter(
        (option) => filterArray.indexOf(option[this.valueName]) != -1
      )
      this.selectedItems = result
    },
  },
}
</script>

<style>
.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  width: 100%;
}
.custom-checkbox + label::before {
  content: "";
  display: inline-block;
  width: 23px;
  height: 23px;
  flex-shrink: 0;
  flex-grow: 0;

  /* border: 1px solid #adb5bd; */
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);

  /* border-radius: 0.25em; */
  margin-right: 18px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70% 70%;
}
.custom-checkbox:checked + label::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.25714 13L0 7.70796L2.28571 5.52212L5.25714 8.51327L13.7143 0L16 2.30088L5.25714 13Z' fill='%239FD900'/%3E%3C/svg%3E");
  background-position: 50% 64%;
}
input[type="checkbox"] {
  /* background: #f9f9f9; */
  box-shadow: none !important;
  border-radius: 0px !important;
  border: none;
  padding-left: 24px;
  height: 20px;
  outline: none;
  margin-top: 0px;
  margin-bottom: 0px;
}
.vs__open-indicator {
  fill: var(--vs-controls-color);
  transform: scale(var(--vs-controls-size));
  transition: transform var(--vs-transition-duration)
    var(--vs-transition-timing-function);
  transition-timing-function: var(--vs-transition-timing-function);
}
.multi-select {
  position: relative;
  width: 340px;
  background: #f9f9f9;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  border: none;
  height: 50px;
}
.disabled {
  box-shadow: none;
}
.disabled .selected-items {
  cursor: not-allowed;
}

.selected-items {
  padding-right: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.25em !important;
  font-size: 17px !important;
  height: 100%;
}

.arrow {
  margin-left: 5px;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  /* border: 1px solid #ccc; */
  /* background-color: #fff; */
  z-index: 1;
  padding-top: 10px !important;
  padding-left: 10px !important;
}

.option {
  padding: 5px;
  display: flex;
  align-items: center;
}
.option:hover {
  background-color: var(--greenz);
}

input[type="checkbox"] {
  margin-right: 5px;
}
label {
  text-align: left !important;
  cursor: pointer;
}
.variant_selector {
  display: flex;
  padding-right: 10px;
}
.variant_selector div {
  border-bottom: 1px dashed #000000;
  white-space: nowrap;
  padding: 1px;
  margin-right: 5px;
  cursor: pointer;
}
.variant_selector div.selected {
  border-bottom: none;
  background-color: var(--greenz_link);
}
.option:not(.selected) .variant_selector div {
  color: rgb(153, 153, 153);
  border-bottom: 0px dashed #000000 !important;
}
</style>
