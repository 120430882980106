<template>
  <Layout url_prefix="admin">
    <template #page_header>
      <div class="h1">Личный кабинет администратора</div>
      <Search
        placeholder="поиск по базе школ"
        @searchInput="searchSchools"
        @searchResultClick="onSearchResultClick"
        @searchButtonPress="onSearchButtonPress"
        :search_results="search.results"
        :formatter="searchFormatter"
      >
      </Search>
      <div class="tabs_container">
        <div
          class="tab"
          @click="changeActiveTab('schools')"
          :class="{ tab_active: activeTab == 'schools' }"
        >
          <div class="h2">База школ</div>
          <Button class="button_bottom" :to="{ name: 'admin-school-add' }"
            >Создать школу</Button
          >
        </div>
        <div
          class="tab"
          @click="changeActiveTab('tests')"
          :class="{ tab_active: activeTab == 'tests' }"
        >
          <div class="h2">Тесты</div>
        </div>
      </div>
    </template>

    <template #page_content>
      <div class="table__container" v-if="activeTab == 'schools'">
        <table class="table" v-if="schools">
          <thead>
            <tr>
              <th>ИНН</th>
              <th>Краткое наименование</th>
              <th>Тарифный план</th>
              <th>Нас. пункт</th>
              <th>Контактное лицо</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="school in schools"
              :key="school.id"
              :class="{ disabled: school.is_disabled }"
            >
              <td>
                <router-link
                  class="link"
                  :to="{ name: 'admin-school-edit', params: { id: school.id } }"
                >
                  {{ school.inn }}
                </router-link>
              </td>
              <td>{{ school.name_short }}</td>
              <td>«{{ school.tarif_verbose }}»</td>
              <td>{{ school.city }}</td>
              <td>{{ school.contact_person }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="activeTab == 'tests'">
        <div class="h2">Каталог тестов</div>
        <div class="table__container" v-if="baseTests.results && baseTests.results.length">
          <table class="table">
            <thead>
              <tr>
                <th>Название теста</th>
                <th>Предмет</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="basetest in baseTests.results" :key="basetest.id">
                <td>
                  {{ basetest.name }}
                </td>
                <td>{{ basetest.subject.name }}</td>
                <td>
                  <span
                    @click="deleteBaseTest(basetest.id)"
                    style="cursor: pointer; color: var(--alert)"
                    >удалить</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination" v-if="baseTests.next != null || baseTests.previous != null">
            <Pagination
              :count="baseTests.count"
              :page_size="page_size"
              @pageChange="onBaseTestsPageChange"
            ></Pagination>
          </div>
        </div>
        <div v-else>
          <p>Тестов в каталоге ещё нет</p>
          <br />
        </div>
        <div class="h2">Все тесты</div>
        <div class="table__container">
          <table class="table">
            <thead>
              <tr>
                <th>Школа</th>
                <th>Название теста</th>
                <th>Предмет</th>
                <th>В каталог</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="test in tests.results" :key="test.id">
                <td>{{ test.school.name }}</td>
                <td>
                  {{ test.name }}
                </td>
                <td>{{ test.subject.name }}</td>
                <td>
                  <span
                    @click="makeTestBase(test.id)"
                    style="cursor: pointer; color: var(--greenz)"
                    >В каталог</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination" v-if="tests.next != null || tests.previous != null">
            <Pagination
              :count="tests.count"
              :page_size="page_size"
              @pageChange="onTestsPageChange"
            ></Pagination>
          </div>

        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import { schools, tests, baseTests } from "@/api"
import { debounce, pick, forOwn } from "lodash"
import ireplace from "@/utils/ireplace"

import Button from "@/components/UI/Button.vue"
import Pagination from "@/components/UI/Pagination.vue"
import Search from "@/components/UI/Search.vue"
import Layout from "@/components/Layouts/Layout.vue"

const PAGE_SIZE = process.env.VUE_APP_PAGE_SIZE

export default {
  components: {
    Layout,
    Button,
    Search,
    Pagination,
  },
  data() {
    return {
      activeTab: "schools",
      schools: null,
      tests: null,
      baseTests: null,
      search: {
        query: "",
        results: [],
        debounced: null,
      },
      page_size: PAGE_SIZE,
      // search_results: this.searchResults,
    }
  },
  computed: {
    baseTestsBasedOnIds() {
      return this.baseTests.map((test) => test.based_on)
    },
    // filteredTests() {
    //   return this.tests.filter((test) => {
    //     return this.baseTestsBasedOnIds.indexOf(test.id) === -1
    //   })
    // },
  },
  mounted() {
    console.log(this.page_size)
    this.getSchoolsList()
    this.getTestsList()
    this.getBaseTestsList()
  },
  methods: {
    changeActiveTab(tab_name) {
      this.activeTab = tab_name
    },
    async getSchoolsList() {
      this.schools = await schools.list()
    },
    async getTestsList(params) {
      this.tests = await tests.list_not_base(params)
    },
    async onTestsPageChange(page) {
      this.getTestsList({
        limit: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
      })
    },
    async getBaseTestsList(params) {
      params = params || {}
      const pagination_required = true
      this.baseTests = await baseTests.list(params, pagination_required)
    },
    async onBaseTestsPageChange(page) {
      this.getBaseTestsList({
        limit: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
      })
    },
    async makeTestBase(test_id) {
      if (confirm("Переместить в каталог тестов?")) {
        await tests.makeBase(test_id)
        this.getTestsList()
        this.getBaseTestsList()
      }
    },
    async deleteBaseTest(test_id) {
      if (confirm("Удалить тест из каталога?")) {
        await baseTests.delete(test_id)
        this.getTestsList()
        this.getBaseTestsList()
      }
    },
    async searchSchools(query) {
      this.search.query = query
      if (query.length < 3) {
        this.search.results = []
        return
      }
      // this.search.query = query
      if (this.search.debounced) {
        this.search.debounced.cancel()
        this.search.debounced = null
      }
      this.search.debounced = debounce(async () => {
        this.search.results = await schools.search(query)
      }, 200)
      this.search.debounced()
    },
    onSearchResultClick(search_result) {
      this.$router.push({
        name: "admin-school-edit",
        params: { id: search_result.id },
      })
    },
    async onSearchButtonPress() {
      if (this.search.query == "") {
        await this.getSchoolsList()
        return
      }
      if (this.search.query.length < 3) {
        return
      }
      this.schools = this.search.results
    },
    searchFormatter(query, search_result) {
      const fields_to_display = [
        "inn",
        "region_verbose",
        "city",
        "contact_person",
      ]
      const picked = pick(search_result, fields_to_display)

      let result = ""
      forOwn(picked, (value, key) => {
        if (key == "inn") {
          result += `<b>${value}</b>`
        } else {
          result += `<span>${value}</span>`
        }
      })
      result = ireplace(
        result,
        query,
        "<span class='search_results__item__highlight'>$1</span>"
      )
      return result
    },
  },
}
</script>

<style scoped>
.tabs_container {
  height: 80px;
  width: 1000px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}
.tab:nth-child(2) {
  padding-left: 35px;
  padding-right: 35px;
}
</style>
