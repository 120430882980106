<template>
  <Layout url_prefix="director">
    <template #page_header>
      <div class="h1">Базовый тест</div>
    </template>

    <template #page_content>
      <div class="right_shifted_form">
        <TestForm
          v-if="test"
          :initial="test"
          @change="formDataChanged"
        ></TestForm>
        <div class="">
          <div class="buttons__container__without_grow">
            <Button class="button_top" @click="save"
              >Сохранить и добавить тест в личный кабинет</Button
            >
            <Button
              class="button_gray"
              @click="$router.push({ name: 'teacher-index' })"
              >Выйти</Button
            >
          </div>
        </div>
      </div>
    </template>
    <Alert ref="alert" />
    <div class="spacer200"></div>
  </Layout>
</template>

<script>
import { mapState } from "vuex"

import { baseTests, tests } from "@/api"
import Button from "@/components/UI/Button.vue"
import Layout from "@/components/Layouts/Layout.vue"
import TestForm from "@/components/Forms/TestForm/TestForm.vue"
import Alert from "@/components/Functional/Alert.vue"

export default {
  props: ["id"],
  components: {
    Layout,
    Button,
    TestForm,
    Alert,
  },
  data() {
    return {
      formData: {},
      test: null,
      initial_subject: null,
    }
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  mounted() {
    this.fetchTest()
  },
  methods: {
    async fetchTest() {
      const response = await baseTests.get(this.id)
      this.test = response
      this.initial_subject = this.test.subject.id
    },
    async save() {
      if (this.userProfile.school.tarif == "start") {
        this.$refs.alert.alert(
          "Ваш тарифный план не позволяет добавлять тесты из каталога. Пожалуйста, обратитесь к вашему менеджеру."
        )
        return
      }
      // check if tarif is "optimum" and subject of test is in userProfile.school.selected_subjects
      if (
        this.userProfile.school.tarif == "optimum" &&
        !this.userProfile.school.selected_subjects.includes(
          this.initial_subject
        )
      ) {
        this.$refs.alert.alert(
          "Ваш тарифный план не позволяет добавлять тесты из каталога по данному предмету. Пожалуйста, обратитесь к вашему менеджеру."
        )
        return
      }
      try {
        const response = await tests.create(this.formData)
        this.$store.dispatch("fetchTests")
        this.$refs.alert.alert(
          "Тест успешно сохранен и добавлен в личный кабинет",
          () => {
            this.$router.push({
              name: "teacher-test-edit",
              params: { id: response.id },
            })
          }
        )
      } catch (error) {
        this.$refs.alert.alert(
          "Возникла ошибка при сохранении теста. Проверьте правильность заполнения всех полей."
        )
      }
    },
    formDataChanged(formData) {
      this.formData = formData
    },
  },
}
</script>

<style></style>
