<template>
  <div class="form school_form">
    <div class="centered_form__input_row">
      <label>Номер класса*</label>
      <input name="number" v-model="form_data.number" />
    </div>

    <div class="centered_form__input_row">
      <label>Литера (буква) класса*</label>
      <input name="litera" v-model="form_data.litera" />
    </div>

    <div class="centered_form__input_row" v-if="grade">
      <label class="top_label">Добавить ученика</label>
      <div class="teacher_students">
        <div class="students_list" v-if="students.length > 0">
          <div
            v-for="student in students"
            class="grade_student_with_remove_button"
          >
            <img
              @click="removeStudent(student.id)"
              class="remove"
              src="@/assets/img/remove_icon.svg"
            />
            <span>{{ student.fio }}</span>
          </div>
        </div>
        <v-select
          class="student_select"
          label="fio"
          :reduce="(student) => student.id"
          :options="students"
          v-model="form_data.students"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Нет результатов по запросу <em>{{ search }}</em
              >.
            </template>
            <em v-else style="opacity: 0.5"
              >Начните вводить текст для поиска</em
            >
          </template>
        </v-select>
        <span class="expand_table_link" @click="$refs.studentCreateModal.open">
          + добавить еще ученика
        </span>
        <AdaptiveModal ref="studentCreateModal">
          <StudentForm
            :hide_grade="hide_grade"
            @form_data_changed="changeStudent"
          ></StudentForm>
          <div class="buttons__container__without_grow">
            <Button @click="addNewStudent">создать ученика</Button>
            <Button class="button_gray" @click="hideStudentCreateModal"
              >выйти без сохранения</Button
            >
          </div>
        </AdaptiveModal>
      </div>
    </div>

    <div class="centered_form__input_row">
      <label>Комментарий к классу</label>
      <input name="comment" v-model="form_data.comment" autocomplete="false" />
    </div>
  </div>
</template>

<script>
import { pick } from "lodash"
import { grades, students } from "@/api"

import Button from "@/components/UI/Button"
import AdaptiveModal from "@/components/UI/Modal_Adaptive.vue"
import StudentForm from "@/components/Forms/StudentForm.vue"
import "vue-select/dist/vue-select.css"
import vSelect from "vue-select"

export default {
  props: ["grade", "students", "hide_grade"],
  components: {
    Button,
    vSelect,
    AdaptiveModal,
    StudentForm,
  },
  data() {
    return {
      grades: [],
      new_student: null,
      form_data: {
        number: 1,
        litera: "",
        comment: "",
      },
    }
  },
  mounted() {
    this.form_data = pick(this.grade, ["number", "litera", "comment"])
    this.getGrades()
  },
  methods: {
    getGrades() {
      this.grades = grades
    },
    removeStudent(student_id) {
      this.$emit("removeStudent", student_id)
    },
    addNewStudent() {
      this.$emit("addNewStudent", this.new_student)
      this.$refs.studentCreateModal.close()
    },
    changeStudent(data) {
      this.new_student = data
    },
    hideStudentCreateModal() {
      this.$refs.studentCreateModal.close()
    },
  },
  watch: {
    form_data: {
      deep: true,
      handler(data) {
        this.$emit("form_data_changed", data)
      },
    },
  },
}
</script>

<style></style>

<style scoped>
.student_select {
  /* margin-top: 20px; */
  margin-bottom: 18px;
  width: 300px;
}
.teacher_students {
  padding-bottom: 58px;
  width: 338px;
}
.teacher_students .expand_table_link {
  margin-left: 10px;
}
.buttons__container__without_grow {
  padding-top: 20px;
}
.grade_student_with_remove_button {
  height: 30px;
  display: flex;
  justify-content: start;
  flex-flow: row wrap;
  align-items: center;
  gap: 10px;
}
.grade_student_with_remove_button img {
  height: 10px;
  cursor: pointer;
  padding-left: 16px;
}
.top_label {
  margin-top: 0;
  padding-top: 12px;
}
.centered_form__input_row:nth-child(2) {
  padding-top: 20px;
}
.students_list {
  padding-bottom: 20px;
}
</style>
