<template>
  <router-link class="button" v-if="to" :to="to"><slot></slot></router-link>
  <span class="button" v-else><slot></slot></span>
</template>

<script>
export default {
  props: {
    to: Object,
  },
}
</script>

<style>
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--greenz);
  /* display: inline-block; */
  padding: 15px 18px;
  height: 20px;
  padding-top: 14px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* border-radius: 3px; */
  border-radius: 25px 25px 25px 25px;

  text-decoration: none;
  text-transform: uppercase;
  color: var(--gray1);
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}

.button.nav-button {
  text-transform: none;
  padding-left: 41px;
  padding-right: 41px;
  color: var(--gray1);
}
.router-link-active {
  background-color: white;
  outline: var(--greenz) solid 1px;
  color: var(--gray3) !important;
}
.button:hover {
  background-color: var(--greenz-hovered);
}
.button.button_top {
  border-radius: 0px 25px 25px 25px;
}
.button.button_bottom {
  border-radius: 25px 25px 25px 0px;
}
.button.button_white {
  background-color: var(--white) !important;
  color: var(--gray1);
}
.button.button_gray {
  background-color: var(--gray5) !important;
  color: var(--gray1);
}
.button.button_red {
  background-color: var(--red) !important;
  color: var(--white);
}
.button.button_purple {
  background-color: var(--purple) !important;
  color: var(--white);
}
.button.button_purple.button_outline {
  background-color: white !important;
  outline: var(--purple) solid 1px;
  color: var(--purple);
}
.button.button_green {
  background-color: var(--teacher-primary-color) !important;
  color: var(--white);
}
.button.button_blue {
  background-color: var(--director-primary-color) !important;
  color: var(--white);
}
.button.shadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
.button_with_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0;
  margin: 0;
  height: 50px;
}
.button_with_icon img {
  height: 20px;
}
</style>
