<template>
  <v-select
    :options="options"
    :reduce="reduce"
    :clearable="clearable"
    :modelValue="modelValue"
    @update:modelValue="input"
    v-bind="$attrs"
  >
    <template v-slot:no-options="{ search, searching }">
      <template v-if="searching">
        Нет результатов по запросу <em>{{ search }}.</em>
      </template>
      <em v-else style="opacity: 0.5">{{ emptyText }}</em>
    </template>
  </v-select>
</template>

<script>
import "vue-select/dist/vue-select.css"
import vSelect from "vue-select"

export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    reduce: {
      type: Function,
      default: (item) => item.id,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: "Начните вводить текст для поиска",
    },
    modelValue: {},
  },
  components: {
    vSelect,
  },
  methods: {
    input(value) {
      this.$emit("update:modelValue", value)
    },
  },
}
</script>
