<template>
  <div :class="{
    checkmark: 1,
    checked: checked,
  }"></div>
</template>

<script>
export default {
  props: ["checked"],
}
</script>

<style>
.checkmark {
  width: 30px;
  height: 30px;
  margin-left: 18px;
  cursor: pointer;
  aspect-ratio: 1;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center;
  background: url("@/assets/img/answer-wrong.svg");
  margin-top: 9px;
}

.checkmark.checked {
  background: url("@/assets/img/answer-correct.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
