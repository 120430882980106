<template>
  <div class="form school_form">
    <Row label="Предмет">
      <PixelionSelect
        :options="subjects || []"
        label="name"
        v-model="test.subject"
      />
    </Row>

    <Row label="Класс">
      <v-select
        :options="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']"
        :clearable="false"
        v-model="test.grade_number"
        :searchable="false"
      >
      </v-select>
    </Row>

    <Row label="Название теста">
      <input name="name" v-model="test.name" />
    </Row>

    <Row label="Ограничение по времени">
      <PixelionSelect
        :options="time_limit_options"
        :reduce="(option) => option.value"
        v-model="test.default_time_limit"
      />
    </Row>

    <Row label="Вариант" style="position: relative">
      <v-select :options="[1, 2]" :clearable="false" v-model="variant">
      </v-select>
      <span
        v-if="variant == 2"
        @click="deleteSecondVariant"
        style="
          right: -130px;
          position: absolute;
          border-bottom: 1px dashed #000000;
          cursor: pointer;
        "
        >удалить вариант</span
      >
    </Row>
  </div>
  <TransitionGroup>
    <div
      class="question_container"
      v-for="(question, index) in questionsForSelectedVariant"
      :key="question.id"
    >
      <QuestionForm
        :id="`question_${index}`"
        :is_first="index == 0"
        :is_last="index == questionsForSelectedVariant.length - 1"
        :question_index="index + 1"
        :question="question"
        :selected_variant="variant"
        @update="updateQuestion(index, $event)"
        @del="delQuestion(index)"
        @add="addAt(index + 1)"
        @up="moveUp(index)"
        @down="moveDown(index)"
      />
    </div>
  </TransitionGroup>
</template>

<script>
import { mapState } from "vuex"
import { time_limit_options } from "@/utils/const"
import { v4 as uuidv4 } from "uuid"
import { cloneDeep, each } from "lodash"

import "vue-select/dist/vue-select.css"
import vSelect from "vue-select"

import Button from "@/components/UI/Button.vue"
import CenteredFormRow from "@/components/UI/CenteredFormRow.vue"
import PixelionSelect from "@/components/UI/PixelionSelect.vue"
import QuestionForm from "@/components/Forms/TestForm/QuestionForm.vue"

export default {
  props: ["initial"],
  emits: ["change"],
  components: {
    Button,
    Row: CenteredFormRow,
    PixelionSelect,
    QuestionForm,
    vSelect,
  },
  data() {
    return {
      time_limit_options,
      variant: 1,
      test: {
        subject: null,
        name: null,
        default_time_limit: 40 * 60,
        grade_number: null,
        schema: [],
      },
      schemaVariants: {
        1: [],
        2: [],
      },
    }
  },
  computed: {
    ...mapState(["subjects"]),
    questionsForSelectedVariant() {
      return this.schemaVariants[this.variant]
    },
  },
  mounted() {
    if (this.initial) this.loadTest()
    else this.addQuestion(true) //this.test.schema.push(this.createQuestion())
  },
  methods: {
    loadTest() {
      this.test = this.initial
      this.test.subject = this.test.subject?.id
      this.test.schema = this.test.schema.map((question) => {
        // if no question variant is set, set it to 1
        if (!question.variant) question.variant = 1
        return question
      })
      // clone variants to this.schemaVariants
      each(this.test.schema, (question) => {
        this.schemaVariants[question.variant].push(cloneDeep(question))
      })
      this.$emit("change", this.test)
    },
    createQuestion() {
      return {
        id: uuidv4(),
        type: "single",
        question: "",
        description: "",
        variant: this.variant,
        keyboard_type: "",
      }
    },
    addQuestion(skipScroll = false) {
      const schema = this.schemaVariants[this.variant]
      schema.push(this.createQuestion())
      if (!skipScroll) this.scrollToQuestionIndex(schema.length - 1)
      this.mergeVariants()
      // this.test.schema.push(this.createQuestion())
      // this.scrollToQuestionIndex(this.test.schema.length - 1)
    },
    addAt(index) {
      const schema = this.schemaVariants[this.variant]
      schema.splice(index, 0, this.createQuestion())
      this.scrollToQuestionIndex(index)
      this.mergeVariants()
      // this.test.schema.splice(index, 0, this.createQuestion())
      // this.scrollToQuestionIndex(index)
    },
    moveUp(index) {
      if (index === 0) return
      const questions = this.questionsForSelectedVariant
      questions.splice(index - 1, 0, questions.splice(index, 1)[0])
      this.scrollToQuestionIndex(index - 1)
      this.mergeVariants()
    },
    moveDown(index) {
      const questions = this.questionsForSelectedVariant
      if (index === questions.length - 1) return
      questions.splice(index + 1, 0, questions.splice(index, 1)[0])
      this.scrollToQuestionIndex(index + 1)
      this.mergeVariants()
    },
    delQuestion(index) {
      const schema = this.schemaVariants[this.variant]
      schema.splice(index, 1)
      // this.test.schema.splice(index, 1)
      this.mergeVariants()
    },
    updateQuestion(index, data) {
      // this.test.schema[index] = data
      this.schemaVariants[this.variant][index] = data
      this.mergeVariants()
    },
    mergeVariants() {
      this.test.schema = [...this.schemaVariants[1], ...this.schemaVariants[2]]
    },
    deleteSecondVariant() {
      if (confirm("Все вопросы второго варианта будут удалены. Вы уверены?")) {
        this.variant = 1
        this.schemaVariants[2] = []
        this.mergeVariants()
      }
    },
    scrollToQuestionIndex(index) {
      setImmediate(() => {
        this.$scrollTo(`#question_${index}`)
      })
    },
    showTest() {
      console.log(JSON.parse(JSON.stringify(this.test)))
    },
  },
  watch: {
    test: {
      handler(test) {
        this.$emit("change", test)
      },
      deep: true,
    },
    variant(newVariant) {
      console.log(newVariant)
      // if there is no questions of the new variant, add one
      if (!this.schemaVariants[newVariant].length) {
        this.addQuestion(true)
        console.log("added")
      }
      // if (!this.test.schema.find((q) => q.variant === newVariant)) {
      //   this.addQuestion()
      //   console.log("added")
      // }
    },
  },
}
</script>

<style>
.grade__select .vs__selected-options {
  display: inline-flex;
  flex-basis: 100%;
  flex-flow: row wrap;
  padding: 0 2px;
  position: relative;
  padding: 10px 12px !important;
}
.grade__select .vs__selected {
  height: 30px;
  width: 50px;
  padding: 0 10px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 10px;
  margin-right: 10px;
}
.grade__select .vs__search {
  display: none;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
<style scoped>
.question_container {
  width: 1000px;
}
</style>
