<template>
  <div class="answers_form">
    <div class="answers_form__header">Ответы:</div>
    <template v-if="groups">
      <div class="group_row" v-for="group in groups" :key="group.id">
        <div class="divider_with_buttons__container">
          <div class="divider_with_buttons__line"></div>
          <div class="divider_with_buttons__header">группа вопрос-ответов</div>
          <div class="divider_with_buttons__buttons_container">
            <Button
              v-if="groups.length > 1"
              class="answer_button_with_icon shadow remove_button button_white"
              @click="removeGroup(group)"
              title="Удалить группу"
            >
              <span class="icon icon-trash"></span>
            </Button>
            <Button
              @click="addGroup(group)"
              class="answer_button_with_icon add_button shadow button_white"
              title="Добавить группу"
            >
              <span class="icon icon-big_plus"></span>
            </Button>
          </div>
        </div>

        <div class="answer_row">
          <div class="answer_hint_2">
            варианты ответов будут показаны ученикам внутри выпадающего списка в
            случайном порядке
          </div>
          <div>
            <div class="answer_hints">
              <div>вопрос</div>
              <div class="answers_hint">
                <div class="answers_hint_text">Отметьте правильный вариант</div>
                <img
                  class="answers_hint_icon"
                  src="@/assets/img/curved_arrow.png"
                />
              </div>
            </div>
            <div class="fields">
              <AnswerField
                :answer="group"
                :hide-remove-add="true"
                @change="emitUpdate"
              />
              <div class="equal_sign">
                <div>=</div>
              </div>
              <div class="answers">
                <div v-for="answer in group.answers" class="fields">
                  <AnswerField
                    :answer="answer"
                    :checked="correct[group.id] == answer.id"
                    :hide-remove="group.answers.length <= 1"
                    @remove="removeAnswer(group, $event)"
                    @add="addAnswer(group, $event)"
                    @change="emitUpdate"
                  />
                  <CheckMark
                    @click="clickCheckMark(group, answer)"
                    :checked="correct[group.id] == answer.id"
                    :title="
                      correct[group.id] == answer.id
                        ? 'Правильный вариант'
                        : 'Неправильный вариант'
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { cloneDeep } from "lodash"
import Button from "@/components/UI/Button.vue"

import CheckMark from "../CheckMark.vue"
import AnswerField from "@/components/Forms/TestForm/AnswerField.vue"

// "groups": [
//     { "id": "1", "text": "Алгоритм ...", "options": [
//         { "id": "a", "text": "Содержит цифры" },
//         { "id": "b", "text": "Содержит буквы" },
//         { "id": "c", "text": "Содержит инструкции" }
//     ] },
//     { "id": "2", "text": "Для чего пригодится ChatGPT?", "options": [
//         { "id": "a", "text": "Написания текстов" },
//         { "id": "b", "text": "Производства болтов" }
//     ] },
// ],

// "correct": {
//     "1": "c",
//     "2": "a"
// },

export default {
  props: ["question"],
  emits: ["update"],
  components: {
    AnswerField,
    CheckMark,
    Button,
  },
  data() {
    return {
      groups: [
        {
          id: "1",
          text: "",
          answers: [
            { id: "1", text: "" },
            { id: "2", text: "" },
          ],
        },
        {
          id: "2",
          text: "",
          answers: [{ id: "1", text: "" }],
        },
      ],
      correct: {
        1: "1",
        2: "1",
      },
    }
  },
  mounted() {
    if (
      this.question.groups &&
      Array.isArray(this.question.groups) &&
      Array.isArray(this.question.groups[0].answers)
    ) {
      this.groups = this.question.groups
    }
    if (this.question.correct && typeof this.question.correct === "object") {
      this.correct = this.question.correct
    }
  },
  methods: {
    render() {
      return cloneDeep({
        groups: this.groups,
        correct: this.correct,
      })
    },
    emitUpdate() {
      this.$emit("update", this.render())
    },
    generateEmptyGroup(groupId) {
      return {
        id: groupId,
        text: "",
        answers: [this.generateEmptyAnswer("1")],
      }
    },
    generateEmptyAnswer(answerId) {
      return { id: answerId, text: "" }
    },
    clickCheckMark(group, answer) {
      this.correct[group.id] = answer.id
      this.emitUpdate()
    },
    addGroup(groupBefore) {
      // Calculate new id and index
      const idsList = this.groups.map((a) => parseInt(a.id))
      const newId = "" + (Math.max(...idsList) + 1)
      const newIndex = this.groups.indexOf(groupBefore) + 1
      // Insert group into place
      const newGroup = this.generateEmptyGroup(newId)
      this.groups.splice(newIndex, 0, newGroup)
      // Mark first answer as correct
      this.correct[newGroup.id] = newGroup.answers[0].id
      // Propagate updates
      this.emitUpdate()
    },
    removeGroup(group) {
      // Disallow to remove the only group
      if (this.groups.length == 1) return
      // Remove the group from groups list
      this.groups = this.groups.filter((a) => a.id !== group.id)
      // Remove the group from correct list
      delete this.correct[group.id]
      // Propagate updates
      this.emitUpdate()
    },
    addAnswer(group, answerBefore) {
      const idsList = group.answers.map((a) => parseInt(a.id))
      const newId = "" + (Math.max(...idsList) + 1)
      const newIndex = group.answers.indexOf(answerBefore) + 1
      group.answers.splice(newIndex, 0, this.generateEmptyAnswer(newId))
      this.emitUpdate()
    },
    removeAnswer(group, answer) {
      // Disallow to remove the only answer
      if (group.answers.length == 1) return
      // Remove answer from list
      group.answers = group.answers.filter((a) => a.id !== answer.id)
      // Remove answer from correct list if necessary
      if (this.correct[group.id] == answer.id) {
        this.correct[group.id] = group.answers[0].id
      }
      this.emitUpdate()
    },
  },
}
</script>

<style>
.answer_row {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding-left: 242px; */
  padding-bottom: 10px;
  gap: 5px;
}
</style>

<style scoped>
.divider_with_buttons__container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  gap: 15px;
}
.divider_with_buttons__buttons_container {
  display: flex;
  align-items: center;
  gap: 12px;
}
.divider_with_buttons__header {
  display: inline-block;
}

.divider_with_buttons__header {
  font-family: "PTSansItalic";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
}
.divider_with_buttons__line {
  flex-grow: 1;
  border-bottom: 1px solid #e0e0e0;
  height: 1px;
}

.answers_form >>> .remove_button:hover {
  background-color: var(--alert) !important;
}
.answers_form >>> .add_button:hover {
  background-color: var(--greenz) !important;
}
.answers_form >>> .remove_button:hover .icon-trash:before,
.answers_form >>> .add_button:hover .icon-big_plus:before {
  color: white;
}

.answer_row {
  display: flex;
  padding-top: 10px;
  padding-right: 70px;
}

.answer_hint_2 {
  width: 250px;
  text-align: right;
  padding-right: 20px;
  padding-top: 56px;
  box-sizing: border-box;
}
.fields {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding-bottom: 10px;
}
.equal_sign {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 10px;
}
.fields >>> .answer_field {
  width: auto;
}
.answer_hints {
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  height: 33px;

  font-family: "PTSansItalic";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
}
.answers_hint_text {
  padding-right: 35px !important;
}
.answers_hint_icon {
  right: 9px !important;
}
</style>
