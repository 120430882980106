<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { each } from "lodash"
import { mapState } from "vuex"

const VUEX_PRELOAD = {
  admins: ["fetchRegions", "fetchSubjects"],
  directors: [
    "fetchRegions",
    "fetchGrades",
    "fetchTeachers",
    "fetchStudents",
    "fetchSubjects",
    "fetchTests",
    "fetchAssignments",
  ],
  teachers: [
    "fetchRegions",
    "fetchGrades",
    "fetchStudents",
    "fetchSubjects",
    "fetchTests",
    "fetchAssignments",
  ],
}

const REFRESH_TIME = 1000 * 60 * 5

export default {
  name: "App",
  data() {
    return {
      timers: {},
    }
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  beforeUnmount() {
    each(this.timers, (timer) => {
      clearInterval(timer)
    })
  },
  watch: {
    userProfile(profile) {
      if (profile) {
        const preloads = VUEX_PRELOAD[profile.group] || []
        each(preloads, (action) => {
          this.$store.dispatch(action)
        })
        each(this.timers, (timer) => {
          clearInterval(timer)
          timer = null
        })
        each(preloads, (action) => {
          this.timers[action] = setInterval(() => {
            this.$store.dispatch(action)
          }, REFRESH_TIME)
        })
      } else {
        each(this.timers, (timer) => {
          clearInterval(timer)
          timer = null
        })
        this.$store.commit("clearState")
      }
    },
  },
}
</script>

<style></style>
