<template>
  <div class="h1">Смена пароля</div>

  <form autocomplete="off">
    <div class="centered_form">
      <div class="centered_form__input_container w500">
        <div class="centered_form__input_row">
          <label>Новый пароль</label>
          <input
            type="password"
            v-model="password"
            class="profile_input"
            autocomplete="false"
          />
        </div>
        <div class="centered_form__input_row">
          <label>Повтор нового пароля</label>
          <input
            type="password"
            v-model="password_repeat"
            class="profile_input"
            autocomplete="false"
          />
        </div>
        <div class="buttons__container">
          <Button @click="savePassword">Сохранить</Button>
          <Button class="button_gray" @click="$emit('exit')">
            Выйти без сохранения
          </Button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Button from "@/components/UI/Button"

export default {
  emits: ["exit", "form_data_changed"],
  components: {
    Button,
  },
  emits: ["save", "exit"],
  data() {
    return {
      password: "",
      password_repeat: "",
    }
  },
  methods: {
    savePassword() {
      if (this.password !== this.password_repeat) {
        alert("Пароли не совпадают")
        return
      }
      this.$emit("save", this.password)
    },
  },
}
</script>

<style scoped>
.centered_form {
  /* padding-top: 200px; */
  /* width: 500px; */
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}
.centered_form__input_container {
  width: 600px;
  display: flex;
  flex-flow: column wrap;
  gap: 10px;
  /* justify-content: center; */
}
.centered_form__input_container .button {
  margin-top: 15px;
  align-self: flex-start;
}
.centered_form__input_row {
  justify-content: center;
}
.centered_form__input_row label {
  width: 170px;
}
</style>
