<template>
  <div class="assignment_header">
    <div class="h3">Идёт тест:</div>
    <span class="time_remaining"
      >Осталось
      <TimeRemaining
        :start_time="assignment.time_remaining"
        :autostart="true"
      ></TimeRemaining
    ></span>
  </div>
  <div class="assignment_container">
    <div class="assignment_selects">
      <v-select
        class="vs_subject_select"
        :options="[subject]"
        label="name"
        v-model="subject"
        disabled
        :searchable="false"
      >
      </v-select>
      <v-select
        class="vs_test_select"
        :options="[test]"
        label="name"
        v-model="test"
        disabled
        :searchable="false"
      >
      </v-select>
      <v-select
        class="vs_limit_select"
        v-model="time_limit"
        :options="time_limit_options"
        :reduce="(option) => option.value"
        disabled
        :clearable="false"
        :searchable="false"
      >
      </v-select>
    </div>

    <Button class="button_purple" @click="pauseAll">ПАУЗА</Button>
    <!-- <Button @click="resumeAll">ПОЕХАЛИ</Button> -->
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css"
import vSelect from "vue-select"
import { time_limit_options } from "@/utils/const.js"

import Button from "@/components/UI/Button.vue"
import TimeRemaining from "@/components/UI/TimeRemaining.vue"

export default {
  components: {
    Button,
    vSelect,
    TimeRemaining,
  },
  emits: ["pauseAll"],
  props: ["subject", "test", "time_limit", "assignment"],
  data() {
    return {
      time_limit_options,
    }
  },
  methods: {
    pauseAll() {
      this.$emit("pauseAll")
    },
    resumeAll() {
      this.$emit("resumeAll")
    },
  },
}
</script>

<style scoped>
.assignment_container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  /* justify-content: space-between; */
  gap: 27px;
  /* flex-flow: row wrap; */
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.assignment_selects {
  display: flex;
  /* justify-content: center; */
  gap: 10px;
  max-width: 800px;
}
.assignment_header {
  padding-top: 67px;
  padding-bottom: 5px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  gap: 7px;
}
.assignment_header .h3 {
  color: var(--red);
}
.vs_subject_select {
  width: 200px;
}
.vs_test_select {
  width: 200px;
}
.vs_limit_select {
  width: 180px;
}
.vs_subject_select {
  width: 277px;
}
.vs_test_select {
  width: 400px;
}
.vs_limit_select {
  width: 200px;
}
/* 
.vs__search {
  width: 75px !important;
}
.vs__selected-options {
  width: 50px;
}
.v-select {
  font-size: 14px;
} */
>>> {
  --vs-border-radius: 25px;
}
.button_purple {
  width: 185px;
}
</style>
<style>
.vs_limit_select .vs__dropdown-menu {
  max-width: 180px !important;
  min-width: unset !important;
}
</style>
