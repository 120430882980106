<template>
  <div class="admin-layout">
    <div class="navigation_menu">
      <div class="navigation_menu__header">
        <router-link class="unstyled_link" :to="{ name: 'admin-index' }"
          ><div class="project_name">ШколТест</div></router-link
        >
      </div>

      <div class="navigation_menu__items">
        <Button class="nav-button" :to="{ name: 'admin-index' }"
          >Личный кабинет</Button
        >
        <Button :to="{ name: 'admin-profile' }">
          <img src="@/assets/img/profile-icon.svg" />
        </Button>

        <LogoutButton class="button navigation_menu__item">
          <img src="@/assets/img/exit-icon.svg" />
        </LogoutButton>
      </div>
    </div>
    <slot> </slot>
  </div>
</template>

<script>
import LogoutButton from "@/components/Functional/LogoutButton"
import Button from "@/components/UI/Button"

export default {
  name: "AdminLayout",
  components: {
    LogoutButton,
    Button,
  },
}
</script>

<style>
/* .admin-layout .unstyled_link {
  color: var(--admin-primary-color);
} */

.admin-layout .navigation_menu__item {
  color: var(--admin-primary-color);
}
.admin-layout .navigation_menu__header {
  color: var(--admin-primary-color);
}
/* .admin-layout .expand_table_link {
  color: var(--admin-primary-color);
} */
</style>
<style scoped>
.vs__dropdown-option--highlight {
  --vs-dropdown-option--active-bg: var(--admin-primary-color);
}
</style>
