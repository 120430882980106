<template>
  <div class="form school_form">
    <div class="centered_form__input_row">
      <label>ФИО учителя*</label>
      <input name="fio" v-model="form_data.fio" />
    </div>

    <div class="centered_form__input_row">
      <label>Основной предмет</label>
      <v-select
        :options="subjects"
        :reduce="(subject) => subject.value"
        v-model="form_data.primary_subject"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            Нет результатов по запросу <em>{{ search }}</em
            >.
          </template>
          <em v-else style="opacity: 0.5">Начните вводить текст для поиска</em>
        </template>
      </v-select>
    </div>

    <div class="centered_form__input_row">
      <label>Фото</label>
      <SelectAvatarInput
        @imageLoaded="setAvatar"
        @imageRemoved="removeAvatar"
        :base64_image="form_data.avatar_base64"
      ></SelectAvatarInput>
    </div>

    <div class="centered_form__input_row">
      <label>Электронная почта*</label>
      <input name="email" v-model="form_data.email" />
    </div>
    <div class="centered_form__input_row">
      <label>Телефон</label>
      <PhoneMaskedInput v-model="form_data.phone"></PhoneMaskedInput>
    </div>
  </div>
</template>

<script>
import { map } from "lodash"

import "vue-select/dist/vue-select.css"
import vSelect from "vue-select"
import Button from "@/components/UI/Button"
import PhoneMaskedInput from "../UI/PhoneMaskedInput.vue"
import SelectAvatarInput from "@/components/UI/SelectAvatarInput"

export default {
  props: ["teacher"],
  components: {
    Button,
    vSelect,
    PhoneMaskedInput,
    SelectAvatarInput,
  },
  data() {
    return {
      form_data: {
        fio: "",
        primary_subject: null,
        avatar_base64: "",
        email: "",
        phone: "",
      },
    }
  },
  computed: {
    subjects() {
      const vuexSubjects = this.$store.state.subjects
      if (vuexSubjects) {
        return map(vuexSubjects, (subject) => {
          return {
            label: subject.name,
            value: subject.id,
          }
        })
      }
      return []
    },
  },
  methods: {
    setAvatar(base64Image) {
      this.form_data["avatar_base64"] = base64Image
      // console.log(base64Image)
    },
    removeAvatar() {
      this.form_data["avatar_base64"] = null
    },
  },
  watch: {
    teacher(new_teacher) {
      if (new_teacher) {
        this.form_data = new_teacher
      }
    },
    form_data: {
      deep: true,
      handler(data) {
        this.$emit("form_data_changed", data)
      },
    },
  },
}
</script>

<style scoped>
.school_form {
  margin-left: -303px;
}
</style>
