<template>
    <div>
      <table>
        <thead>
          <tr>
            <th v-for="(column, index) in columns" :key="index">
              {{ column }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
            <td v-for="(column, columnIndex) in columns" :key="columnIndex">
              <div v-if="!isEditing(rowIndex, columnIndex)">
                {{ row[column] }}
                <button @click="edit(rowIndex, columnIndex)">
                  Edit
                </button>
              </div>
              <div v-else>
                <input type="text" v-model="row[column]" />
                <button @click="save(rowIndex, columnIndex)">Save</button>
                <button @click="cancel(rowIndex, columnIndex)">Cancel</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      content: {
        type: String,
        required: true,
      },
      saveApiUrl: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        editStatus: {},
        editedRows: [],
        columns: ['column1', 'column2', 'column3'],
        rows: [
          {
            column1: 'value1',
            column2: 'value2',
            column3: 'value3',
          },
          {
            column1: 'value4',
            column2: 'value5',
            column3: 'value6',
          },
        ],
      }
    },
    mounted() {
      console.log("mounted");
    },
    methods: {
      isEditing(rowIndex, columnIndex) {
        return this.editStatus[rowIndex] === columnIndex
      },
      edit(rowIndex, columnIndex) {
        this.editStatus[rowIndex] = columnIndex
      },
      save(rowIndex, columnIndex) {
        const editedRow = this.rows[rowIndex]
        editedRow[columnIndex] = this.editedRows[rowIndex][columnIndex]
  
        fetch(this.saveApiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            row: editedRow,
            columnName: this.columns[columnIndex],
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            // Обработка успешного ответа сервера
          })
          .catch((error) => {
            // Обработка ошибки запроса
          })
  
        this.editStatus = {}
        this.editedRows = []
      },
      cancel(rowIndex, columnIndex) {
        this.editStatus = {}
        this.editedRows = []
      },
    },
    watch: {
      rows(newRows) {
        this.editedRows = newRows.map((row) => ({ ...row }))
      },
    },
  }
  </script>