<template>
  <Layout url_prefix="teacher">
    <template v-if="test" #page_header>
      <div class="h1">{{ test.name }}</div>
    </template>
    <template v-if="test" #page_content>
      <CreateAssignmentGradesStudents
        v-if="students && grades && test"
        :hasVariants="test.has_second_variant"
        :grades="grades"
        :students="students"
        :default_time_limit="test.default_time_limit"
        @createAssignment="createAssignment"
      ></CreateAssignmentGradesStudents>
      <div class="spacer50"></div>
      <template v-if="assignments && assignments.length">
        <div class="table__container">
          <table class="table">
            <thead>
              <tr>
                <th>Класс</th>
                <th>Дата прошлого назначения</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="assignment in assignments" :key="assignment.id">
                <td>
                  <router-link
                    v-if="grades && assignment.grade"
                    class="link"
                    :to="{
                      name: 'teacher-grade-edit',
                      params: { id: assignment.grade },
                    }"
                  >
                    {{ findGradeById(assignment.grade).full_name }}
                  </router-link>
                  <router-link
                    v-if="
                      !assignment.grade &&
                      assignment.sessions.length &&
                      students
                    "
                    class="link"
                    :to="{
                      name: 'teacher-student-edit',
                      params: { id: assignment.sessions[0].student },
                    }"
                  >
                    {{ findStudentById(assignment.sessions[0].student).fio }}
                  </router-link>
                </td>
                <td>{{ formatDate(assignment.started_at) }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>

      <div class="right_shifted_form">
        <div class="h2">Редактирование данных</div>
        <TestForm
          v-if="test"
          :initial="test"
          @change="formDataChanged"
        ></TestForm>
        <div class="">
          <!-- <p>
            Внимание! После первого прохождения теста его нельзя будет<br />
            удалить из системы
          </p> -->
          <div class="buttons__container__without_grow">
            <Button class="button_top" @click="save"
              >Сохранить изменения</Button
            >
            <Button
              class="button_gray"
              @click="$router.push({ name: 'teacher-index' })"
              >Выйти</Button
            >
          </div>
        </div>
      </div>
      <Alert ref="alert" />
      <div class="spacer200"></div>
    </template>
  </Layout>
</template>

<script>
import { tests, assignments } from "@/api"
import { mapState } from "vuex"
import formatDate from "@/utils/formatDate"

import Alert from "@/components/Functional/Alert.vue"
import Button from "@/components/UI/Button"
import Layout from "@/components/Layouts/Layout"
import TestForm from "@/components/Forms/TestForm/TestForm.vue"
import CreateAssignmentGradesStudents from "@/components/Forms/CreateAssignmentGradesStudents.vue"

export default {
  props: ["id"],
  components: {
    Alert,
    Layout,
    Button,
    TestForm,
    CreateAssignmentGradesStudents,
  },
  data() {
    return {
      formatDate,
      formData: {},
      test: null,
      assignments: [],
    }
  },
  computed: {
    ...mapState(["grades", "students"]),
  },
  mounted() {
    this.fetchTest()
    this.fetchAssignments()
  },
  methods: {
    async fetchTest() {
      const response = await tests.get(this.id)
      this.test = response
    },
    async fetchAssignments() {
      const response = await assignments.list({
        test: this.id,
        // grade__isnull: false,
      })
      this.assignments = response
    },
    async save() {
      try {
        const response = await tests.update(this.test.id, this.formData)
        this.$store.dispatch("fetchTests")
        this.$refs.alert.alert("Тест успешно сохранён")
      } catch (error) {
        this.$refs.alert.alert(
          "Возникла ошибка при сохранении теста. Проверьте правильность заполнения всех полей."
        )
      }
      // this.$router.push({ name: "teacher-index" })
    },
    formDataChanged(formData) {
      this.formData = formData
    },
    findGradeById(grade_id) {
      if (!this.grades) return null
      return this.grades.find((grade) => grade.id === grade_id)
    },
    findStudentById(student_id) {
      if (!this.students) return null
      return this.students.find((student) => student.id === student_id)
    },
    async createAssignment(grade_id, students, time_limit) {
      if (students.length) {
        await tests.assign_to_students(this.test.id, students, time_limit)
      } else {
        await tests.assign_to_grade(this.test.id, grade_id, time_limit)
      }
      this.$refs.alert.alert("Тест успешно назначен")
      this.fetchAssignments()
    },
  },
}
</script>

<style scoped>
.table th:nth-child(1) {
  width: 250px;
}
.assignment_form {
  margin-bottom: 80px;
}
</style>
