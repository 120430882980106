import store from "@/store"

import { createRouter, createWebHistory } from "vue-router"
import { useExistingTokens } from "@/api/auth"
import redirectProfile from "@/router/redirectProfile"

import HomeView from "../views/HomeView.vue"

import LogIn from "../views/SignIn/LogIn.vue"
import PasswordRecovery from "../views/SignIn/PasswordRecovery.vue"
import PasswordChange from "../views/SignIn/PasswordChange.vue"

import AdminIndex from "@/views/Admin/Index.vue"
import AdminProfile from "@/views/Admin/Profile.vue"
import AdminSchoolEdit from "@/views/Admin/SchoolEdit.vue"
import AdminSchoolCreate from "@/views/Admin/SchoolCreate.vue"

import DirectorIndex from "@/views/Director/Index.vue"
import DirectorProfile from "@/views/Director/Profile.vue"
import DirectorTeacherEdit from "@/views/Director/TeacherEdit.vue"
import DirectorTeacherCreate from "@/views/Director/TeacherCreate.vue"
import DirectorStudentEdit from "@/views/Director/StudentEdit.vue"
import DirectorStudentCreate from "@/views/Director/StudentCreate.vue"
import DirectorTestCreate from "@/views/Director/TestCreate.vue"
import DirectorTestEdit from "@/views/Director/TestEdit.vue"
import DirectorBaseTest from "@/views/Director/BaseTest.vue"

import TeacherIndex from "@/views/Teacher/Index.vue"
import TeacherProfile from "@/views/Teacher/Profile.vue"
import TeacherTestCreate from "@/views/Teacher/TestCreate.vue"
import TeacherTestEdit from "@/views/Teacher/TestEdit.vue"
import TeacherStudentEdit from "@/views/Teacher/StudentEdit.vue"
import TeacherStudentCreate from "@/views/Teacher/StudentCreate.vue"
import TeacherGradeEdit from "@/views/Teacher/GradeEdit.vue"
import TeacherGradeCreate from "@/views/Teacher/GradeCreate.vue"
import TeacherGrade from "@/views/Teacher/Grade.vue"
import TeacherBaseTest from "@/views/Teacher/BaseTest.vue"

import StudentIndex from "@/views/Student/Index.vue"
import StudentProfile from "@/views/Student/Profile.vue"
import StudentTest from "@/views/Student/Test.vue"
import StudentTestResult from "@/views/Student/TestResult.vue"

import HowToUse from "@/views/HowToUse.vue"

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LogIn,
  },
  {
    path: "/logout",
    name: "logout",
  },
  {
    path: "/password-recovery",
    name: "password-recovery",
    component: PasswordRecovery,
  },
  {
    path: "/password-change",
    name: "password-change",
    component: PasswordChange,
  },
  {
    path: "/how-to-use",
    name: "how-to-use",
    component: HowToUse,
  },
  // ======== Admin ========
  {
    path: "/admin",
    name: "admin-index",
    component: AdminIndex,
  },
  {
    path: "/admin/profile",
    name: "admin-profile",
    component: AdminProfile,
  },
  {
    path: "/admin/school/edit/:id",
    name: "admin-school-edit",
    component: AdminSchoolEdit,
    props: true,
  },
  {
    path: "/admin/school/add",
    name: "admin-school-add",
    component: AdminSchoolCreate,
  },
  // ======== Director ========
  {
    path: "/director",
    name: "director-index",
    component: DirectorIndex,
  },
  {
    path: "/director/tab/:activeTab",
    name: "director-index-with-tab",
    component: DirectorIndex,
  },
  {
    path: "/director/tab/:activeTab/type/:testsType",
    name: "director-index-tests-type",
    component: DirectorIndex,
  },
  {
    path: "/director/profile",
    name: "director-profile",
    component: DirectorProfile,
  },
  {
    path: "/director/teacher/edit/:id",
    name: "director-teacher-edit",
    component: DirectorTeacherEdit,
    props: true,
  },
  {
    path: "/director/teacher/add",
    name: "director-teacher-add",
    component: DirectorTeacherCreate,
  },
  {
    path: "/director/test/add",
    name: "director-test-add",
    component: DirectorTestCreate,
  },
  {
    path: "/director/base-test/:id",
    name: "director-base-test",
    component: DirectorBaseTest,
    props: true,
  },
  {
    path: "/director/student/add",
    name: "director-student-add",
    component: DirectorStudentCreate,
  },
  {
    path: "/director/student/edit/:id",
    name: "director-student-edit",
    component: DirectorStudentEdit,
    props: true,
  },
  {
    path: "/director/test/edit/:id",
    name: "director-test-edit",
    component: DirectorTestEdit,
    props: true,
  },
  // ======== Teacher ========
  {
    path: "/teacher",
    name: "teacher-index",
    component: TeacherIndex,
  },
  {
    path: "/teacher/tab/:activeTab",
    name: "teacher-index-with-tab",
    component: TeacherIndex,
  },
  {
    path: "/teacher/tab/:activeTab/type/:testsType",
    name: "teacher-index-tests-type",
    component: TeacherIndex,
  },
  {
    path: "/teacher/profile",
    name: "teacher-profile",
    component: TeacherProfile,
  },

  {
    path: "/teacher/grade/view/:id",
    name: "teacher-grade-view",
    component: TeacherGrade,
    props: true,
  },
  {
    path: "/teacher/grade/edit/:id",
    name: "teacher-grade-edit",
    component: TeacherGradeEdit,
    props: true,
  },
  {
    path: "/teacher/grade/add",
    name: "teacher-grade-add",
    component: TeacherGradeCreate,
  },
  {
    path: "/teacher/student/add",
    name: "teacher-student-add",
    component: TeacherStudentCreate,
  },
  {
    path: "/teacher/student/edit/:id",
    name: "teacher-student-edit",
    component: TeacherStudentEdit,
    props: true,
  },
  {
    path: "/teacher/test/add",
    name: "teacher-test-add",
    component: TeacherTestCreate,
  },
  {
    path: "/teacher/test/edit/:id",
    name: "teacher-test-edit",
    component: TeacherTestEdit,
    props: true,
  },
  {
    path: "/teacher/base-test/:id",
    name: "teacher-base-test",
    component: TeacherBaseTest,
    props: true,
  },
  // ======== Student ========
  {
    path: "/student",
    name: "student-index",
    component: StudentIndex,
  },
  {
    path: "/student/profile",
    name: "student-profile",
    component: StudentProfile,
  },
  {
    path: "/student/test/:id",
    name: "student-test",
    component: StudentTest,
    props: true,
  },
  {
    path: "/student/test/result/:id",
    name: "student-test-result",
    component: StudentTestResult,
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  },
  routes,
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/password-recovery", "/password-change"]
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem("accessToken")
  const hasProfile = store.state.userProfile !== null

  if (authRequired && !loggedIn) {
    return next("/login")
  }

  if (authRequired && !hasProfile) {
    useExistingTokens().then((profile) => {
      redirectProfile(profile, to.name)
    })
  }

  next()
})

export default router
