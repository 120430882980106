<template>
  <a href="#" v-if="!isSecondary" @click="logout"><slot></slot></a>
  <a href="#" v-else @click="logoutToSaved"><slot></slot></a>
</template>

<script>
import {
  logout as actionLogout,
  isSecondaryUser,
  logoutToSaved as actionLogoutToSaved,
} from "@/api/auth"

export default {
  computed: {
    isSecondary() {
      return isSecondaryUser()
    },
  },
  methods: {
    logout() {
      actionLogout()
      this.$router.push({ name: "login" })
    },
    logoutToSaved() {
      actionLogoutToSaved()
    },
  },
}
</script>
