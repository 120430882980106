<template>
  <Layout url_prefix="student">
    <template #page_header>
      <div class="h1">
        Личный кабинет ученика<br />
        <template v-if="userProfile"
          >{{ userProfile.fio }} ({{ userProfile.grade.full_name }})</template
        >
      </div>
      <div class="tabs_container">
        <div
          @click="changeActiveTab('new_tests')"
          class="tab"
          :class="{ tab_active: activeTab == 'new_tests' }"
        >
          <div class="h2" @click="">Новые тесты</div>
        </div>
        <div
          @click="changeActiveTab('completed_tests')"
          class="tab"
          :class="{ tab_active: activeTab == 'completed_tests' }"
        >
          <div class="h2">Завершенные тесты</div>
        </div>
      </div>
    </template>

    <template #page_content>
      <div v-if="activeTab == 'new_tests'">
        <template v-if="newSessions && newSessions.length > 0">
          <div class="h2">Новые тесты</div>
          <div class="table__container">
            <table class="table" v-if="newSessions">
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Назначен</th>
                  <th>Статус</th>
                  <th>Действия</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="session in newSessions" :key="session.id">
                  <td>
                    <div>
                      {{ session.assignment.test.name }}, вар.
                      {{ session.variant }}
                    </div>
                    <div>
                      <span class="assignment_teacher" style="color: #777">{{
                        session.assignment.teacher
                      }}</span>
                    </div>
                  </td>
                  <td>
                    {{ formatDate(session.assignment.started_at) }}
                  </td>
                  <td>
                    <TestResultStatus :session="session"></TestResultStatus>
                  </td>
                  <td>
                    <Button
                      class="button_slim"
                      @click="startSession(session.id)"
                      v-if="session.status == 'A'"
                    >
                      Старт!
                    </Button>
                    <Button
                      class="button_slim button_purple"
                      @click="joinSession(session.id)"
                      v-if="session.status == 'S' || session.status == 'P'"
                    >
                      Продолжить
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
        <template v-else>
          <div class="h2 centered">Новых тестов нет</div>
        </template>
      </div>

      <div v-if="activeTab == 'completed_tests'">
        <template v-if="finishedSessions.length > 0">
          <div class="h2">Завершенные тесты</div>
          <div class="table__container">
            <table class="table">
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Прошлое открытие</th>
                  <th>Статус</th>
                  <th>Время</th>
                  <th>Верно</th>
                  <th>Неверно</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="session in finishedSessions" :key="session.id">
                  <td>
                    <div>
                      {{ session.assignment.test.name }}, вар.
                      {{ session.variant }}
                    </div>
                    <div>
                      <span class="assignment_teacher" style="color: #777">{{
                        session.assignment.teacher
                      }}</span>
                    </div>
                  </td>
                  <td class="spaced-datetime">
                    {{ formatDate(session.finished_at) }}
                  </td>

                  <template v-if="session.result">
                    <!-- Явился, есть результат -->

                    <td>
                      <div
                        class="iconed-percent greenz-link"
                        v-if="statusInPercent(session) == 100"
                      >
                        <img
                          class="small-icon"
                          src="@/assets/img/icon_done.svg"
                          alt=""
                        />
                        {{ statusInPercent(session) }}%
                      </div>
                      <div class="iconed-percent alert" v-else>
                        <img
                          class="small-icon"
                          src="@/assets/img/failed.svg"
                          alt=""
                        />
                        {{ statusInPercent(session) }}%
                      </div>
                    </td>

                    <td>
                      <TimeFromSeconds :time="session.result?.total_seconds" />
                    </td>
                    <td>{{ session.result?.total_points }}</td>
                    <td style="white-space: nowrap">
                      {{
                        session.result?.question_count -
                        session.result?.total_points
                      }}
                      <template
                        v-if="
                          session.result?.question_count -
                            session.result?.total_points !=
                          0
                        "
                      >
                        (<span style="color: red" @click="showMistakes(session)"
                          >ошибки</span
                        >)</template
                      >
                    </td>
                  </template>
                  <template v-else>
                    <!-- Не явился -->
                    <td>н/я</td>
                    <td>—</td>
                    <td>—</td>
                    <td>—</td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
        <template v-else>
          <div class="h2 centered">Завершенных тестов нет</div>
        </template>
      </div>
    </template>

    <MistakesModal ref="mistakesModal" />
  </Layout>
</template>

<script>
import { sessions } from "@/api"
import { mapState } from "vuex"
import formatDate from "@/utils/formatDate"

import Button from "@/components/UI/Button.vue"
import TestResultStatus from "@/components/Functional/TestResultStatus.vue"
import Layout from "@/components/Layouts/Layout.vue"
import TimeFromSeconds from "@/components/UI/TimeFromSeconds.vue"
import MistakesModal from "@/components/Functional/MistakesModal.vue"

const SESSIONS_REFRESH_INTERVAL = 10 * 1000

export default {
  components: {
    Layout,
    Button,
    TestResultStatus,
    TimeFromSeconds,
    MistakesModal,
  },
  data() {
    return {
      formatDate,
      sessions: [],
      timer: null,
      activeTab: "new_tests",
    }
  },
  computed: {
    ...mapState(["userProfile"]),
    newSessions() {
      return this.sessions.filter(
        (session) => ["A", "S", "P"].indexOf(session.status) > -1
      )
    },
    finishedSessions() {
      return this.sessions.filter(
        (session) => ["F", "N"].indexOf(session.status) > -1
      )
    },
  },
  mounted() {
    this.getMySessions()
    this.timer = setInterval(() => {
      this.getMySessions()
    }, SESSIONS_REFRESH_INTERVAL)
  },
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    async getMySessions() {
      this.sessions = await sessions.my()
    },
    async startSession(session_id) {
      await sessions.start(session_id)
      this.joinSession(session_id)
    },
    joinSession(session_id) {
      this.$router.push({
        name: "student-test",
        params: { id: session_id },
      })
    },
    changeActiveTab(tab_name) {
      this.activeTab = tab_name
    },
    // showMistakes(session) {
    //   this.$router.push({
    //     name: "student-test-result",
    //     params: { id: session.id },
    //   })
    // },
    async showMistakes(session) {
      this.$refs.mistakesModal.showMistakesForSession(session)
    },
    statusInPercent(session) {
      if (
        session.status == "N" ||
        !session.result ||
        session.result.question_count == 0
      ) {
        return 0
      }
      const result =
        (session.result.total_points / session.result.question_count) * 100
      if (result == 0) {
        return 0
      }
      return result.toFixed(1)
    },
  },
}
</script>

<style scoped>
.table__container {
  padding-bottom: 50px !important;
  padding-top: 0 !important;
}
.table tbody tr {
  height: 50px;
}
tbody td {
  /* vertical-align: bottom; */
  padding-top: 13px;
}
.not_found {
  text-align: center;
}
.button_slim {
  height: 25px;
  padding: 5px 15px 5px 15px;
  line-height: 1px;
}
.tabs_container {
  justify-content: flex-start;
}
.assignment_teacher {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}
.table th:nth-child(2),
.table td:nth-child(2) {
  text-align: right;
  /* padding-right: 40px; */
}

.table th:nth-child(4),
.table td:nth-child(4),
.table th:nth-child(5),
.table td:nth-child(5) {
  text-align: center;
}
.table th:nth-child(3),
.table td:nth-child(3) {
  text-align: left;
  padding-left: 40px;
  max-width: 40px;
}

.greenz-link {
  color: var(--greenz_link);
}
.greenz-link {
  color: var(--greenz_link);
}
.alert {
  color: var(--alert);
}
.spaced-datetime {
  word-spacing: 28px;
}
.iconed-percent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
  height: 20px;
  gap: 5px;
}
</style>
