<template>
  <form class="search" @submit.prevent="">
    <input
      class="search__input"
      :placeholder="placeholder"
      v-model="query"
      @keyup="onInput"
      @focus="showSearchResults"
      @blur="hideSearchResults"
      @keyup.enter="onButtonPress"
    />
    <Button class="button_bottom" @click="onButtonPress">Найти</Button>
    <div
      class="search_results"
      @mouseover="search_results_hovered = true"
      @mouseout="search_results_hovered = false"
      v-if="query.length > 0 && search_results.length && show_search_results"
    >
      <span
        @mousedown="onSearchResultClick(search_result)"
        v-for="search_result in search_results"
        class="search_results__item"
        v-html="formatResult(query, search_result)"
      >
      </span>
    </div>
  </form>
</template>

<script>
import Button from "@/components/UI/Button"
import ireplace from "@/utils/ireplace"
import { forOwn } from "lodash"

export default {
  components: {
    Button,
  },
  props: {
    placeholder: String,
    search_results: Array,
    formatter: Function,
  },
  data() {
    return {
      query: "",
      show_search_results: false,
      search_results_hovered: false,
    }
  },
  methods: {
    onInput() {
      this.$emit("searchInput", this.query)
    },
    onButtonPress() {
      this.$emit("searchButtonPress", this.query)
    },
    onSearchResultClick(search_result) {
      this.$emit("searchResultClick", search_result)
    },
    formatResult(query, search_result) {
      if (this.formatter) {
        return this.formatter(query, search_result)
      }

      let result = ""
      forOwn(search_result, (value, key) => {
        result += `<span class='search_results__key__${key}'>${value}</span>`
      })

      result = ireplace(
        result,
        this.query,
        "<span class='search_results__item__highlight'>$1</span>"
      )
      return result
    },
    showSearchResults() {
      this.show_search_results = true
    },
    hideSearchResults() {
      if (!this.search_results_hovered) {
        this.show_search_results = false
      }
    },
  },
}
</script>

<style>
.search {
  height: 40px;
  width: 100%;
  /* margin-top: 25px; */
  margin-bottom: 65px;
  display: flex;
  justify-content: center;
  gap: 19px;
  position: relative;
}
.search__input::placeholder {
  font-style: italic;
}
.search__input {
  width: 640px;
  border-radius: 25px 25px 0px 25px;
}
.search_results {
  position: absolute;
  top: 61px;
  margin-left: -114px;
  width: 650px;
  margin-bottom: 30px;
  font-family: "Courier New", Courier, monospace;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #fafafa;
  box-shadow: inset 0px 2px 4px rgb(0 0 0 / 10%);
  border-radius: 3px;
  max-height: 300px;
  overflow: scroll;
  z-index: 2;
}
.search_results__item {
  text-decoration: none;
  color: var(--gray1);
  padding: 10px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}
.search_results__item:hover {
  color: white;
}
.search_results__item:hover {
  background-color: var(--greenz);
}
.search_results__item_field {
}
.search_results__item__highlight {
  font-weight: bold;
  background-color: yellow;
}
.search_results__item:hover .search_results__item__highlight {
  font-weight: bold;
  background-color: darkgoldenrod;
}
</style>
