import router from "@/router"

const redirectProfile = function (profile, targetRouteName) {
  if (!profile) {
    console.log("Tokens are expired")
    router.replace({ name: "login" })
    return
  }
  switch (profile.group) {
    case "admins":
      if (targetRouteName === "home" || targetRouteName.indexOf("admin") != 0) {
        router.replace({ name: "admin-index" })
      }
      break
    case "directors":
      if (
        targetRouteName === "home" ||
        targetRouteName.indexOf("director") != 0
      ) {
        router.replace({ name: "director-index" })
      }
      break
    case "teachers":
      if (
        targetRouteName === "home" ||
        targetRouteName.indexOf("teacher") != 0
      ) {
        router.replace({ name: "teacher-index" })
      }
      break
    case "students":
      if (
        targetRouteName === "home" ||
        targetRouteName.indexOf("student") != 0
      ) {
        router.replace({ name: "student-index" })
      }
      break
    default:
      router.replace({ name: "login" })
      break
  }
}

export default redirectProfile
