import axios from "axios"
import store from "@/store"
import router from "@/router"
import api from "."

const LOGIN_URL = "/authentication/login/"
const LOGIN_AS_URL = "/authentication/login_as/"
const PROFILE_URL = "/authentication/profile/"
const TOKEN_REFRESH_URL = "/authentication/token-refresh/"
const RECOVERY_REQUEST_URL = "/authentication/recovery/request/"
const RECOVERY_PROCEED_URL = "/authentication/recovery/proceed/"
const CHANGE_ADMIN_EMAIL_URL = "/authentication/change_admin_email/"
const CHANGE_OWN_PASSWORD_URL = "/authentication/change_own_password/"
const CHANGE_SUBORDINATE_PASSWORD_URL =
  "/authentication/change_subordinate_password/"

/**
 * Set axios headers to include the access token.
 * @param {String} accessToken
 */
function setHeaders(accessToken) {
  axios.defaults.withCredentials = true
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
}

/**
 * Unset axios authentication headers.
 */
function unsetHeaders() {
  axios.defaults.withCredentials = false
  axios.defaults.headers.common["Authorization"] = ""
}

/**
 * Get user profile.
 * @returns {Promise<Object>} A promise that resolves to the user profile.
 */
async function getProfile() {
  try {
    const profileResponse = await axios.get(PROFILE_URL)
    return profileResponse.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function loginWithTokens(accessToken, refreshToken) {
  setHeaders(accessToken)

  // Storing tokens in local storage
  localStorage.setItem("accessToken", accessToken)
  localStorage.setItem("refreshToken", refreshToken)

  // Getting user profile
  const profile = await getProfile()
  store.commit("setUserProfile", profile)
  return Promise.resolve(profile)
}

/**
 * Login user
 * @param {string} username
 * @param {string} password
 * @returns {Promise<object>} User Profile
 */
export async function login(username, password) {
  const tokensResponse = await axios.post(LOGIN_URL, { username, password })
  const accessToken = tokensResponse.data.access
  const refreshToken = tokensResponse.data.refresh
  return loginWithTokens(accessToken, refreshToken)
}

/**
 * Continue to use existing tokens after page reload
 * @returns {Promise<object>} User Profile
 */
export async function useExistingTokens() {
  const accessToken = localStorage.getItem("accessToken")
  const refreshToken = localStorage.getItem("refreshToken")

  if (!accessToken || !refreshToken) {
    return Promise.reject("No tokens found")
  }

  setHeaders(accessToken)

  // Getting user profile
  try {
    const profile = await getProfile()
    store.commit("setUserProfile", profile)
    return Promise.resolve(profile)
  } catch (error) {
    // If profile is not available, logout
    return logout()
  }
}

/**
 * Logout user
 * @returns {Promise}
 */
export async function logout() {
  // Removing tokens from local storage
  localStorage.removeItem("accessToken")
  localStorage.removeItem("refreshToken")
  localStorage.removeItem("savedAccessToken")
  localStorage.removeItem("savedRefreshToken")

  // Removing user profile from store
  store.commit("setUserProfile", null)

  // Removing headers
  unsetHeaders()

  // Redirecting to home page
  router.push("/")

  return Promise.resolve()
}

/**
 * Refresh access token
 * @returns {Promise<object>} New access token
 */
export async function refreshToken() {
  const token = localStorage.getItem("refreshToken")
  if (token == null) {
    return Promise.reject()
  }
  try {
    const tokensResponse = await axios.post(TOKEN_REFRESH_URL, {
      refresh: token,
    })
    const tokens = {
      refresh: token,
      access: tokensResponse.data.access,
    }
    setHeaders(tokens.access)
    localStorage.setItem("accessToken", tokens.access)
    return Promise.resolve(tokens.access)
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Logins as another user
 * @param {string} username
 * @returns {Promise<object>} User Profile
 */
export async function loginAs(username) {
  const currentAccessToken = localStorage.getItem("accessToken")
  const currentRefreshToken = localStorage.getItem("refreshToken")

  const tokensResponse = await axios.post(LOGIN_AS_URL, { username })

  // Storing original tokens
  localStorage.setItem("secondaryUserMode", "1")

  if (localStorage.getItem("savedTokens") == null) {
    localStorage.setItem("savedTokens", JSON.stringify([]))
  }

  let savedTokens = JSON.parse(localStorage.getItem("savedTokens"))
  savedTokens.push({
    access: currentAccessToken,
    refresh: currentRefreshToken,
  })
  localStorage.setItem("savedTokens", JSON.stringify(savedTokens))

  const newAccessToken = tokensResponse.data.access
  const newRefreshToken = tokensResponse.data.refresh

  return loginWithTokens(newAccessToken, newRefreshToken)
}

/**
 * Logout secondary user and return to saved tokens
 * @returns {Promise<object>} User Profile
 */
export async function logoutToSaved() {
  if (localStorage.getItem("secondaryUserMode") !== "1") {
    return Promise.reject("Not in secondary user mode")
  }

  let savedTokens = JSON.parse(localStorage.getItem("savedTokens"))
  if (!savedTokens || savedTokens.length === 0) {
    return Promise.reject("No saved tokens found")
  }

  const lastSavedTokens = savedTokens.pop()
  localStorage.setItem("savedTokens", JSON.stringify(savedTokens))

  const savedAccessToken = lastSavedTokens.access
  const savedRefreshToken = lastSavedTokens.refresh

  if (savedTokens.length === 0) {
    localStorage.removeItem("secondaryUserMode")
  }

  // Removing user profile from store
  store.commit("setUserProfile", null)

  // Removing headers
  unsetHeaders()

  // Restoring primary tokens and profile
  return loginWithTokens(savedAccessToken, savedRefreshToken)
}

/**
 * @returns {boolean} True if user is in secondary user mode
 */
export function isSecondaryUser() {
  return localStorage.getItem("secondaryUserMode") === "1"
}

/**
 * Sends password recovery request to server
 * @param {string} username
 * @returns {Promise<object>} Response
 */
export async function recoveryRequest(username) {
  return axios.post(RECOVERY_REQUEST_URL, { username })
}

/**
 * Resets password using recovery token
 * @param {string} username
 * @returns {Promise<object>} Response
 */
export async function recoveryProceed(token, new_password) {
  const tokensResponse = await axios.post(RECOVERY_PROCEED_URL, {
    token,
    password: new_password,
  })
  const accessToken = tokensResponse.data.access
  const refreshToken = tokensResponse.data.refresh

  return loginWithTokens(accessToken, refreshToken)
}

/**
 * Sends own password change request to server
 * @param {string} new_password
 * @returns {Promise<object>} New access and refresh tokens
 */
export async function changeOwnPassword(new_password) {
  const tokensResponse = await axios.post(CHANGE_OWN_PASSWORD_URL, {
    new_password: new_password,
  })
  const accessToken = tokensResponse.data.access
  const refreshToken = tokensResponse.data.refresh

  return loginWithTokens(accessToken, refreshToken)
}

/**
 * Sends subordinate password change request to server
 * @param {string} user_id
 * @param {string} new_password
 * @returns {Promise<object>} New access and refresh tokens
 */
export async function changeSubordinatePassword(user_id, new_password) {
  const response = await axios.post(CHANGE_SUBORDINATE_PASSWORD_URL, {
    user_id,
    new_password,
  })
  return response
}

/**
 * Changes admin email
 * @param {string} new_email
 * @returns {Promise<object>} Response
 */
export async function changeAdminEmail(new_email) {
  const response = await axios.post(CHANGE_ADMIN_EMAIL_URL, { new_email })
  return response
}

/**
 * Refreshes user profile
 * @returns {Promise<Object>} A promise that resolves to the user profile.
 */
export async function refreshProfile() {
  const profile = await getProfile()
  store.commit("setUserProfile", profile)
  return Promise.resolve(profile)
}
