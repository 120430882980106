<template>
  <span
    class="test_result_action"
    v-if="test_result.action"
    :class="'test_result_action_' + test_result.action"
  >
    <span
      class="test_result_action_row dotted_link"
      v-if="test_result.action == 'resume'"
      ><div class="icon_container"></div>
      продолжить</span
    >
    <span
      class="test_result_action_row dotted_link"
      v-if="test_result.action == 'restart'"
      ><div class="icon_container"></div>
      пройти заново</span
    >
  </span>
</template>
<script>
export default {
  props: ["session"],
  data() {
    return {}
  },
  mounted() {},
}
</script>
<style>
.test_result_action_resume {
  color: var(--director-primary-color);
}
.test_result_action_restart {
  color: var(--red);
}
.test_result_action_row {
}
</style>
