<template>
  <div class="layout-wrapper">
    <div class="navigation_menu">
      <div class="navigation_menu__header">
        <router-link
          class="unstyled_link"
          :to="{ name: `${url_prefix}-index` }"
          style="outline: none"
        >
          <img class="st-logo" src="@/assets/img/st_logo.png" />
        </router-link>
      </div>

      <div class="navigation_menu__items">
        <Button class="nav-button" :to="{ name: `${url_prefix}-index` }"
          >Личный кабинет</Button
        >
        <div class="avatar" v-if="userProfile?.avatar_base64">
          <router-link :to="{ name: `${url_prefix}-profile` }">
            <img :src="userProfile?.avatar_base64" />
          </router-link>
        </div>

        <Button v-else :to="{ name: `${url_prefix}-profile` }" title="Профиль">
          <img src="@/assets/img/profile-icon.svg" />
        </Button>

        <LogoutButton class="button navigation_menu__item" title="Выйти">
          <img src="@/assets/img/exit-icon.svg" />
        </LogoutButton>
      </div>
    </div>

    <div class="page_header">
      <slot name="page_header"></slot>
    </div>

    <div class="page_content">
      <slot name="page_content"></slot>
    </div>

    <slot> </slot>
  </div>
  <footer class="footer">
    <div class="footer-links">
      <a href="https://test-online.ru">О проекте</a>
      <a href="https://test-online.ru/faq" target="_blank">Как пользоваться</a>
    </div>
  </footer>
</template>

<script>
import LogoutButton from "@/components/Functional/LogoutButton"
import Button from "@/components/UI/Button"
import { mapState } from "vuex"

export default {
  name: "Layout",
  props: ["url_prefix"],
  components: {
    LogoutButton,
    Button,
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  mounted() {
    document.body.style.margin = 0
  },
}
</script>

<style>
/* .admin-layout .unstyled_link {
  color: var(--admin-primary-color);
} */

.admin-layout .navigation_menu__item {
  color: var(--admin-primary-color);
}
.admin-layout .navigation_menu__header {
  color: var(--admin-primary-color);
}
.admin-layout .expand_table_link {
  color: var(--admin-primary-color);
}
</style>
<style scoped>
.vs__dropdown-option--highlight {
  --vs-dropdown-option--active-bg: var(--admin-primary-color);
}
.avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 25px;
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.footer {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  height: 73px;
  border-top: 5px solid #9fd900;
  background-color: #f2f2f2;
  /* margin-left: -50px; */
}
.footer-links {
  padding-top: 24px;
  padding-left: 95px;
  display: flex;
  gap: 29px;
}
.footer-links a {
  color: #828282;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.layout-wrapper {
  min-height: calc(100vh - 78px);
  margin-left: 50px;
  margin-right: 50px;
}
.footer-link.router-link-active {
  background-color: unset;
  outline: none;
  color: inherit;
}
</style>
