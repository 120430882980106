<template>
  <p>Загрузка...</p>
</template>

<script>
export default {
  name: "HomeView",
}
</script>

<style scoped>
.home {
  text-align: center;
  margin-top: 100px;
}
</style>
