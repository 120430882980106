<template>
  <component
    v-if="selectedAnswerForm"
    ref="answersComponent"
    :is="selectedAnswerForm"
    :question="modelValue"
    @update="updateModelValue"
  />
</template>

<script>
import { pick } from "lodash"
import { convertToCamelCase } from "@/utils/strings"

import AnswersSingleForm from "./AnswerForms/AnswersSingleForm.vue"
import AnswersMultipleForm from "./AnswerForms/AnswersMultipleForm.vue"
import AnswersStringForm from "./AnswerForms/AnswersStringForm.vue"
import AnswersNumberForm from "./AnswerForms/AnswersNumberForm.vue"
import AnswersSequenceForm from "./AnswerForms/AnswersSequenceForm.vue"
import AnswersMatchForm from "./AnswerForms/AnswersMatchForm.vue"
import AnswersSkipsForm from "./AnswerForms/AnswersSkipsForm.vue"
import AnswersTableForm from "./AnswerForms/AnswersTableForm.vue"
import AnswersMatchSelectForm from "./AnswerForms/AnswersMatchSelectForm.vue"

export default {
  components: {
    AnswersSingleForm,
    AnswersMultipleForm,
    AnswersStringForm,
    AnswersNumberForm,
    AnswersSequenceForm,
    AnswersMatchForm,
    AnswersSkipsForm,
    AnswersTableForm,
    AnswersMatchSelectForm,
  },
  props: ["modelValue"],
  emits: ["update:modelValue"],
  data() {
    return {
      selectedAnswerForm: null,
    }
  },
  mounted() {
    this.switchType(this.modelValue.type)
  },
  methods: {
    switchType(type) {
      if (!type) return

      // Select component name by question type
      const componentName = "Answers" + convertToCamelCase(type, true) + "Form"
      this.selectedAnswerForm = componentName

      // Propagate answer fields by calling emitUpdate on selected component
      setImmediate(() => {
        this.$refs.answersComponent.emitUpdate()
      })
    },
    updateModelValue(answersData) {
      // Merge answers data with question data and update v-model
      const questionData = pick(this.modelValue, [
        "id",
        "type",
        "question",
        "description",
        "variant",
        "keyboard_type",
      ])
      this.$emit("update:modelValue", {
        ...questionData,
        ...answersData,
      })
    },
  },
  watch: {
    "modelValue.type"(type) {
      this.switchType(type)
    },
  },
}
</script>
