<template>
  <!-- ВОПРОСЫ -->
  <div class="question_block" :key="'q-' + question.id">
    <div class="question_header_badge h2">Вопрос № {{ question_index }}</div>
    <div class="form school_form">
      <!-- Тип ответа -->
      <Row label="Тип ответа">
        <PixelionSelect
          :options="answer_type_options"
          :reduce="(t) => t.value"
          v-model="question.type"
        />
      </Row>

      <div
        class="keyboard_select"
        v-if="question.type == 'single' || question.type == 'multiple'"
      >
        <Row label="Тип клавиатуры" class="">
          <div class="text_editor_container">
            <input
              type="radio"
              :name="question.id + '_keyboard_type'"
              :id="question.id + '-keyboard-simple'"
              v-model="question.keyboard_type"
              value=""
            />
            <label :for="question.id + '-keyboard-simple'">Обычная</label>
            <input
              type="radio"
              :name="question.id + '_keyboard_type'"
              :id="question.id + '-keyboard-fruits'"
              v-model="question.keyboard_type"
              value="fruits"
            />
            <label :for="question.id + '-keyboard-fruits'">«Фрукты»</label>
            <input
              type="radio"
              :name="question.id + '_keyboard_type'"
              :id="question.id + '-keyboard-shapes'"
              v-model="question.keyboard_type"
              value="shapes"
            />
            <label :for="question.id + '-keyboard-shapes'">«Фигуры»</label>
          </div>
        </Row>
      </div>

      <!-- Заголовок вопроса -->
      <Row
        :label="question.type != 'skips' ? `Вопрос` : `Вопрос (суть задания)`"
      >
        <!-- <input
          class="question_header"
          v-model="question.question"
          ref="question_header"
        /> -->
        <div class="text_editor_container" style="display: flex">
          <!-- <QuillEditor
            class="question_header_editor"
            toolbar="full"
            theme="snow"
            v-model:content="question.question"
            contentType="html"
            :modules="quillModules"
          /> -->
          <!-- <input
            class="question_header"
            v-model="question.question"
            ref="question_header"
            style="display: flex; flex: 1;"
          /> -->
          <textarea
            class="question_header"
            data-min-rows="1"
            rows="1"
            v-model="question.question"
            style="display: flex; flex: 1"
            ref="question_header"
            @keyup="change"
            @keydown.enter.prevent
          >
          </textarea>
        </div>
      </Row>

      <!-- Подробности вопроса -->
      <!-- <Row
        label="Подробности и примечания к вопросу"
        top_label
        v-if="question.type != 'skips' && question.type != 'table'"
      >
        <div class="text_editor_container">
          <QuillEditor
            class="question_description_editor"
            toolbar="full"
            theme="snow"
            v-model:content="question.description"
            contentType="html"
            :modules="quillModules"
          />
        </div>
      </Row> -->

      <!-- Подробности вопроса (таблица) -->
      <Row
        label="Таблица и примечания к вопросу (отображаются под кратким вопросом)"
        top_label
        v-if="question.type != 'skips'"
      >
        <div style="flex: 1" class="text_editor_container">
          <editor
            class="table_editor"
            v-model="question.description"
            api-key="8b01uvmy0ecrxmm4tpf682w4yxv238by1oc574229ewglbbt"
            :init="{
              height: 400,
              menubar: false,
              language: 'ru',
              statusbar: false,
              plugins: ['table', 'code', 'image', 'paste', 'link', 'media'],
              paste_data_images: true,
              automatic_uploads: true,
              file_picker_types: 'image',
              file_picker_callback: filePickerCallback,
              toolbar1:
                'undo redo | h1 h2 | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | numlist bullist | image media | table | code',
              toolbar2:
                'subscript superscript | link | styleselect fontsizeselect forecolor | ',
            }"
          />
        </div>
      </Row>

      <div class="answers">
        <AnswersFormProxy v-model="question" />
      </div>
    </div>
    <div class="question_control_container">
      <div class="question_control_buttons">
        <Button class="" @click="$emit('add')">+ ещё вопрос</Button>
        <Button class="question_control" v-if="!is_last" @click="$emit('down')">
          <img
            class="question_control_icon"
            src="@/assets/img/arrow_down.png"
          />
        </Button>
        <Button class="question_control" v-if="!is_first" @click="$emit('up')">
          <img class="question_control_icon" src="@/assets/img/arrow_up.png" />
        </Button>
        <Button
          class="question_control trash_button"
          v-if="!(is_first && is_last)"
          @click="$emit('del')"
        >
          <span class="icon icon-trash"></span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash"

import "@vueup/vue-quill/dist/vue-quill.snow.css"
import Editor from "@tinymce/tinymce-vue"

import { QuillEditor } from "@vueup/vue-quill"
import ImageResize from "quill-image-resizer-plugin"

import AnswersFormProxy from "./AnswersFormProxy.vue"

import Button from "@/components/UI/Button.vue"
import PixelionSelect from "@/components/UI/PixelionSelect.vue"
import CenteredFormRow from "@/components/UI/CenteredFormRow.vue"

import EditableTable from "@/components/UI/EditableTable.vue"

import { defaultTable } from "@/utils/const"
import { scaleToBox } from "@/utils/base64"

export default {
  props: [
    "question",
    "question_index",
    "is_first",
    "is_last",
    "selected_variant",
  ],
  emits: ["add", "del", "up", "down", "update"],
  components: {
    AnswersFormProxy,
    Button,
    QuillEditor,
    PixelionSelect,
    EditableTable,
    Editor,
    Row: CenteredFormRow,
  },
  data() {
    return {
      answer_type_options: [
        { label: "Одиночный выбор", value: "single" },
        { label: "Множественный выбор", value: "multiple" },
        { label: "Ввод текста", value: "string" },
        { label: "Ввод числа", value: "number" },
        { label: "Найти последовательность", value: "sequence" },
        { label: "Установить соответствие", value: "match" },
        { label: "Заполнить пропуски", value: "skips" },
        { label: "Заполнить пропуски в таблице", value: "table" },
        { label: "Найти соответствие из множества", value: "match_select" },
      ],
      quillModules: [
        {
          name: "imageResize",
          module: ImageResize,
        },
      ],
    }
  },
  mounted() {
    // Hack: for some Vue-internals reason, only last question updates properly.
    // Everything works after manual update:
    setTimeout(() => {
      this.updateQuestion(this.question)
    }, 300)

    this.$nextTick(() => {
      this.expandField(this.$refs.question_header)
    })
    this.toxChecker = setInterval(this.checkTox, 100)
  },
  methods: {
    updateQuestion(updatedQuestion) {
      this.$emit("update", updatedQuestion)
    },
    debouncedEmitUpdate: debounce(function (updatedQuestion) {
      this.updateQuestion(updatedQuestion)
    }, 300),
    change(event) {
      this.debouncedEmitUpdate(this.question)
      if (event) {
        this.expandField(event.target)
      }
    },
    getScrollHeight(elm) {
      var savedValue = elm.value
      elm.value = ""
      elm._baseScrollHeight = elm.scrollHeight
      elm.value = savedValue
    },
    expandField(elm) {
      var minRows = elm.getAttribute("data-min-rows") | 0
      !elm._baseScrollHeight && this.getScrollHeight(elm)
      elm.rows = minRows
      let rows = Math.ceil((elm.scrollHeight - elm._baseScrollHeight) / 18)
      elm.rows = minRows + rows
    },
    filePickerCallback(cb, value, meta) {
      var input = document.createElement("input")
      input.setAttribute("type", "file")
      input.setAttribute("accept", "image/*")

      input.onchange = function () {
        var file = this.files[0]

        var reader = new FileReader()
        reader.onload = function () {
          /*
          Note: Now we need to register the blob in TinyMCEs image blob
          registry. In the next release this part hopefully won't be
          necessary, as we are looking to handle it internally.
        */
          var id = "blobid" + new Date().getTime()
          var blobCache = tinymce.activeEditor.editorUpload.blobCache

          scaleToBox(reader.result, 500, 500).then((croppedImage) => {
            var blobInfo = blobCache.create(
              id,
              file,
              croppedImage.split(",")[1]
            )
            blobCache.add(blobInfo)
            cb(blobInfo.blobUri(), { title: file.name, width: "500" })
          })
        }
        reader.readAsDataURL(file)
      }

      input.click()
    },
  },
  watch: {
    question: {
      handler(updatedQuestion) {
        const cleanDesc = updatedQuestion.description
          .replaceAll("<p><br></p>", "")
          .replaceAll("<p>\n</p>", "")
        if (updatedQuestion.type == "table" && cleanDesc == "") {
          updatedQuestion.description = defaultTable
        } else if (cleanDesc == "") {
          updatedQuestion.description = ""
        }
        this.debouncedEmitUpdate(updatedQuestion)
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.question_block {
  position: relative;
  margin: 50px 0;
  padding: 50px;
  border: 1px solid var(--greenz);
  border-radius: 25px;
  display: flex;
  flex-flow: column wrap;
  gap: 10px;
}
.question_header_badge.h2 {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-top: 0;
  text-align: center;
  top: -20px;
  background: var(--greenz);
  width: 168px;
  line-height: 35px;
  padding-top: 3px;
}
.small {
  font-size: 0.8em;
  padding: 5px 10px;
}
.question_block .centered_form__label {
  /* background: aqua; */
  /* width: 300px; */
}
.school_form {
  margin-left: 0;
  width: 100%;
}
.school_form >>> .v-select,
.text_editor_container {
  /* width: 555px; */
  flex: 1;
}
.question_header {
  width: 530px;
}
.school_form >>> .centered_form__label {
  width: 223px;
}
.question_header {
  font-family: PTSansWebBold;
  background-color: var(--greenz);
  font-weight: normal;
  font-size: 1em;
  line-height: 1;
  resize: none;
  border-radius: 25px;
  box-shadow: inset 0px 2px 4px rgb(0 0 0 / 10%);
  border: 0;
  padding: 20px;
  overflow-y: hidden;
}
.question_header:focus {
  border: none;
  outline: none;
}
.form {
  gap: 21px;
}
.text_editor_container >>> .question_description_editor {
  /* height: 120px; */
  height: fit-content;
  min-height: 120px;
  background-color: #f9f9f9;
  border-radius: 0 0 25px 25px;
  font-size: 17px;
}

.text_editor_container >>> .ql-editor {
  /* height: 120px !important; */
  height: fit-content;
  min-height: 120px;
}
.text_editor_container >>> .ql-container.ql-snow {
  border: 0;
}
.answers >>> .answers_form__header {
  text-align: right;
  width: 223px;
  font-size: 21px;
}
.answers >>> .answers_hint_icon {
  position: absolute;
  height: 18px;
  right: 33px;
  top: 12px;
}
.answers >>> .answers_hint_text {
  font-style: italic;
  color: #828282;
  text-align: right;
  padding-right: 60px;
}
.answers >>> .answers_hint {
  position: relative;
  padding-bottom: 20px;
}
.question_control_icon {
  height: 22px;
  /* width: 22px; */
}
.question_control {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button_red {
  width: 15px;
}
.question_control_container {
  width: 100%;
  position: relative;
}
.question_control_buttons {
  display: flex;
  justify-content: flex-end !important;
  position: absolute;
  gap: 10px;
  right: -27px;
  bottom: -75px;
}
.question_control_buttons .button {
  background-color: var(--gray7) !important;
}
.question_control .icon {
  font-size: 20px;
}
.question_control_buttons >>> .button:hover {
  background-color: var(--greenz) !important;
}
.question_control_buttons >>> .trash_button:hover {
  background-color: var(--alert) !important;
}
.question_control_buttons >>> .trash_button {
  padding: 15px 15px !important;
  height: 20px;
  width: 20px;
}
.question_control_buttons >>> .trash_button:hover .icon-trash:before {
  color: white;
}
.text_editor_container >>> .tox.tox-tinymce {
  border-radius: 0 0 25px 25px;
  border: 0;
}

.text_editor_container >>> .tox-editor-header {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.text_editor_container >>> .tox .tox-edit-area__iframe {
  background-color: #f9f9f9;
}
.keyboard_select {
  width: 100%;
}
.keyboard_select input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  height: 25px;
  width: 25px;
  /* display: grid;
  place-content: center; */
  border-radius: 50%;
  position: relative;
}
/* .keyboard_select input[type="radio"]::before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

.keyboard_select input[type="radio"]:checked::before {
  transform: scale(1);
} */
.keyboard_select input[type="radio"]:checked::before {
  background-color: #9fd900;
  content: "";
  width: 9px;
  height: 9px;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
}

.keyboard_select label {
  width: fit-content;
  padding-bottom: 0;
  padding-left: 5px;
}
.keyboard_select .text_editor_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.answer_text {
  /* display: block;
  box-sizing: padding-box;
  overflow: hidden; */
  font-size: 17px !important;
  width: 100%;
  font-size: 14px;
  border: 0;
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 6px;
  outline: none;
  margin-top: 5px;
  margin-bottom: 0px;
  background: none;
  overflow-y: hidden;
  resize: none;
}
.answer_text:focus {
  border: none;
  outline: none;
}
</style>
