import { logout, refreshToken } from "./auth"
import axios from "axios"

function query(
  method,
  url,
  data,
  tryToRefresh = true,
  contentType = "application/json"
) {
  var query_promise = null
  if (method == "get") {
    if (data) query_promise = axios.get(url, { params: data })
    else query_promise = axios.get(url)
  } else if (method == "post") {
    query_promise = axios.post(url, data, {
      headers: { "Content-Type": contentType },
    })
  } else if (method == "patch") {
    query_promise = axios.patch(url, data)
  } else if (method == "put") {
    query_promise = axios.put(url, data)
  } else if (method == "delete") {
    query_promise = axios.delete(url, { data: data })
  }
  return query_promise
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      if (
        (error.response.status == 401 ||
          (error.response.status == 403 &&
            error.response.data.code == "token_not_valid")) &&
        tryToRefresh
      ) {
        // Access token is seems to be invalid
        return refreshToken()
          .then(() => {
            return query(method, url, data, false)
          })
          .catch((error) => {
            logout()
            return Promise.reject(error)
          })
      }
      return Promise.reject(error)
    })
}

export function get(url, data) {
  return query("get", url, data)
}

export function post(url, data, contentType = "application/json") {
  return query("post", url, data, contentType)
}

export function put(url, data, contentType = "application/json") {
  return query("put", url, data, contentType)
}

export function patch(url, data, contentType = "application/json") {
  return query("patch", url, data, contentType)
}

export function del(url) {
  return query("delete", url)
}

export function defaultReadEndpoint(endpoint_url) {
  return {
    async list(params, pagination) {
      pagination = pagination ? pagination : false
      const data = await get(endpoint_url, params)
      if (!pagination) return data.results
      return data
    },
    async get(id) {
      const data = await get(`${endpoint_url}${id}/`)
      return data
    },
  }
}

export function defaultWriteEndpoint(endpoint_url) {
  return {
    async create(data) {
      const response = await post(endpoint_url, data)
      return response
    },
    async update(id, data) {
      const response = await patch(`${endpoint_url}${id}/`, data)
      return response
    },
    async delete(id) {
      const response = await del(`${endpoint_url}${id}/`)
      return response
    },
  }
}

export function defaultEndpoint(endpoint_url) {
  return {
    ...defaultReadEndpoint(endpoint_url),
    ...defaultWriteEndpoint(endpoint_url),
  }
}
