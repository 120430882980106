<template>
  <div class="half_screen_bg">
    <div class="background">
      <img class="answer_button" src="@/assets/img/login_bg.png" />
    </div>
    <div class="centered_form__container">
      <a href="https://test-online.ru/">
        <img class="st-logo" src="@/assets/img/st_logo.png" />
      </a>
      <div class="centered_form">
        <div class="centered_form__header h1">Вход в личный кабинет</div>
        <div class="centered_form__input_container">
          <input
            type="email"
            placeholder="Адрес электронной почты"
            v-model="username"
            autofocus
          />
          <input type="password" placeholder="Пароль" v-model="password" />
          <router-link
            class="link password_recovery_link"
            :to="{ name: 'password-recovery' }"
          >
            Забыли пароль?
          </router-link>
          <Button class="login_button button_top" @click="login">Войти</Button>
        </div>
      </div>
    </div>

    <Alert ref="alert" />
  </div>
</template>

<script>
import { login as apiLogin } from "@/api/auth"
import redirectProfile from "@/router/redirectProfile"
import Alert from "@/components/Functional/Alert.vue"
import Button from "@/components/UI/Button.vue"

export default {
  components: {
    Alert,
    Button,
  },
  data() {
    return {
      username: "",
      password: "",
    }
  },
  methods: {
    async login() {
      try {
        const profile = await apiLogin(this.username, this.password)
        redirectProfile(profile, "home")
      } catch (error) {
        if (error.response.status === 401) {
          this.$refs.alert.alert("Неверный логин или пароль.")
        } else if (
          error.response.status === 403 &&
          error.response.data.code == "DISABLED_USER"
        ) {
          this.$refs.alert.alert(
            "Ваш аккаунт отключен. Обратитесь к администратору."
          )
        } else {
          this.$refs.alert.alert(
            "Возникла ошибка при входе. Пожалуйста, попробуйте позднее или обратитесь к администратору."
          )
        }
      }
    },
  },
}
</script>

<style></style>

<style scoped>
.centered_form {
  /* padding-top: 200px; */
  width: 500px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}
.centered_form__input_container {
  width: 310px;
  display: flex;
  flex-flow: column wrap;
  gap: 10px;
  /* justify-content: center; */
}
.centered_form__input_container .button {
  margin-top: 15px;
  align-self: flex-start;
}
.half_screen_bg {
  display: flex;
  flex-flow: row;
  flex-basis: 50%;
  margin-left: -50px;
  margin-right: -50px;
}
.background {
  height: 100vh;
  width: 100%;
  background-color: aquamarine;
}
.background img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: right;
}
.centered_form__container {
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  padding-left: 122px;
  align-items: flex-start;
  height: 100vh;
  width: 80%;
  gap: 180px;
}
.st-logo {
  padding-top: 64px;
}

.password_recovery_link {
  padding-left: 24px;
  color: var(--greenz_link);
}
.login_button {
  width: 150px;
}
</style>
