<template>
  <div class="form school_form">
    <div class="centered_form__input_row">
      <label></label>
      <span class="form_header">Все поля обязательны для заполнения</span>
    </div>
    <div class="centered_form__input_row">
      <label>ИНН</label>
      <input
        :class="{ required: !form_data.inn && showRequired }"
        name="inn"
        v-model="form_data.inn"
      />
    </div>
    <div class="centered_form__input_row">
      <label>Область</label>
      <!-- <input name="region" v-model="form_data.region" /> -->
      <v-select
        :options="regionsOptions"
        :reduce="(region) => region.value"
        v-model="form_data.region"
        :disabled="regionsOptions.length === 0"
        :class="{ required: !form_data.region && showRequired }"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            Нет результатов по запросу <em>{{ search }}</em
            >.
          </template>
          <em v-else style="opacity: 0.5">Начните вводить текст для поиска</em>
        </template>
      </v-select>
    </div>
    <div class="centered_form__input_row">
      <label>Нас. пункт</label>

      <input
        :class="{ required: !form_data.city && showRequired }"
        name="city"
        v-model="form_data.city"
      />
    </div>
    <div class="centered_form__input_row">
      <label>Краткое наименование</label>
      <input
        :class="{ required: !form_data.name_short && showRequired }"
        name="name_short"
        v-model="form_data.name_short"
      />
    </div>
    <div class="centered_form__input_row">
      <label class="top_label">Полное наименование</label>
      <textarea
        :class="{ required: !form_data.name_full && showRequired }"
        class="textarea"
        name="name_full"
        v-model="form_data.name_full"
        rows="3"
      ></textarea>
    </div>
    <div class="centered_form__input_row">
      <label>Контактное лицо</label>
      <input
        :class="{ required: !form_data.contact_person && showRequired }"
        name="contact_person"
        v-model="form_data.contact_person"
      />
    </div>
    <div class="centered_form__input_row">
      <label>Директор</label>
      <input
        :class="{ required: !form_data.director_fio && showRequired }"
        name="director_fio"
        v-model="form_data.director_fio"
      />
    </div>

    <div class="centered_form__input_row">
      <label>Фото</label>
      <SelectAvatarInput
        @imageLoaded="setAvatar"
        @imageRemoved="removeAvatar"
        :base64_image="form_data.avatar_base64"
      ></SelectAvatarInput>
    </div>

    <div class="centered_form__input_row">
      <label>Электронная почта</label>
      <input
        :class="{ required: !form_data.email && showRequired }"
        name="email"
        v-model="form_data.email"
      />
    </div>
    <div class="centered_form__input_row">
      <label>Телефон</label>
      <PhoneMaskedInput
        :class="{ required: !form_data.phone && showRequired }"
        v-model="form_data.phone"
      ></PhoneMaskedInput>
    </div>
    <div class="centered_form__input_row">
      <label>Тариф</label>
      <PixelionSelect
        name="tarif"
        v-model="form_data.tarif"
        :options="tarifs"
        :reduce="(tarif) => tarif.value"
      />
    </div>
    <div class="centered_form__input_row" v-if="form_data.tarif == 'optimum'">
      <label>Предметы</label>
      <!-- TODO: чудо-селект с галочкой -->
      <CheckboxSelect
        class="vs_test_select"
        :options="subjectsOptions"
        placeholder="Доступны предметы"
        v-model="form_data.selected_subjects"
        label-name="label"
        value-name="id"
      />
    </div>
  </div>
</template>

<script>
// import { regions } from "@/api"
import { mapState } from "vuex"
import Button from "@/components/UI/Button"
import CheckboxSelect from "../UI/CheckboxSelect.vue"
import "vue-select/dist/vue-select.css"
import vSelect from "vue-select"
import PhoneMaskedInput from "../UI/PhoneMaskedInput.vue"
import SelectAvatarInput from "@/components/UI/SelectAvatarInput.vue"
import PixelionSelect from "../UI/PixelionSelect.vue"

export default {
  components: {
    Button,
    vSelect,
    PhoneMaskedInput,
    SelectAvatarInput,
    PixelionSelect,
    CheckboxSelect,
  },
  props: ["school"],
  data() {
    return {
      showRequired: false,
      tarifs: [
        { label: "Старт", value: "start" },
        { label: "Оптимум", value: "optimum" },
        { label: "Всё включено", value: "all_inclusive" },
      ],
      form_data: {
        inn: "",
        region: null,
        city: "",
        name_short: "",
        name_full: "",
        contact_person: "",
        director_fio: "",
        email: "",
        phone: "",
        tarif: "",
        avatar_base64: null,
        selected_subjects: [],
      },
      excludeFromValidation: ["avatar_base64", "is_disabled"],
    }
  },
  computed: {
    ...mapState(["regions", "subjects"]),
    subjectsOptions() {
      if (!this.subjects) {
        return []
      }
      return this.subjects.map((subject) => {
        return {
          id: subject.id,
          label: subject.name,
        }
      })
    },
    regionsOptions() {
      if (!this.regions) {
        return []
      }
      return this.regions.map((region) => {
        return {
          label: region[1],
          value: region[0],
        }
      })
    },
  },
  mounted() {
    if (this.school) {
      this.form_data = this.school
    }
  },
  methods: {
    checkFields() {
      this.showRequired = true
      for (var key in this.form_data) {
        if (this.excludeFromValidation?.includes(key)) {
          continue
        }
        if (!this.form_data[key]) {
          console.log(key)
          return false
        }
      }
      return true
    },
    setAvatar(base64) {
      this.form_data.avatar_base64 = base64
    },
    removeAvatar() {
      this.form_data.avatar_base64 = null
    },
  },
  watch: {
    form_data: {
      deep: true,
      handler(data) {
        this.$emit("form_data_changed", data)
      },
    },
  },
}
</script>

<style scoped>
.school_form {
  margin-left: -303px;
}
</style>
