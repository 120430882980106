<template>
  <Layout url_prefix="director">
    <template #page_header>
      <div class="h1">Редактировать тест</div>
    </template>

    <template #page_content>
      <div class="right_shifted_form">
        <TestForm
          v-if="test"
          :initial="test"
          @change="formDataChanged"
        ></TestForm>
        <div class="">
          <div class="buttons__container__without_grow">
            <Button class="button_top" @click="save">Сохранить тест</Button>
            <Button
              class="button_gray"
              @click="$router.push({ name: 'director-index' })"
              >Выйти</Button
            >
          </div>
        </div>
      </div>
    </template>
    <Alert ref="alert" />
    <div class="spacer200"></div>
  </Layout>
</template>

<script>
import { tests } from "@/api"
import Button from "@/components/UI/Button.vue"
import Layout from "@/components/Layouts/Layout.vue"
import TestForm from "@/components/Forms/TestForm/TestForm.vue"
import Alert from "@/components/Functional/Alert.vue"

export default {
  props: ["id"],
  components: {
    Layout,
    Button,
    TestForm,
    Alert,
  },
  data() {
    return {
      formData: {},
      test: null,
    }
  },
  mounted() {
    this.fetchTest()
  },
  methods: {
    async fetchTest() {
      const response = await tests.get(this.id)
      this.test = response
    },
    async save() {
      try {
        const response = await tests.update(this.test.id, this.formData)
        this.$store.dispatch("fetchTests")
        this.$refs.alert.alert("Тест успешно сохранён")
      } catch (error) {
        this.$refs.alert.alert(
          "Возникла ошибка при сохранении теста. Проверьте правильность заполнения всех полей."
        )
      }
    },
    formDataChanged(formData) {
      this.formData = formData
    },
  },
}
</script>

<style></style>
