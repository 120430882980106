<template>
  <div class="centered_form__input_row">
    <label
      class="centered_form__label"
      v-if="label"
      :for="for"
      :class="{ top_label }"
      >{{ label }}</label
    >
    <slot></slot>
    <!-- <div class="centered_form__input">
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    for: {
      type: String,
      default: null,
    },
    top_label: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.centered_form__input_row {
  /* background: aliceblue; */
}
.centered_form__label {
  /* background: gainsboro; */
}
/* .centered_form__input {
  background: rgb(151, 221, 31);
  width: 300px;
} */
/* .question_block  */
</style>
