<template>
  <Layout url_prefix="director">
    <template #page_header>
      <div class="h1">
        <template v-if="userProfile && userProfile.school">{{
          userProfile.school.name
        }}</template>
        <br />Личный кабинет директора школы
      </div>
      <Search
        placeholder="живой поиск по учителям, классам, ученикам, тестам"
        @searchInput="liveSearch"
        @searchResultClick="searchResultClick"
        @searchButtonPress="searchButtonPress"
        :search_results="liveSearchResults"
        :formatter="searchFormatter"
      ></Search>
      <div class="tabs_container">
        <div
          @click="changeActiveTab('teachers')"
          class="tab"
          :class="{ tab_active: activeTab == 'teachers' }"
        >
          <div class="h2" @click="">Учителя</div>
          <Button class="button_bottom" :to="{ name: 'director-teacher-add' }"
            >Создать учителя</Button
          >
        </div>
        <div
          @click="changeActiveTab('tests')"
          class="tab"
          :class="{ tab_active: activeTab == 'tests' }"
        >
          <div class="h2">Тесты</div>
          <Button class="button_bottom" :to="{ name: 'director-test-add' }"
            >Создать тест</Button
          >
        </div>
        <div
          @click="changeActiveTab('students')"
          class="tab"
          :class="{ tab_active: activeTab == 'students' }"
        >
          <div class="h2">Ученики</div>
          <Button class="button_bottom" :to="{ name: 'director-student-add' }">
            Создать ученика
          </Button>
        </div>
      </div>
    </template>
    <template #page_content>
      <div class="table__container" v-if="activeTab == 'teachers'">
        <table class="table">
          <thead>
            <tr>
              <th>ФИО учителя</th>
              <th>Предмет</th>
              <th>Тестирование</th>
              <th>за 3 месяца</th>
              <th>всего</th>
              <th>участвует с</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="teachers"
              v-for="teacher in teachersSlice"
              :key="teacher.id"
              :class="{ disabled: teacher.is_disabled }"
            >
              <td>
                <router-link
                  class="link"
                  :to="{
                    name: 'director-teacher-edit',
                    params: { id: teacher.id },
                  }"
                  >{{ teacher.fio }}</router-link
                >
              </td>
              <td>{{ getSubjectById(teacher.primary_subject) }}</td>
              <td></td>
              <td>{{ teacher.testing_3months }}</td>
              <td>{{ teacher.testing_total }}</td>
              <td>{{ formatDate(teacher.created_at) }}</td>
            </tr>
          </tbody>
        </table>
        <a
          class="expand_table_link"
          @click="toggleShowAllTeachers"
          href="#"
          v-if="teachers && teachers.length > 3"
        >
          <span v-if="tables.teachers.showAll == false">Смотреть всех</span>
          <span v-else>Свернуть</span>
        </a>
      </div>

      <div class="" v-if="activeTab == 'tests'">
        <TestFilter
          @gradeSelected="gradeSelected"
          @tabChanged="tabChanged"
          :base_tests_count="baseTests.length"
          :school_tests_count="tests ? tests.length : 0"
          ref="testFilter"
          :active_tab="activeTestsTab"
        ></TestFilter>
        <div class="subject_tests__container">
          <template v-if="activeTestsTab == 'school_tests'">
            <div
              v-for="(tests, subject) in subjectTests"
              :key="subject"
              class="subject_tests"
            >
              <div class="h3">{{ subject }}</div>
              <template v-for="test in tests" v-if="tests">
                <router-link
                  class="link"
                  :to="{ name: 'director-test-edit', params: { id: test.id } }"
                  >{{ test.name }}</router-link
                >
                <span v-if="test.author_name" class="test_author_label">{{
                  test.author_name
                }}</span>
              </template>
            </div>
          </template>

          <template v-if="activeTestsTab == 'base_tests'">
            <div
              v-for="(tests, subject) in subjectBaseTests"
              :key="subject"
              class="subject_tests"
            >
              <div class="h3">{{ subject }}</div>
              <template v-for="test in tests">
                <template v-if="!subjectIsActiveForSchool(test.subject.id)">
                  <a
                    class="link link-disabled"
                    @click="$refs.tarifModal.open()"
                  >
                    {{ test.name }}
                  </a>
                </template>
                <template v-else>
                  <router-link
                    class="link"
                    :to="{
                      name: 'director-base-test',
                      params: { id: test.id },
                    }"
                  >
                    {{ test.name }}
                  </router-link>
                </template>

                <!-- <span v-if="test.subject.name" class="test_author_label">{{ test.subject.name }}</span> -->
              </template>
            </div>
          </template>
        </div>

        <AdaptiveModal ref="tarifModal">
          <div class="modal_header">
            Функция доступна в расширенной версии программы
          </div>
          <a
            class="modal_link"
            href=" https://test-online.ru/tariff"
            target="_blank"
            >Сравнить тарифы</a
          >
        </AdaptiveModal>

        <!-- <div class="subject_tests__container">
          <div
            v-for="(tests, subject) in subjectTests"
            :key="subject"
            class="subject_tests"
          >
            <div class="h3">{{ subject }}</div>
            <template v-for="test in tests">
              <router-link
                class="link"
                :to="{ name: 'director-test-edit', params: { id: test.id } }"
                >{{ test.name }}</router-link
              >
              <span v-if="test.author_name" class="test_author_label">{{
                test.author_name
              }}</span>
            </template>
          </div>
          <div class="subject_tests">
            <div class="h3">Базовые тесты</div>
            <template v-for="baseTest in baseTests">
              <router-link
                class="link"
                :to="{ name: 'director-base-test', params: { id: baseTest.id } }"
                >{{ baseTest.name }}</router-link
              >
              <span class="test_author_label">{{ baseTest.subject.name }}</span>
            </template>
          </div>
        </div> -->
      </div>

      <div class="table__container" v-if="activeTab == 'students'">
        <table class="table students__table">
          <thead>
            <tr>
              <th>ФИО ученика</th>
              <th>
                <v-select
                  class="small_select"
                  :options="schoolGradeNumbers"
                  :clearable="clearable"
                  :searchable="false"
                  placeholder="Класс"
                  v-model="selectedGradeNumber"
                >
                </v-select>
              </th>
              <th>
                <v-select
                  class="small_select"
                  :options="schoolGradeLiteras"
                  :clearable="clearable"
                  :searchable="false"
                  placeholder="А-Я"
                  v-model="selectedGradeLetter"
                >
                </v-select>
              </th>
              <th>пройдено тестов</th>
              <th>% верных ответов</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="student in filteredStudents"
              :key="student.id"
              :class="{ disabled: student.is_disabled }"
            >
              <td>
                <router-link
                  class="link"
                  :to="{
                    name: 'director-student-edit',
                    params: { id: student.id },
                  }"
                  >{{ student.fio }}</router-link
                >
              </td>
              <td>{{ student.grade_number }}</td>
              <td>{{ student.grade_litera }}</td>
              <td>{{ student.finished_sessions_count }}</td>
              <td>
                <template v-if="student.finished_sessions_count != 0">
                  {{ student.performance }}%
                </template>
                <template v-else> — </template>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <span
          class="expand_table_link"
          @click="toggleShowAllStudents"
          v-if="students && students.length > 3"
        >
          <span v-if="tables.students.showAll == false">Смотреть всех</span>
          <span v-else>Свернуть</span>
        </span> -->
      </div>

      <div class="table__container" v-if="activeTab == 'search'">
        <table class="table" v-if="liveSearchResults.length">
          <thead>
            <tr>
              <th>Результат поиска</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(result, index) in liveSearchResults" :key="index">
              <td>
                <span
                  class="link"
                  v-html="searchFormatter('', result)"
                  @click="searchResultClick(result)"
                ></span>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <div class="spacer200"></div>
  </Layout>
</template>

<script>
import { mapState } from "vuex"
import { groupBy, map, uniq } from "lodash"
import formatDate from "@/utils/formatDate"
import ireplace from "@/utils/ireplace"

import { teachers, baseTests } from "@/api"

import Button from "@/components/UI/Button.vue"
import Search from "@/components/UI/Search.vue"
import TestFilter from "@/components/UI/TestFilter.vue"
import Layout from "@/components/Layouts/Layout.vue"
import AdaptiveModal from "@/components/UI/Modal_Adaptive.vue"

import "vue-select/dist/vue-select.css"
import vSelect from "vue-select"

export default {
  components: {
    Layout,
    Button,
    Search,
    TestFilter,
    AdaptiveModal,
    vSelect,
  },
  data() {
    return {
      formatDate,
      liveSearchResults: [],
      tables: {
        teachers: {
          showAll: false,
        },
        students: {
          showAll: false,
        },
      },
      activeTab: "teachers",
      teachers: [],
      baseTests: [],
      selectedGrade: 1,
      activeTestsTab: "school_tests",
      selectedGradeNumber: null,
      selectedGradeLetter: null,
    }
  },
  computed: {
    ...mapState(["students", "tests", "subjects", "grades", "userProfile"]),
    studentsSlice() {
      if (!this.students) return []
      return this.students.slice(
        0,
        this.tables.students.showAll ? undefined : 3
      )
    },
    teachersSlice() {
      return this.teachers.slice(
        0,
        this.tables.teachers.showAll ? undefined : 3
      )
    },
    // subjectTests() {
    //   return groupBy(this.tests, "subject.name")
    // },
    testsFilteredByGrade() {
      if (!this.tests) return []
      return this.tests.filter(
        (test) => test.grade_number == this.selectedGrade
      )
    },
    baseTestsFilteredByGrade() {
      if (!this.baseTests) return []
      return this.baseTests.filter(
        (baseTest) => baseTest.grade_number == this.selectedGrade
      )
    },
    subjectTests() {
      return groupBy(this.testsFilteredByGrade, "subject.name")
    },
    subjectBaseTests() {
      return groupBy(this.baseTestsFilteredByGrade, "subject.name")
    },
    schoolGradeNumbers() {
      const numbers = uniq(
        map(this.students, (student) => student.grade_number)
      )
      numbers.sort()
      return numbers
    },
    schoolGradeLiteras() {
      const litera = uniq(map(this.students, (student) => student.grade_litera))
      litera.sort()
      return litera
    },
    filteredStudents() {
      return this.students.filter((student) => {
        const gradeCondition =
          this.selectedGradeNumber == student.grade_number ||
          this.selectedGradeNumber == null
        const literaCondition =
          this.selectedGradeLetter == student.grade_litera ||
          this.selectedGradeLetter == null
        return gradeCondition && literaCondition
      })
    },
  },
  mounted() {
    this.getTeachersWithAggregates()
    this.getBaseTests()
    if (this.$route.params.activeTab) {
      this.activeTab = this.$route.params.activeTab
    }
    if (this.$route.params.testsType) {
      console.log(this.$refs.testFilter)
      // this.activeTestsTab = this.$route.params.testsType
      this.$nextTick(() => {
        this.$refs.testFilter.onTabChanged(this.$route.params.testsType)
      })
    }
  },
  methods: {
    getSubjectById(id) {
      if (id && this.subjects) {
        const subject = this.subjects.find((subj) => subj.id === id)
        return subject.name
      } else {
        return ""
      }
    },
    async getTeachersWithAggregates() {
      this.teachers = await teachers.list({ testing_aggregates: true })
    },
    async getBaseTests() {
      this.baseTests = await baseTests.list({
        limit: 10000,
        offset: 0,
      })
      this.baseTests.forEach((test) => {
        test.isBaseTest = true
      })
    },
    toggleShowAllTeachers() {
      this.tables.teachers.showAll = !this.tables.teachers.showAll
    },
    toggleShowAllStudents() {
      this.tables.students.showAll = !this.tables.students.showAll
    },
    changeActiveTab(tab_name) {
      this.activeTab = tab_name
    },
    searchButtonPress(query) {
      if (query) {
        this.activeTab = "search"
      }
    },
    liveSearch(query) {
      query = query.trim()
      if (query.length > 0) {
        const teachers = this.teachers.filter((teacher) => {
          return teacher.fio.toLowerCase().includes(query.toLowerCase())
        })
        const grades = this.grades.filter((grade) => {
          return grade.full_name
            .toLowerCase()
            .replace("«", "")
            .replace("»", "")
            .replace(" ", "")
            .includes(query.toLowerCase())
        })
        const students = this.students.filter((student) => {
          return student.fio.toLowerCase().includes(query.toLowerCase())
        })
        const tests = this.tests.filter((test) => {
          return test.name.toLowerCase().includes(query.toLowerCase())
        })
        teachers.forEach((teacher) => {
          teacher.type = "teacher"
        })
        grades.forEach((grade) => {
          grade.type = "grade"
        })
        students.forEach((student) => {
          student.type = "student"
        })
        tests.forEach((test) => {
          test.type = "test"
        })
        this.liveSearchResults = [...teachers, ...grades, ...students, ...tests]
      } else {
        this.liveSearchResults = []
      }
    },
    searchFormatter(query, search_result) {
      let result = ""
      switch (search_result.type) {
        case "teacher":
          result = `<b>Учитель: </b><span>${search_result.fio}</span>`
          break
        case "grade":
          result = `<b>Класс: </b><span>${search_result.name}</span>`
          break
        case "student":
          result = `<b>Ученик: </b><span>${search_result.fio}</span>`
          break
        case "test":
          result = `<b>Тест: </b><span>${search_result.name}</span>`
          break
      }
      if (query) {
        result = ireplace(
          result,
          query,
          "<span class='search_results__item__highlight'>$1</span>"
        )
      }
      return result
    },
    searchResultClick(search_result) {
      switch (search_result.type) {
        case "teacher":
          this.$router.push({
            name: "director-teacher-edit",
            params: { id: search_result.id },
          })
          break
        // case "grade":
        //   this.$router.push({
        //     name: "director-grade-edit",
        //     params: { id: search_result.id },
        //   })
        //   break
        case "student":
          this.$router.push({
            name: "director-student-edit",
            params: { id: search_result.id },
          })
          break
        case "test":
          this.$router.push({
            name: "director-test-edit",
            params: { id: search_result.id },
          })
          break
      }
    },
    filterTests(grade__number) {
      console.log(grade__number)
    },
    gradeSelected(grade) {
      this.selectedGrade = grade
    },
    tabChanged(tab_name) {
      this.activeTestsTab = tab_name
    },
    subjectIsActiveForSchool(subject_id) {
      if (
        !this.userProfile?.school ||
        this.userProfile?.school?.tarif == "start"
      ) {
        return false
      }
      if (this.userProfile.school.tarif == "all_inclusive") {
        return true
      }
      const schoolSubjects = this.userProfile.school.selected_subjects
      return schoolSubjects.indexOf(subject_id) !== -1
    },
  },
}
</script>

<style>
.link {
  cursor: pointer;
  text-decoration: underline;
}
.test_author_label {
  /* font-family: PT Sans; */
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray3);
}
</style>

<style scoped>
.students__table :nth-child(n + 2):nth-child(-n + 4) {
  text-align: center;
}
.students__table td:nth-child(n + 2):nth-child(-n + 4) {
  text-align: center;
}
.students__table thead {
  height: 80px;
}
.small_select {
  width: 120px;
  margin: auto;
}
/* .small_select >>> ul {
  width: 120px !important;
} */
.small_select >>> .vs__search {
  padding-bottom: 0px !important;
  padding-left: 18px !important;
  padding: 0px;
}
.small_select >>> .vs__selected-options {
  flex-wrap: nowrap !important;
  height: 50px;
}
.small_select >>> .vs__selected-options:has(.vs__selected) .vs__search {
  display: none;
}
.small_select >>> .vs__selected {
  padding-bottom: 2px !important;
}
</style>
