<template>
  <Layout url_prefix="director">
    <template #page_header>
      <div class="header_container" v-if="finishedLoading">
        <div class="h1">{{ teacher.fio }}</div>
        <div class="grade_comment" v-if="teacher.primary_subject">
          {{ primarySubjectName }}
        </div>
      </div>
    </template>

    <template #page_content>
      <div class="buttons_with_legend__container">
        <div class="button_with_legend">
          <Button @click="loginAsTeacher">Зайти как учитель</Button>
        </div>
        <div class="button_with_legend">
          <Button
            class="button_purple button_outline"
            @click="$refs.passwordModal.open()"
            >Сменить пароль</Button
          >
          <p>
            Учитель не будет уведомлен<br />
            о смене пароля.
          </p>
        </div>
        <div class="button_with_legend">
          <Button
            @click="toggleTeacherDisabled"
            class="button_gray button_with_icon"
          >
            <img src="@/assets/img/icon_disable.svg" />
            <template v-if="teacher.is_disabled"> Включить </template>
            <template v-else> Отключить учителя </template>
          </Button>
          <p>
            Пароль сохранится, но учитель не<br />
            сможет зайти в систему
          </p>
        </div>
      </div>

      <div class="h2 centered">Редактирование данных учителя</div>

      <div class="right_shifted_form">
        <TeacherForm
          @form_data_changed="formDataChanged"
          :teacher="teacher"
          class="mb30"
        ></TeacherForm>
        <div class="form_buttons">
          <div class="buttons__container__without_grow">
            <Button class="button_top" @click="save"
              >Сохранить изменения</Button
            >
            <Button @click="exit" class="button_gray"
              >Выйти без сохранения</Button
            >
          </div>
        </div>
      </div>
      <div class="spacer200"></div>
      <AdaptiveModal ref="passwordModal">
        <ChangePasswordForm
          @exit="$refs.passwordModal.close()"
          @save="changePassword"
        >
        </ChangePasswordForm>
      </AdaptiveModal>
      <Alert ref="alert" />
    </template>
  </Layout>
</template>

<script>
import { teachers } from "@/api"
import { mapState } from "vuex"
import AdaptiveModal from "@/components/UI/Modal_Adaptive.vue"
import { changeSubordinatePassword, loginAs } from "@/api/auth"

import Button from "@/components/UI/Button"
import Layout from "@/components/Layouts/Layout"
import TeacherForm from "@/components/Forms/TeacherForm"
import ChangePasswordForm from "@/components/Forms/ChangePasswordForm"
import Alert from "@/components/Functional/Alert.vue"

export default {
  components: {
    Layout,
    TeacherForm,
    Button,
    AdaptiveModal,
    ChangePasswordForm,
    Alert,
  },
  props: ["id"],
  data() {
    return {
      teacher: {
        id: null,
        fio: "",
        primary_subject: null,
        email: "",
        phone: "",
      },
      finishedLoading: false,
    }
  },
  computed: {
    ...mapState(["subjects"]),
    primarySubjectName(){
      if (this.subjects){
        let subject = this.subjects.find(subject => subject.id === this.teacher.primary_subject);
        return subject.name
      }
    }
  },
  mounted() {
    this.loadTeacher()
  },
  methods: {
    async loadTeacher() {
      this.teacher = await teachers.get(this.id)
      this.finishedLoading = true
    },
    formDataChanged(data) {
      this.teacher = data
    },
    async toggleTeacherDisabled() {
      this.teacher.is_disabled = !this.teacher.is_disabled
      await teachers.update(this.teacher.id, {
        is_disabled: this.teacher.is_disabled,
      })
      this.$store.dispatch("fetchTeachers")
    },
    async save() {
      try {
        const response = await teachers.update(this.teacher.id, this.teacher)
        this.$router.push({ name: "director-index" })
      } catch (error) {
        if (error.response.data.email) {
          this.$refs.alert.alert(
            "Не указан email или такой email уже существует."
          )
        } else {
          this.$refs.alert.alert(
            "Ошибка при сохранении данных. Проверьте правильность заполнения всех полей."
          )
        }
      }
      this.$store.dispatch("fetchTeachers")
    },
    async changePassword(password) {
      try {
        const response = await changeSubordinatePassword(
          this.teacher.user,
          password
        )
        this.$refs.passwordModal.close()
        this.$refs.alert.alert("Пароль изменен")
      } catch (error) {
        this.$refs.alert.alert("Ошибка при смене пароля")
      }
    },
    async loginAsTeacher() {
      try {
        await loginAs(this.teacher.email)
        this.$router.replace({ name: "teacher-index" })
      } catch (error) {
        console.log(error)
        this.$refs.alert.alert(
          "Возникла ошибка при входе в систему как учитель."
        )
      }
    },
    exit() {
      this.$router.push({ name: "director-index" })
    },
  },
}
</script>

<style scoped>
.buttons_with_legend__container {
  display: flex;
  justify-content: center;
  gap: 35px;
  max-width: 1000px;
}
.button_with_legend {
  display: flex;
  flex-flow: column;
  width: 310px;
}
.button_with_legend .button {
  white-space: nowrap;
}
.centered_form__input_row label {
  padding-right: 35px;
  padding-bottom: 2px;
}
.form_buttons {
  margin-left: 300px;
  width: 600px;
  padding-top: 10px;
}
.right_shifted_form >>> .vs__selected {
  max-width: 200px !important;
}
</style>
