<template>
  <Layout url_prefix="student">
    <template #page_header v-if="session">
      <div class="h1">
        Итог теста «{{ session.assignment.test.name }}», вар.
        {{ session.variant }}
      </div>
    </template>
    <template v-if="session" #page_content>
      <!-- <div class="h3">Оценка за тест: 4</div> -->

      <div class="result_container">
        <div class="result">
          <div>Результат:</div>
          <ProgressBar
            class="status-bar"
            width="411px"
            height="20px"
            :value="statusInPercent(session)"
            bg_color="#FF0952"
          ></ProgressBar>
          <div>{{ statusInPercent(session) }}%</div>
        </div>

        <div class="answers-count">
          Верных ответов:
          <span style="color: var(--greenz_link)">{{
            session.result.total_points
          }}</span
          >. Неверно:
          <span style="color: var(--alert)">
            {{
              session.result.question_count - session.result.total_points
            }} </span
          >.
        </div>

        <div>
          Время прохождения:
          <TimeFromSeconds
            :time="session.result.total_seconds"
            format="%mm мин %ss сек"
          />
        </div>

        <div class="wrong_answered_questions_container">
          <template v-if="wrongAnsweredQuestions.length > 0">
            <p class="wrong_answers_header">Вопросы для контроля учителю</p>
            <ul>
              <li v-for="question in wrongAnsweredQuestions" :key="question.id">
                № {{ question.number }}. {{ question.question }}
              </li>
            </ul>
          </template>
          <router-link class="index_link" :to="{ name: 'student-index' }"
            >На главную страницу</router-link
          >
        </div>
        <!-- <Button class="button_gray">посмотреть вопросы теста</Button> -->
        <div class="result_button_container"></div>
      </div>
    </template>
  </Layout>
</template>
<script>
import { sessions } from "@/api"
import { filter } from "lodash"
import Button from "@/components/UI/Button"
import Layout from "@/components/Layouts/Layout"
import TimeFromSeconds from "@/components/UI/TimeFromSeconds.vue"
import ProgressBar from "@/components/UI/ProgressBar.vue"

export default {
  props: ["id"],
  components: {
    Layout,
    Button,
    TimeFromSeconds,
    ProgressBar,
  },
  data() {
    return {
      session: null,
    }
  },
  computed: {
    wrongAnsweredQuestions() {
      if (!this.session) return []
      const questions = filter(
        this.session.assignment.schema_without_correct_answers,
        (question) => {
          return !question.variant || question.variant == this.session.variant
        }
      )
      const wrong = []
      for (let i = 0; i < questions.length; i++) {
        if (this.session.result.checked_answers[questions[i].id] == 0) {
          wrong.push({ ...questions[i], number: i + 1 })
        }
      }
      return wrong
    },
  },
  mounted() {
    this.getSession()
  },
  methods: {
    async getSession() {
      const session = await sessions.get(this.id)
      this.session = session
    },
    statusInPercent(session) {
      if (
        session.status == "N" ||
        !session.result ||
        session.result.question_count == 0
      ) {
        return 0
      }
      const result =
        (session.result.total_points / session.result.question_count) * 100
      return result.toFixed(2)
    },
  },
}
</script>
<style scoped>
.wrong_answers_header {
  margin-top: 50px;
  margin-bottom: 26px;
  font-size: 16px;
  line-height: 20px;
  color: var(--gray3);
}
.wrong_answered_questions_container {
  position: relative;
  width: 600px;
  /* margin-right: auto;
  margin-left: auto; */
  padding-right: 60px;
  /* padding-left: 60px; */
  color: var(--alert);
  min-height: 200px;
  border-bottom: 1px solid var(--gray5);
  padding-bottom: 50px;
}
.index_link {
  position: absolute;
  bottom: -40px;
  right: 0;
  left: 0;
  text-align: right;
  color: var(--greenz_link);
}
.result {
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  gap: 17px;
}
.result > div:nth-child(1) {
  padding-right: 32px;
}

.answers-count {
  font-size: 21px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 29px;
  padding-bottom: 14px;
}
.result_container {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}
</style>
