import {
  get,
  post,
  defaultEndpoint,
  defaultReadEndpoint,
  patch,
} from "./common"

import { cropAndConvertAllImagesInHtml } from "@/utils/base64"

/* Schools */
const schools = {
  ...defaultEndpoint("/school/schools/"),

  async search(query) {
    const data = await get("/school/schools/search/", { query })
    return data
  },
  async snapshot(id) {
    const data = await get(`/school/schools/${id}/snapshot/`)
    return data
  },
}

/* Teachers */
const teachers = defaultEndpoint("/school/teachers/")

/* Directors */
const directors = defaultEndpoint("/school/directors/")

/* Regions */
const regions = {
  async list() {
    const data = await get("/school/regions/")
    return data
  },
}

/* Grades */
const grades = {
  ...defaultEndpoint("/school/grades/"),
  async statistics(id) {
    const response = await get(`/school/grades/${id}/statistics/`)
    return response
  },
}

/* Subjects */
const subjects = defaultEndpoint("/school/subjects/")

/* Students */
const students = defaultEndpoint("/school/students/")

/* Tests */
const tests = {
  ...defaultEndpoint("/tester/tests/"),

  async list_not_base(params) {
    const data = await get("/tester/tests/?without_base=true", params)
    return data
  },

  async update(id, data) {
    console.log("update", id, data)
    const questions = data.schema
    if (questions && questions.length > 0) {
      // iterate over questions,
      for (let i = 0; i < questions.length; i++) {
        // remove style=\"cursor: nwse-resize;\" attribute
        questions[i].description = questions[i].description.replace(
          'style="cursor: nwse-resize;"',
          ""
        )
        // crop images
        questions[i].description = await cropAndConvertAllImagesInHtml(
          questions[i].description,
          500,
          500
        )
        // console.log("cropped", questions[i].description)
      }
    }
    console.log("new data: ", data)
    const response = await patch(`/tester/tests/${id}/`, data)
    return response
  },

  async assign_to_grade(id, grade_id, time_limit, variant) {
    const response = await post(`/tester/tests/${id}/assign_to_grade/`, {
      grade: grade_id,
      time_limit,
      variant,
    })
    return response
  },
  async assign_to_student(id, student_id, time_limit, variant) {
    const response = await post(`/tester/tests/${id}/assign_to_student/`, {
      student: student_id,
      time_limit,
      variant,
    })
    return response
  },
  async assign_to_students(id, students, time_limit) {
    const response = await post(`/tester/tests/${id}/assign_to_students/`, {
      students,
      time_limit,
    })
    return response
  },
  async makeBase(id) {
    const response = await post(`/tester/tests/${id}/make_base/`, {})
    return response
  },
}

const baseTests = {
  ...defaultEndpoint("/tester/basetests/"),
}

/* Assignments */
const assignments = {
  ...defaultReadEndpoint("/tester/assignments/"),

  async pause_all(id) {
    const response = await post(`/tester/assignments/${id}/pause_all/`)
    return response
  },
  async resume_all(id) {
    const response = await post(`/tester/assignments/${id}/resume_all/`)
    return response
  },
  async finish_all(id) {
    const response = await post(`/tester/assignments/${id}/finish_all/`)
    return response
  },
}

/* Sessions (results) */
const sessions = {
  ...defaultReadEndpoint("/tester/sessions/"),

  async my(params) {
    const data = await get("/tester/sessions/my/", params)
    return data
  },
  async start(id) {
    const response = await post(`/tester/sessions/${id}/start/`)
    return response
  },
  async pause(id) {
    const response = await post(`/tester/sessions/${id}/pause/`)
    return response
  },
  async resume(id) {
    const response = await post(`/tester/sessions/${id}/resume/`)
    return response
  },
  async finish(id) {
    const response = await post(`/tester/sessions/${id}/finish/`)
    return response
  },
  async mistakes(id) {
    const response = await post(`/tester/sessions/${id}/mistakes/`)
    return response
  },
  async answer(secret, question_id, answer_data) {
    const response = await post(
      `/tester/answer_fast/${secret}/${question_id}/`,
      answer_data
    )
    return response
  },
}

/* Content */
const content = {
  async get(rendered) {
    /*
      { "leftColumn": "some html...", "rightColumn": "other html..." }
    */
    const params = {}
    if (rendered) {
      params["rendered"] = rendered
    }
    const response = await get(`/content/`, params)
    return response
  },
  async set(title, leftColumn, rightColumn) {
    const data = await post("/content/", {
      title,
      leftColumn,
      rightColumn,
    })
    return data
  },
}

export {
  schools,
  directors,
  teachers,
  regions,
  grades,
  subjects,
  students,
  tests,
  baseTests,
  assignments,
  sessions,
  content,
}
