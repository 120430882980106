<template>
  <Layout url_prefix="teacher">
    <template #page_header>
      <div class="header_container">
        <div class="h1">Создать тест</div>
        <div
          class="grade_comment"
          @click="
            $router.push({
              name: 'teacher-index-tests-type',
              params: { testsType: 'base_tests', activeTab: 'tests' },
            })
          "
        >
          Создать тест школы можно на базе любого теста из «Каталога тестов»
        </div>
      </div>
    </template>
    <template #page_content>
      <div class="right_shifted_form">
        <TestForm @change="formDataChanged"></TestForm>
      </div>
      <div class="test_buttons_container">
        <Button class="button_top" @click="save">Создать тест</Button>
        <Button
          @click="$router.push({ name: 'teacher-index' })"
          class="button_gray"
          >Выйти</Button
        >
      </div>
    </template>
    <Alert ref="alert" />
    <div class="spacer200"></div>
  </Layout>
</template>

<script>
import { tests } from "@/api"

import Alert from "@/components/Functional/Alert.vue"
import Button from "@/components/UI/Button"
import Layout from "@/components/Layouts/Layout"
import TestForm from "@/components/Forms/TestForm/TestForm.vue"

export default {
  components: {
    Alert,
    Layout,
    Button,
    TestForm,
  },
  data() {
    return {
      formData: {},
    }
  },
  methods: {
    async save() {
      try {
        if (this.formData.grade_number.length === 0) {
          this.$refs.alert.alert("Выберите класс для теста")
          return
        }
        const response = await tests.create(this.formData)
        this.$store.dispatch("fetchTests")
        this.$refs.alert.alert("Тест сохранён", () => {
          this.$router.push({
            name: "teacher-test-edit",
            params: { id: response.id },
          })
        })
        // this.$router.push({
        //   name: "teacher-test-edit",
        //   params: { id: response.id },
        // })
      } catch (error) {
        this.$refs.alert.alert(
          "Возникла ошибка при создании теста. Проверьте правильность заполнения всех полей."
        )
      }
    },
    formDataChanged(formData) {
      this.formData = formData
    },
  },
}
</script>

<style scoped>
.header_container {
  position: relative;
  width: 100%;
}
.test_buttons_container {
  flex-flow: row wrap;
  display: flex;
  justify-content: center;
  gap: 14px;
}
.grade_comment {
  position: absolute;
  width: fit-content;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* bottom: -8px; */
  transform: translateY(-50%);
  background: var(--purple);
  color: white;
  padding-top: 1px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 25px;
  font-style: italic;
  cursor: pointer;
}
</style>
