<template>
  <Layout url_prefix="director">
    <template #page_header>
      <div class="h1">{{ student.fio }}</div>
    </template>

    <template #page_content>
      <div class="buttons_with_legend__container">
        <div class="button_with_legend">
          <div class="profile_edit_container">
            <div class="profile_edit_button" v-scroll-to="'#editProfile'">
              Редактировать профиль ученика
            </div>
          </div>
        </div>
        <div class="button_with_legend">
          <Button class="button_red" @click="$refs.passwordModal.open()"
            >Сменить пароль</Button
          >
          <p>
            Ученик не будет уведомлен <br />
            о смене пароля.<br />
          </p>
        </div>
        <div class="button_with_legend">
          <!-- <Button @click="toggle_student_disabled" class="button_gray"
          >Отключить ученика</Button
        > -->
          <Button
            @click="toggleStudentDisabled"
            class="button_gray button_with_icon"
          >
            <img src="@/assets/img/icon_disable.svg" />
            <template v-if="student.is_disabled"> Включить </template>
            <template v-else> Отключить ученика </template>
          </Button>
          <p>
            Пароль сохранится, но ученик не<br />
            сможет зайти в систему
          </p>
        </div>
      </div>

      <!-- <CreateAssignmentSubjectsTests
        :tests="tests"
        @createAssignment="createAssignment"
      ></CreateAssignmentSubjectsTests> -->

      <div class="h2 centered_header">Результаты тестирования</div>
      <div class="table__container">
        <table class="table">
          <thead>
            <tr>
              <th>Название</th>
              <th>Прошлое открытие</th>
              <th>Статус</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="session in testSessions" :key="session.id">
              <td>
                <span>
                  {{ session.assignment.test.name }}, вар.
                  {{ session.variant }}
                </span>
              </td>
              <td>{{ formatDate(session.assignment.started_at) }}</td>
              <td>
                <TestResultStatus
                  :session="session"
                  @show-mistakes="showMistakes"
                ></TestResultStatus>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="right_shifted_form">
        <div class="h2 centered_header" id="editProfile">
          Редактирование данных ученика
        </div>
        <StudentForm
          @form_data_changed="formDataChanged"
          :student="student"
          class="school_form mb30"
        ></StudentForm>
        <div class="form_buttons">
          <div class="buttons__container__without_grow">
            <Button class="button_top" @click="save"
              >Сохранить изменения</Button
            >
            <Button
              @click="$router.push({ name: 'director-index' })"
              class="button_gray"
              >Выйти без сохранения</Button
            >
          </div>
        </div>
      </div>
      <div class="spacer200"></div>

      <AdaptiveModal ref="passwordModal">
        <ChangePasswordForm
          @exit="$refs.passwordModal.close()"
          @save="changePassword"
        ></ChangePasswordForm>
      </AdaptiveModal>

      <MistakesModal ref="mistakesModal" />

      <Alert ref="alert" />
    </template>
  </Layout>
</template>

<script>
import { students, sessions, tests } from "@/api"
import { changeSubordinatePassword } from "@/api/auth"
import { mapState } from "vuex"
import formatDate from "@/utils/formatDate"

import AdaptiveModal from "@/components/UI/Modal_Adaptive.vue"
import MistakesModal from "@/components/Functional/MistakesModal.vue"
import Alert from "@/components/Functional/Alert.vue"
import Button from "@/components/UI/Button.vue"
import Layout from "@/components/Layouts/Layout.vue"
import StudentForm from "@/components/Forms/StudentForm.vue"
import ChangePasswordForm from "@/components/Forms/ChangePasswordForm.vue"
import TestResultStatus from "@/components/Functional/TestResultStatus.vue"
import TestResultAction from "@/components/Functional/TestResultAction.vue"
import CreateAssignmentSubjectsTests from "@/components/Forms/CreateAssignmentSubjectsTests.vue"

export default {
  components: {
    Layout,
    StudentForm,
    Button,
    AdaptiveModal,
    Alert,
    ChangePasswordForm,
    TestResultStatus,
    TestResultAction,
    CreateAssignmentSubjectsTests,
    MistakesModal,
  },
  props: ["id"],
  data() {
    return {
      formatDate,
      student: {
        fio: "",
        grade: null,
        email: "",
        phone: "",
        comment: "",
      },
      finishedLoading: false,
      testSessions: [],
    }
  },
  computed: {
    ...mapState(["tests"]),
  },
  mounted() {
    this.loadStudent()
    this.getTestSessions()
  },
  methods: {
    async loadStudent() {
      this.student = await students.get(this.id)
      this.finishedLoading = true
    },
    async getTestSessions() {
      const testSessions = await sessions.list({ student: this.id })
      this.testSessions = testSessions
    },
    async createAssignment(test_id, _students, time_limit) {
      await tests.assign_to_student(test_id, this.id, time_limit) // + variant
      await this.getTestSessions()
    },
    formDataChanged(data) {
      this.student = data
    },
    async toggleStudentDisabled() {
      this.student.is_disabled = !this.student.is_disabled
      await students.update(this.student.id, {
        is_disabled: this.student.is_disabled,
      })
      this.$store.dispatch("fetchStudents")
    },
    async save() {
      try {
        const response = await students.update(this.student.id, this.student)
        this.$router.push({ name: "director-index" })
      } catch (error) {
        if (error.response.data.email) {
          this.$refs.alert.alert(
            "Не указан email или такой email уже существует."
          )
        } else {
          this.$refs.alert.alert(
            "Ошибка при сохранении данных. Проверьте правильность заполнения всех полей."
          )
        }
      }
      this.$store.dispatch("fetchStudents")
    },
    async changePassword(password) {
      try {
        const response = await changeSubordinatePassword(
          this.student.user,
          password
        )
        this.$refs.passwordModal.close()
        this.$refs.alert.alert("Пароль изменен")
      } catch (error) {
        this.$refs.alert.alert("Ошибка при смене пароля")
      }
    },
    async showMistakes(session) {
      this.$refs.mistakesModal.showMistakesForSession(session)
    },
    exit() {
      this.$router.push({ name: "director-index" })
    },
  },
}
</script>

<style scoped>
.table__container {
  padding-bottom: 10px !important;
}
</style>
<style>
.button_with_legend {
  display: flex;
  flex-flow: column;
  /* max-width: 222px; */
}
.button_with_legend .button {
  white-space: nowrap;
}
.test_result_status_done {
  color: var(--teacher-primary-color);
}
.test_result_status_active {
  color: var(--director-primary-color);
}
.test_result_status_failed {
  color: var(--red);
}
.test_result_status_row {
  display: flex;
  gap: 5px;
}
.test_result_status_row img {
  height: 14px;
  align-self: center;
}
.icon_container {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.test_result_status_active img {
  width: 7px;
  margin-left: -3px;
}
.test_result_status_done img {
  width: 12px;
}
.test_result_status_failed img {
  width: 15px;
}
.profile_edit_container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.profile_edit_button {
  text-decoration: none;
  color: var(--greenz_link);
  font-size: 16px;
  border-bottom: 2px dashed var(--greenz_link);
  text-transform: uppercase;
  padding-top: 13px;
  cursor: pointer;
}
</style>
