<template>
  <div class="centered_form">
    <div class="centered_form__input_row">
      <label>ФИО ученика*</label>
      <input
        :class="{ required: !form_data.fio && showRequired }"
        v-model="form_data.fio"
        @keyup.enter="enterPressed"
      />
    </div>

    <div class="centered_form__input_row" v-if="!hide_grade">
      <label>Текущий класс</label>
      <v-select
        :class="{ required: !form_data.grade && showRequired }"
        :options="grades"
        label="full_name"
        :reduce="(grade) => grade.id"
        v-model="form_data.grade"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            Нет результатов по запросу <em>{{ search }}</em
            >.
          </template>
          <em v-else style="opacity: 0.5">Начните вводить текст для поиска</em>
        </template>
      </v-select>
    </div>

    <div class="centered_form__input_row">
      <label>Электронная почта*</label>
      <input
        :class="{ required: !form_data.email && showRequired }"
        v-model="form_data.email"
        @keyup.enter="enterPressed"
      />
    </div>

    <div class="centered_form__input_row">
      <label>Фото</label>
      <SelectAvatarInput
        @imageLoaded="setAvatar"
        @imageRemoved="removeAvatar"
        :base64_image="form_data.avatar_base64"
      ></SelectAvatarInput>
    </div>

    <!-- <div class="centered_form__input_row">
      <label>Телефон</label>
      <input name="phone" v-model="form_data.phone" />
    </div> -->
    <div class="centered_form__input_row">
      <label>Комментарий</label>
      <input
        v-model="form_data.comment"
        autocomplete="false"
        @keyup.enter="enterPressed"
      />
    </div>
  </div>
</template>

<script>
import { grades } from "@/api"

import Button from "@/components/UI/Button"
import SelectAvatarInput from "@/components/UI/SelectAvatarInput"
import "vue-select/dist/vue-select.css"
import vSelect from "vue-select"

export default {
  props: ["student", "hide_grade"],
  components: {
    Button,
    vSelect,
    SelectAvatarInput,
  },
  data() {
    return {
      showRequired: false,
      grades: [],
      form_data: {
        fio: "",
        grade: null,
        email: "",
        avatar_base64: "",
        // phone: "",
        comment: "",
      },
    }
  },
  mounted() {
    this.getGrades()
  },
  methods: {
    setAvatar(base64Image) {
      this.form_data["avatar_base64"] = base64Image
      console.log(base64Image)
    },
    removeAvatar() {
      this.form_data["avatar_base64"] = null
    },
    async getGrades() {
      this.grades = await grades.list()
    },
    enterPressed() {
      this.$emit("enter_pressed")
    },
    checkFields() {
      this.showRequired = true
      for (var key in this.form_data) {
        if (!this.form_data[key]) {
          return false
        }
      }
      return true
    },
  },
  watch: {
    student(new_student) {
      if (new_student) {
        this.form_data = new_student
      }
    },
    form_data: {
      deep: true,
      handler(data) {
        this.$emit("form_data_changed", data)
      },
    },
  },
}
</script>

<style scoped>
.centered_form__input_row {
  padding-bottom: 10px;
}
</style>
