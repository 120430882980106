<template>
  <Layout url_prefix="director">
    <template #page_header>
      <div class="h1">Создать учителя</div>
    </template>

    <div class="right_shifted_form">
      <TeacherForm @form_data_changed="formDataChanged"></TeacherForm>

      <div class="form_buttons">
        <p>
          После нажатия на “Создать учителя” в системе будет сформирована
          карточка преподавателя, а на указанный адрес почты отправятся
          реквизиты для входа.
        </p>
        <div class="buttons__container__without_grow">
          <Button class="button_top" @click="createTeacher"
            >Создать учителя</Button
          >
          <Button
            class="button_gray"
            @click="$router.push({ name: 'director-index' })"
            >Выйти без сохранения</Button
          >
        </div>
      </div>
    </div>
    <Alert ref="alert" />
    <div class="spacer200"></div>
  </Layout>
</template>

<script>
import { teachers } from "@/api"
import Button from "@/components/UI/Button"
import Layout from "@/components/Layouts/Layout"
import TeacherForm from "@/components/Forms/TeacherForm.vue"
import Alert from "@/components/Functional/Alert.vue"

export default {
  components: {
    Layout,
    Button,
    TeacherForm,
    Alert,
  },
  data() {
    return {
      teacher: null,
    }
  },
  methods: {
    formDataChanged(data) {
      this.teacher = data
    },
    async createTeacher() {
      if (this.teacher) {
        try {
          const teacher = await teachers.create(this.teacher)
          this.$store.dispatch("fetchTeachers")
          this.$refs.alert.alert("Учитель создан", () => {
            this.$router.push({
              name: "director-teacher-edit",
              params: { id: teacher.id },
            })
          })
        } catch (error) {
          console.log(error)
          if (
            error.response.data.email &&
            error.response.data.email == "Email already exists"
          ) {
            this.$refs.alert.alert(
              "Не удалось создать учителя. Пользователь с таким email уже существует в базе данных."
            )
          } else {
            this.$refs.alert.alert(
              "Не удалось создать учителя. Проверьте правильность заполнения всех полей."
            )
          }
        }
      }
    },
  },
}
</script>

<style></style>
