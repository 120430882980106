<template>
  <Row :top_label="true" class="unusual_row" label="Текст с пропусками">
    <textarea
      v-show="!skippedTextareaBlured"
      class="skips_textarea"
      v-model="skipstext"
      ref="skipstext"
      rows="1"
      data-min-rows="1"
      @keyup="emitUpdate"
      @blur="blurTextarea"
    ></textarea>
    <div
      class="skips_textarea skiptext_display"
      v-show="skippedTextareaBlured"
      @click="hideSkipsDisplayed"
      v-html="skippsTextToDisplay"
    ></div>
  </Row>
  <Row label="&nbsp;">
    <div class="button_container">
      <Button
        class="button_purple"
        @click="addSkipToQuestion"
        @mouseover="disallowTextareaBlur"
        @mouseout="allowTextareaBlur"
      >
        Добавить пропуск</Button
      >
    </div>
  </Row>
  <template v-if="skips.length > 0">
    <div class="answers_form">
      <div class="answers_form__header">Ответы:</div>
      <div class="answers_hint">
        <div class="answers_hint_text"></div>
      </div>
      <div class="answer_hint_2">
        (варианты будут предлагаться в случайном порядке внутри выпадающего в
        местах «пропусков»)
      </div>
      <div class="answer_row" v-for="(skip, index) in skips">
        Пропуск №{{ index + 1 }}
        <input type="text" v-model="skip.value" @keyup="emitUpdate()" />
      </div>
    </div>
  </template>
</template>

<script>
import AnswerField from "@/components/Forms/TestForm/AnswerField.vue"
import Button from "@/components/UI/Button.vue"
import CenteredFormRow from "@/components/UI/CenteredFormRow.vue"

export default {
  props: ["question"],
  emits: ["update"],
  components: {
    AnswerField,
    Button,
    Row: CenteredFormRow,
  },
  data() {
    return {
      skipstext: "",
      skips: [],
      skippedTextareaBlured: true,
      textareaBlurAllowed: true,
      skippsTextToDisplay: "",
    }
  },
  mounted() {
    if (
      this.question.correct &&
      this.question.answers &&
      Array.isArray(this.question.answers) &&
      Array.isArray(this.question.correct)
    ) {
      this.question.correct.forEach((correct, index) => {
        const answer = this.question.answers.find((a) => a.id == correct)
        this.skips.push({
          id: index.toString(),
          value: answer.text,
        })
      })
    }
    this.skipstext = this.question.skipstext || ""
    this.updateSkips(this.question.skipstext || "")
    this.$nextTick(() => {
      this.expandField(this.$refs.skipstext)
    })
  },
  methods: {
    render() {
      const answers = this.skips.map((skip) => {
        return {
          id: skip.id,
          text: skip.value,
        }
      })
      const ids = answers.map((a) => parseInt(a.id))

      // randomize ids order
      ids.sort(() => Math.random() - 0.5)

      // assign new ids to answers
      answers.forEach((a, index) => {
        a.id = ids[index].toString()
      })

      // generate new correct answers list
      const correct = answers.map((a) => a.id)

      // randomize answers order
      answers.sort(() => Math.random() - 0.5)

      return {
        answers: answers,
        correct: correct,
        skipstext: this.skipstext,
      }
    },
    emitUpdate() {
      this.$emit("update", this.render())
    },
    createSkip() {
      return {
        id: (this.skips.length + 1).toString(),
        value: "",
      }
    },
    updateSkips(text) {
      const parts = text.split(/\[\s*\]/g)
      const targetSkipsCount = parts.length - 1
      const currentSkipsCount = this.skips.length
      if (targetSkipsCount > currentSkipsCount) {
        for (let i = currentSkipsCount; i < targetSkipsCount; i++) {
          this.skips.push(this.createSkip())
        }
        this.emitUpdate()
      } else if (targetSkipsCount < currentSkipsCount) {
        this.skips.splice(
          targetSkipsCount,
          currentSkipsCount - targetSkipsCount
        )
        this.emitUpdate()
      }
      this.expandField(this.$refs.skipstext)
      this.updateSkipsDisplayed()
    },
    updateSkipsDisplayed() {
      // this.skippedTextareaBlured = false
      const re = /\[\]/gi
      const re_n = /\n/gi
      const matches_count = this.skipstext?.match(re)?.length

      const single_re = /\[\]/
      this.skippsTextToDisplay = this.skipstext
      for (var i = 0; i < matches_count; i++) {
        this.skippsTextToDisplay = this.skippsTextToDisplay.replace(
          single_re,
          `<span class='skip_badge'>Пропуск ${i + 1}</span>`
        )
      }
      this.skippsTextToDisplay = this.skippsTextToDisplay.replace(re_n, "<br>")
    },
    blurTextarea() {
      if (this.textareaBlurAllowed) {
        this.skippedTextareaBlured = true
      }
    },
    disallowTextareaBlur() {
      this.textareaBlurAllowed = false
    },
    allowTextareaBlur() {
      this.textareaBlurAllowed = true
    },
    hideSkipsDisplayed() {
      this.emitUpdate()
      this.skippedTextareaBlured = false
      this.$nextTick(() => {
        this.$refs.skipstext.focus()
        this.expandField(this.$refs.skipstext)
      })
    },
    addSkipToQuestion() {
      const input = this.$refs.skipstext
      // inserts "[]" at cursor position in input
      input.focus()
      const oldSelectionStart = input.selectionStart
      const textBeforeSelection = input.value.substring(0, input.selectionStart)
      const textFromSelection = input.value.substring(input.selectionEnd)

      let textToAdd = "[]"

      if (
        textBeforeSelection &&
        textBeforeSelection[textBeforeSelection.length - 1] !== " "
      ) {
        textToAdd = " " + textToAdd
      }
      if (textFromSelection && textFromSelection[0] !== " ") {
        textToAdd = textToAdd + " "
      }

      this.skipstext = textBeforeSelection + textToAdd + textFromSelection
      this.$nextTick(() => {
        const newSelectionStart = oldSelectionStart + textToAdd.length
        input.selectionStart = input.selectionEnd = newSelectionStart
        input.focus()
      })
    },
    getScrollHeight(elm) {
      var savedValue = elm.value
      elm.value = ""
      elm._baseScrollHeight = elm.scrollHeight
      elm.value = savedValue
    },
    expandField(elm) {
      var minRows = elm.getAttribute("data-min-rows") | 0
      !elm._baseScrollHeight && this.getScrollHeight(elm)
      elm.rows = minRows
      let rows = Math.ceil((elm.scrollHeight - elm._baseScrollHeight) / 18)
      elm.rows = minRows + rows
    },
  },
  watch: {
    skipstext: function (text) {
      this.updateSkips(text)
    },
  },
}
</script>

<style scoped>
.skips_textarea {
  width: 530px;
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 15px;
  min-height: 6px;
  outline: none;
  margin-top: 5px;
  overflow-y: hidden;
  resize: none;
  margin-bottom: 10px;

  background: #f9f9f9;
  box-shadow: inset 0px 2px 4px rgb(0 0 0 / 10%);
  border-radius: 25px;
  border: none;
  padding-left: 24px;
  outline: none;
}
/* .skips_display {
  width: 525px;
  background: #f9f9f9;
  box-shadow: inset 0px 2px 4px rgb(0 0 0 / 10%);
  border-radius: 25px;
  border: none;
  padding-left: 24px;
  outline: none;
  min-height: 30px;
} */
.answers_form {
  padding-top: 40px;
}
.button_container {
  width: 370px;
}
.unusual_row >>> .centered_form__label {
  padding-top: 5px !important;
}

.answer_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-left: 242px;
  padding-bottom: 10px;
}
.answer_row input {
  width: 400px;
}
.answer_hint_2 {
  position: absolute;
  max-width: 225px;
  text-align: right;
}
.skiptext_display {
  padding-top: 15px;
  box-shadow: none;
  min-height: 18px;
  font-family: PPNeueMachina;
  line-height: 27px;
}
.skiptext_display >>> .skip_badge {
  padding: 3px 5px;
  margin: 0 3px;
  background-color: var(--purple);
  color: white;
}
</style>
