<template>
  <Layout url_prefix="teacher">
    <template #page_header>
      <div class="h1">
        Личный кабинет учителя<br />
        <template v-if="userProfile">
          {{ userProfile.fio }}
          <template v-if="userProfile.primary_subject">
            ({{ userProfile.primary_subject.name }})
          </template>
        </template>
      </div>
      <Search
        placeholder="живой поиск по классам, ученикам, тестам"
        @searchInput="liveSearch"
        @searchResultClick="searchResultClick"
        @searchButtonPress="searchButtonPress"
        :search_results="liveSearchResults"
        :formatter="searchFormatter"
      ></Search>

      <div class="tabs_container">
        <div
          @click="changeActiveTab('grades')"
          class="tab"
          :class="{ tab_active: activeTab == 'grades' }"
        >
          <div class="h2" @click="">Классы</div>
          <Button class="button_bottom" :to="{ name: 'teacher-grade-add' }"
            >Создать класс</Button
          >
        </div>
        <div
          @click="changeActiveTab('tests')"
          class="tab"
          :class="{ tab_active: activeTab == 'tests' }"
        >
          <div class="h2">Тесты</div>
          <Button class="button_bottom" :to="{ name: 'teacher-test-add' }"
            >Создать тест</Button
          >
        </div>
        <div
          @click="changeActiveTab('students')"
          class="tab"
          :class="{ tab_active: activeTab == 'students' }"
        >
          <div class="h2">Ученики</div>
          <Button class="button_bottom" :to="{ name: 'teacher-student-add' }">
            Создать ученика
          </Button>
        </div>
      </div>
    </template>

    <!-- <div class="heared_with_buttons">
      <div class="h2">Тесты</div>
      <Button :to="{ name: 'teacher-test-add' }">Создать тест</Button>
    </div> -->
    <template #page_content>
      <div v-if="activeTab == 'tests'">
        <TestFilter
          @gradeSelected="gradeSelected"
          @tabChanged="tabChanged"
          :base_tests_count="baseTests.length"
          :school_tests_count="tests ? tests.length : 0"
          :active_tab="activeTestsTab"
          ref="testFilter"
        ></TestFilter>
        <div class="subject_tests__container">
          <template v-if="activeTestsTab == 'school_tests'">
            <div
              v-for="(tests, subject) in subjectTests"
              :key="subject"
              class="subject_tests"
            >
              <div class="h3">{{ subject }}</div>
              <template v-for="test in tests" v-if="tests">
                <router-link
                  class="link"
                  :to="{ name: 'teacher-test-edit', params: { id: test.id } }"
                  >{{ test.name }}</router-link
                >
                <span v-if="test.author_name" class="test_author_label">{{
                  test.author_name
                }}</span>
              </template>
            </div>
          </template>

          <template v-if="activeTestsTab == 'base_tests'">
            <div
              v-for="(tests, subject) in subjectBaseTests"
              :key="subject"
              class="subject_tests"
            >
              <div class="h3">{{ subject }}</div>
              <template v-for="test in tests">
                <template v-if="!subjectIsActiveForSchool(test.subject.id)">
                  <a
                    class="link link-disabled"
                    @click="$refs.tarifModal.open()"
                  >
                    {{ test.name }}
                  </a>
                </template>
                <template v-else>
                  <router-link
                    class="link"
                    :to="{
                      name: 'teacher-base-test',
                      params: { id: test.id },
                    }"
                  >
                    {{ test.name }}
                  </router-link>
                </template>

                <!-- <span v-if="test.subject.name" class="test_author_label">{{ test.subject.name }}</span> -->
              </template>
            </div>
          </template>
        </div>
      </div>

      <AdaptiveModal ref="tarifModal">
        <div class="modal_header">
          Функция доступна в расширенной версии программы
        </div>
        <a
          class="modal_link"
          href=" https://test-online.ru/tariff"
          target="_blank"
          >Сравнить тарифы</a
        >
      </AdaptiveModal>

      <!-- <div class="heared_with_buttons">
      <div class="h2">Класс</div>
      <Button :to="{ name: 'teacher-grade-add' }">Создать класс</Button>
    </div> -->

      <div class="table__container" v-if="activeTab == 'grades'">
        <table class="table">
          <thead>
            <tr>
              <th>Класс</th>
              <th>Тестирование:</th>
              <th>за 3 месяца</th>
              <th>всего</th>
              <th>прошлый тест</th>
              <th>участвует с</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="grade in gradesSlice"
              :key="grade.id"
              :class="{ disabled: grade.is_deleted }"
            >
              <td>
                <router-link
                  class="link"
                  :to="{
                    name: 'teacher-grade-view',
                    params: { id: grade.id },
                  }"
                >
                  {{ grade.full_name }}
                </router-link>
              </td>
              <td></td>
              <td>{{ grade.testing_3months }}</td>
              <td>{{ grade.testing_total }}</td>
              <td>{{ formatDate(grade.testing_last_date) }}</td>
              <td>{{ formatDate(grade.created_at) }}</td>
            </tr>
          </tbody>
        </table>
        <span
          class="expand_table_link"
          @click="toggleShowAllGrades"
          v-if="grades && grades.length > 3"
        >
          <span v-if="tables.grades.showAll == false">Смотреть всех</span>
          <span v-else>Свернуть</span>
        </span>
      </div>

      <div class="table__container" v-if="activeTab == 'students'">
        <table class="table students__table">
          <thead>
            <tr>
              <th>ФИО ученика</th>
              <th>
                <v-select
                  class="small_select"
                  :options="schoolGradeNumbers"
                  :clearable="clearable"
                  :searchable="false"
                  placeholder="Класс"
                  v-model="selectedGradeNumber"
                >
                </v-select>
              </th>
              <th>
                <v-select
                  class="small_select"
                  :options="schoolGradeLiteras"
                  :clearable="clearable"
                  :searchable="false"
                  placeholder="А-Я"
                  v-model="selectedGradeLetter"
                >
                </v-select>
              </th>
              <th>пройдено тестов</th>
              <th>% верных ответов</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="student in filteredStudents"
              :key="student.id"
              :class="{ disabled: student.is_disabled }"
            >
              <td>
                <router-link
                  class="link"
                  :to="{
                    name: 'teacher-student-edit',
                    params: { id: student.id },
                  }"
                  >{{ student.fio }}</router-link
                >
              </td>
              <td>{{ student.grade_number }}</td>
              <td>{{ student.grade_litera }}</td>
              <td>{{ student.finished_sessions_count }}</td>
              <td>
                <template v-if="student.finished_sessions_count != 0">
                  {{ student.performance }}%
                </template>
                <template v-else> — </template>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <span
          class="expand_table_link"
          @click="toggleShowAllStudents"
          v-if="students && students.length > 3"
        >
          <span v-if="tables.students.showAll == false">Смотреть всех</span>
          <span v-else>Свернуть</span>
        </span> -->
      </div>

      <div class="table__container" v-if="activeTab == 'search'">
        <table class="table" v-if="liveSearchResults.length">
          <thead>
            <tr>
              <th>Результат поиска</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(result, index) in liveSearchResults" :key="index">
              <td>
                <span
                  class="link"
                  v-html="searchFormatter('', result)"
                  @click="searchResultClick(result)"
                ></span>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <div class="spacer200"></div>
  </Layout>
</template>

<script>
import { groupBy, map, uniq } from "lodash"
import { grades, baseTests } from "@/api"
import formatDate from "@/utils/formatDate"
import ireplace from "@/utils/ireplace"

import { mapState } from "vuex"

import Button from "@/components/UI/Button"
import Search from "@/components/UI/Search"
import TestFilter from "@/components/UI/TestFilter.vue"
import AdaptiveModal from "@/components/UI/Modal_Adaptive.vue"

import Layout from "@/components/Layouts/Layout"

import "vue-select/dist/vue-select.css"
import vSelect from "vue-select"

export default {
  components: {
    Layout,
    Button,
    Search,
    TestFilter,
    AdaptiveModal,
    vSelect,
  },
  data() {
    return {
      formatDate,
      liveSearchResults: [],
      tables: {
        students: {
          showAll: false,
        },
        grades: {
          showAll: false,
        },
      },
      grades: null,
      baseTests: [],
      activeTab: "grades",
      selectedGrade: 1,
      activeTestsTab: "school_tests",
      selectedGradeNumber: null,
      selectedGradeLetter: null,
    }
  },
  computed: {
    ...mapState(["students", "tests", "userProfile"]),
    studentsSlice() {
      if (!this.students) return []
      return this.students.slice(
        0,
        this.tables.students.showAll ? undefined : 3
      )
    },
    gradesSlice() {
      if (!this.grades) return []
      return this.grades.slice(0, this.tables.grades.showAll ? undefined : 3)
    },
    testsFilteredByGrade() {
      if (!this.tests) return []
      return this.tests.filter(
        (test) => test.grade_number == this.selectedGrade
      )
    },
    baseTestsFilteredByGrade() {
      if (!this.baseTests) return []
      return this.baseTests.filter(
        (baseTest) => baseTest.grade_number == this.selectedGrade
      )
    },
    subjectTests() {
      return groupBy(this.testsFilteredByGrade, "subject.name")
    },
    subjectBaseTests() {
      return groupBy(this.baseTestsFilteredByGrade, "subject.name")
    },
    schoolGradeNumbers() {
      const numbers = uniq(
        map(this.students, (student) => student.grade_number)
      )
      numbers.sort()
      return numbers
    },
    schoolGradeLiteras() {
      const litera = uniq(map(this.students, (student) => student.grade_litera))
      litera.sort()
      return litera
    },
    filteredStudents() {
      return this.students.filter((student) => {
        const gradeCondition =
          this.selectedGradeNumber == student.grade_number ||
          this.selectedGradeNumber == null
        const literaCondition =
          this.selectedGradeLetter == student.grade_litera ||
          this.selectedGradeLetter == null
        return gradeCondition && literaCondition
      })
    },
  },
  mounted() {
    this.getGradesWithAggregates()
    this.getBaseTests()

    if (this.$route.params.activeTab) {
      this.activeTab = this.$route.params.activeTab
    }
    if (this.$route.params.testsType) {
      console.log(this.$refs.testFilter)
      // this.activeTestsTab = this.$route.params.testsType
      this.$nextTick(() => {
        this.$refs.testFilter.onTabChanged(this.$route.params.testsType)
      })
    }
  },
  methods: {
    async getGradesWithAggregates() {
      this.grades = await grades.list({ testing_aggregates: true })
    },
    async getBaseTests() {
      this.baseTests = await baseTests.list({
        limit: 10000,
        offset: 0,
      })
    },
    toggleShowAllStudents() {
      this.tables.students.showAll = !this.tables.students.showAll
    },
    changeActiveTab(tab_name) {
      this.activeTab = tab_name
    },
    toggleShowAllGrades() {
      this.tables.grades.showAll = !this.tables.grades.showAll
    },
    searchButtonPress(query) {
      if (query) {
        this.activeTab = "search"
      }
    },
    liveSearch(query) {
      query = query.trim()
      if (query.length > 0) {
        const grades = this.grades.filter((grade) => {
          return grade.full_name
            .toLowerCase()
            .replace("«", "")
            .replace("»", "")
            .replace(" ", "")
            .includes(query.toLowerCase())
        })
        const students = this.students.filter((student) => {
          return student.fio.toLowerCase().includes(query.toLowerCase())
        })
        const tests = this.tests.filter((test) => {
          return test.name.toLowerCase().includes(query.toLowerCase())
        })
        grades.forEach((grade) => {
          grade.type = "grade"
        })
        students.forEach((student) => {
          student.type = "student"
        })
        tests.forEach((test) => {
          test.type = "test"
        })
        this.liveSearchResults = [...grades, ...students, ...tests]
      } else {
        this.liveSearchResults = []
      }
    },
    searchFormatter(query, search_result) {
      let result = ""
      switch (search_result.type) {
        case "grade":
          result = `<b>Класс: </b><span>${search_result.name}</span>`
          break
        case "student":
          result = `<b>Ученик: </b><span>${search_result.fio}</span>`
          break
        case "test":
          result = `<b>Тест: </b><span>${search_result.name}</span>`
          break
      }
      if (query) {
        result = ireplace(
          result,
          query,
          "<span class='search_results__item__highlight'>$1</span>"
        )
      }
      return result
    },
    searchResultClick(search_result) {
      switch (search_result.type) {
        case "grade":
          this.$router.push({
            name: "teacher-grade-view",
            params: { id: search_result.id },
          })
          break
        case "student":
          this.$router.push({
            name: "teacher-student-edit",
            params: { id: search_result.id },
          })
          break
        case "test":
          this.$router.push({
            name: "teacher-test-edit",
            params: { id: search_result.id },
          })
          break
      }
    },
    gradeSelected(grade) {
      this.selectedGrade = grade
    },
    tabChanged(tab_name) {
      this.activeTestsTab = tab_name
    },
    subjectIsActiveForSchool(subject_id) {
      if (
        !this.userProfile?.school ||
        this.userProfile?.school?.tarif == "start"
      ) {
        return false
      }
      if (this.userProfile.school.tarif == "all_inclusive") {
        return true
      }
      const schoolSubjects = this.userProfile.school.selected_subjects
      return schoolSubjects.indexOf(subject_id) !== -1
    },
  },
}
</script>

<style scoped>
.students__table :nth-child(n + 2):nth-child(-n + 4) {
  text-align: center;
}
.students__table td:nth-child(n + 2):nth-child(-n + 4) {
  text-align: center;
}
.students__table thead {
  height: 80px;
}
.small_select {
  width: 120px;
  margin: auto;
}
/* .small_select >>> ul {
  width: 120px !important;
} */
.small_select >>> .vs__search {
  padding-bottom: 0px !important;
  padding-left: 18px !important;
  padding: 0px;
}
.small_select >>> .vs__selected-options {
  flex-wrap: nowrap !important;
  height: 50px;
}
.small_select >>> .vs__selected-options:has(.vs__selected) .vs__search {
  display: none;
}
.small_select >>> .vs__selected {
  padding-bottom: 2px !important;
}
</style>
