<template>
  <Layout url_prefix="teacher">
    <template #page_header>
      <div class="h1">Создать класс</div>
    </template>
    <div class="right_shifted_form">
      <GradeForm @form_data_changed="formDataChanged"></GradeForm>
      <div class="form_buttons">
        <p>
          После нажатия на “Создать класс” в системе будет сформирована карточка
          преподавателя, а на указанный адрес почты отправятся реквизиты для
          входа.
        </p>
        <div class="buttons__container__without_grow">
          <Button class="button_top" @click="save">Создать класс</Button>
          <Button
            class="button_gray"
            @click="$router.push({ name: 'teacher-index' })"
            >Выйти без сохранения</Button
          >
        </div>
      </div>
    </div>
    <Alert ref="alert" />
    <div class="spacer200"></div>
  </Layout>
</template>

<script>
import { grades } from "@/api"

import Button from "@/components/UI/Button.vue"
import Layout from "@/components/Layouts/Layout.vue"
import GradeForm from "@/components/Forms/GradeForm.vue"
import Alert from "@/components/Functional/Alert.vue"

export default {
  components: {
    Layout,
    Button,
    GradeForm,
    Alert,
  },
  data() {
    return {
      form_data: {
        name: "",
        comment: "",
      },
    }
  },
  methods: {
    formDataChanged(data) {
      this.form_data.name = data.name
      this.form_data.comment = data.comment
    },
    async save() {
      const response = await grades.create(this.form_data)
      this.$store.dispatch("fetchGrades")
      this.$refs.alert.alert("Класс создан", () => {
        this.$router.push({
          name: "teacher-grade-edit",
          params: { id: response.id },
        })
      })
    },
  },
}
</script>

<style></style>
