import { createStore } from "vuex"
import {
  regions,
  grades,
  subjects,
  teachers,
  students,
  tests,
  assignments,
} from "@/api"

export default createStore({
  state: {
    userProfile: null,
    regions: null,
    grades: null,
    teachers: null,
    students: null,
    subjects: null,
    tests: null,
    assignments: null,
    activeTestTab: 'school_tests',
    selectedGrade: 1,
  },
  getters: {},
  mutations: {
    setUserProfile(state, profile) {
      state.userProfile = profile
    },
    setRegions(state, regions) {
      state.regions = regions
    },
    setGrades(state, grades) {
      state.grades = grades
    },
    setTeachers(state, teachers) {
      state.teachers = teachers
    },
    setStudents(state, students) {
      state.students = students
    },
    setSubjects(state, subjects) {
      state.subjects = subjects
    },
    setTests(state, tests) {
      state.tests = tests
    },
    setAssignments(state, assignments) {
      state.assignments = assignments
    },
    clearState(state) {
      state.regions = null
      state.grades = null
      state.students = null
      state.subjects = null
      state.tests = null
      state.assignments = null
    },
    setActiveTestTab(state, payload) {
      state.activeTestTab = payload.active_tab_name
    },
    setTestGrade(state, payload) {
      state.selectedGrade = payload.active_grade
    }
  },
  actions: {
    async fetchRegions({ commit }) {
      const response = await regions.list()
      commit("setRegions", response)
    },
    async fetchGrades({ commit }) {
      const response = await grades.list()
      commit("setGrades", response)
    },
    async fetchTeachers({ commit }) {
      const response = await teachers.list()
      commit("setTeachers", response)
    },
    async fetchStudents({ commit }) {
      const response = await students.list()
      commit("setStudents", response)
    },
    async fetchSubjects({ commit }) {
      const response = await subjects.list()
      // sort by name
      response.sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
      commit("setSubjects", response)
    },
    async fetchTests({ commit }) {
      const response = await tests.list({
        limit: 10000,
        offset: 0,
      })
      commit("setTests", response)
    },
    async fetchAssignments({ commit }) {
      const response = await assignments.list()
      commit("setAssignments", response)
    },
  },
  modules: {},
})
