<template>
  <AdaptiveModal ref="mistakesModal">
    <template v-if="mistakes.mistakes.length">
      <div class="h3 centered">Ошибки теста «{{ mistakes.test.name }}»:</div>
      <ul class="wrong_answered_questions">
        <li v-for="question in mistakes.mistakes" :key="question.number">
          № {{ question.number }}. {{ question.question }}
        </li>
      </ul>
    </template>
    <template v-else>
      <div class="h3 centered">Ошибок нет</div>
    </template>
  </AdaptiveModal>
</template>

<script>
import { sessions } from "@/api"
import AdaptiveModal from "@/components/UI/Modal_Adaptive.vue"

export default {
  components: {
    AdaptiveModal,
  },
  data() {
    return {
      mistakes: null,
    }
  },
  methods: {
    async showMistakesForSession(session) {
      this.mistakes = await sessions.mistakes(session.id)
      this.$nextTick(() => {
        this.$refs.mistakesModal.open()
      })
    },
  },
}
</script>
