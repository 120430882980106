<template>
  <div class="progress-bar-bg" :style="cssProps">
    <div class="progress-bar"></div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: Number,
    bg_color: {
      type: String,
      default: "#FF0952",
    },
    bar_color: {
      type: String,
      default: "#9FD900",
    },
    width: {
      default: "100px",
      type: String,
    },
    height: {
      default: "10px",
      type: String,
    },
  },
  data() {
    return {}
  },
  computed: {
    cssProps() {
      return {
        "--progressbar-bg-color": this.bg_color,
        "--progressbar-bar-color": this.bar_color,
        "--progressbar-width": this.width,
        "--progressbar-height": this.height,
        "--progressbar-value": `${this.value}%`,
      }
    },
  },
  methods: {},
}
</script>

<style scoped>
.progress-bar-bg {
  background-color: var(--progressbar-bg-color);
  width: var(--progressbar-width);
  height: var(--progressbar-height);
  /* width: 100%;
  height: 100%; */
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: calc(var(--progressbar-height) / 2);

  display: flex;
  justify-content: start;
}
.progress-bar {
  height: 100%;
  width: var(--progressbar-value);
  background-color: var(--progressbar-bar-color);
  border-radius: calc(var(--progressbar-height) / 2 - 0.6px);
}
</style>
