<template>
  <span
    class="test_result_status"
    v-if="session.status"
    :class="'test_result_status_' + session.status"
  >
    <!-- Пройдено -->
    <span
      class="test_result_status_row"
      v-if="session.status == 'F' && session.result.success == true"
    >
      <!-- <div class="icon_container">
        <img class="test_resul_status_icon" src="@/assets/img/done.svg" />
      </div> -->
      <div class="done">пройдено</div>
    </span>

    <!-- Пройдено с ошибками -->
    <span
      class="test_result_status_row"
      v-if="session.status == 'F' && session.result.success == false"
    >
      <!-- <div class="icon_container">
        <img class="test_resul_status_icon" src="@/assets/img/failed.svg" />
      </div> -->
      <span
        class="show_mistakes"
        v-if="show_mistakes"
        @click="showMistakes(session)"
      >
        {{ session.result.total_points }}/{{ session.result.question_count }}
        показать ошибки
      </span>
    </span>

    <!-- Пауза -->
    <span class="test_result_status_row" v-if="session.status == 'P'">
      <div class="icon_container">
        <img class="test_resul_status_icon" src="@/assets/img/paused.svg" />
      </div>
      <div class="test_resul_status_text">пауза</div>
    </span>

    <!-- Активен -->
    <span class="test_result_status_row" v-if="session.status == 'S'">
      активен
      <span
        v-if="
          show_intermediate_results &&
          session.intermediate_result &&
          session.intermediate_result.total_points
        "
      >
        {{ session.intermediate_result.total_points }}
        /
        {{ session.intermediate_result.question_count }}
      </span>
    </span>

    <!-- Новый :) -->
    <span class="test_result_status_row" v-if="session.status == 'A'">
      назначено
    </span>

    <!-- Не явился :) -->
    <span class="test_result_status_row" v-if="session.status == 'N'">
      не явился
    </span>
  </span>
</template>

<script>
// import AdaptiveModal from "@/components/UI/Modal_Adaptive.vue"

export default {
  components: {
    // AdaptiveModal,
  },
  props: {
    session: { type: Object },
    show_mistakes: { type: Boolean, default: true },
    show_intermediate_results: { type: Boolean, default: false },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    showMistakes(session) {
      this.$emit("show-mistakes", session)
      // this.$refs.passwordModal.open()
    },
  },
}
</script>
<style scoped>
.done {
  background-color: var(--greenz);
  display: block;
  padding: 0px 6px 3px 6px;
}
.test_result_status_row {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
}
.test_resul_status_text {
  padding-bottom: 2px;
}
.show_mistakes {
  background-color: var(--red);
  display: block;
  padding: 0px 6px 3px 6px;
  cursor: pointer;
}
</style>
