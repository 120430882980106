<template>
  <div class="half_screen_bg">
    <div class="background">
      <img class="answer_button" src="@/assets/img/login_bg.png" />
    </div>
    <div class="centered_form__container">
      <img class="st-logo" src="@/assets/img/st_logo.png" />
      <div class="centered_form">
        <div class="centered_form__header h1">Напомнить пароль</div>
        <div class="centered_form__input_container w300">
          <input
            type="email"
            v-model="username"
            placeholder="Адрес электронной почты"
            class="email_input"
          />
          <div class="buttons__container">
            <Button class="login_button button_top" @click="recoveryPassword"
              >Отправить</Button
            >
            <Button @click="back" class="button_gray login_button"
              >Отмена</Button
            >
          </div>
        </div>
      </div>
    </div>
    <Alert ref="alert" />
  </div>
</template>

<script>
import { recoveryRequest } from "@/api/auth"
import Button from "@/components/UI/Button"
import Alert from "@/components/Functional/Alert.vue"

export default {
  data() {
    return {
      username: "",
    }
  },
  components: {
    Alert,
    Button,
  },
  methods: {
    async recoveryPassword() {
      try {
        await recoveryRequest(this.username)
        this.$refs.alert.alert(
          "Ссылка для восстановления пароля отправлена на почту",
          () => {
            this.$router.push({ name: "login" })
          }
        )
      } catch {
        this.$refs.alert.alert("Пользователь не найден")
      }
    },
    back() {
      this.$router.push({ name: "login" })
    },
  },
}
</script>

<style scoped>
.centered_form {
  /* padding-top: 200px; */
  width: 500px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}
.centered_form__input_container {
  width: 310px;
  display: flex;
  flex-flow: column wrap;
  gap: 10px;
  /* justify-content: center; */
}
.centered_form__input_container .button {
  margin-top: 15px;
  align-self: flex-start;
}
.half_screen_bg {
  display: flex;
  flex-flow: row;
  flex-basis: 50%;
  margin-left: -50px;
  margin-right: -50px;
}
.background {
  height: 100vh;
  width: 100%;
  background-color: aquamarine;
}
.background img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: right;
}
.centered_form__container {
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  padding-left: 122px;
  align-items: flex-start;
  height: 100vh;
  width: 80%;
  gap: 180px;
}
.st-logo {
  padding-top: 64px;
}

.password_recovery_link {
  padding-left: 24px;
  color: var(--greenz_link);
}
.login_button {
  width: 150px;
}
.email_input {
  width: 100%;
}
</style>
