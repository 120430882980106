<template>
  <AdaptiveModal class="alert_modal" ref="modal">
    <p>{{ message }}</p>
    <Button @click="close">Продолжить</Button>
  </AdaptiveModal>
</template>

<script>
import Button from "@/components/UI/Button.vue"
import AdaptiveModal from "@/components/UI/Modal_Adaptive.vue"
export default {
  components: {
    AdaptiveModal,
    Button,
  },
  data() {
    return {
      message: "",
      callback: null,
    }
  },
  methods: {
    alert(msg, callback = null) {
      this.message = msg
      this.callback = callback
      this.$refs.modal.open()
    },
    close() {
      this.$refs.modal.close()
      if (this.callback) {
        this.callback()
      }
    },
  },
}
</script>
<style scoped>
.alert_modal >>> .modal-container {
  text-align: center;
  position: relative;
}
.alert_modal >>> .modal-container p {
  margin-bottom: 44px;
  margin-top: 30px;
}
</style>
