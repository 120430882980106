import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

import "@/assets/css/main.css"

import axios from "axios"

axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
axios.defaults.xsrfCookieName = "csrftoken"

import VueScrollTo from "vue-scrollto"

const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value()
      }
    }
    document.addEventListener("click", el.clickOutsideEvent)
  },
  unmounted: (el) => {
    document.removeEventListener("click", el.clickOutsideEvent)
  },
}

createApp(App)
  .use(store)
  .use(router)
  .use(VueScrollTo)
  .directive("click-outside", clickOutside)
  .mount("#app")
