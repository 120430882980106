<template>
  <div style="display: flex; width: 334px; vertical-align: middle">
    <input
      accept="image/*"
      ref="fileInput"
      type="file"
      @change="handleFileUpload"
      style="display: none"
    />
    <template v-if="base64Image">
      <div class="avatar">
        <img :src="base64Image" />
      </div>
      <a @click="removeAvatar" class="remove-image">удалить </a>
    </template>
    <template v-else>
      <div class="button button_gray" @click="uploadFile">Загрузить</div>
      <div
        :class="fileSizeError ? 'red' : ''"
        style="text-align: center; padding-left: 20px"
      >
        Максимальный размер<br />
        файла {{ maxMBFileSize }}Mb
      </div>
    </template>
  </div>
</template>

<script>
import { cropToBox, resize } from "@/utils/base64"

export default {
  components: {},
  emits: ["imageLoaded", "imageRemoved"],
  props: {
    base64_image: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      maxMBFileSize: 5,
      fileSizeError: false,
      base64Image: null,
    }
  },
  mounted() {
    this.base64Image = this.base64_image
  },
  computed: {
    maxFileSize() {
      return 1024 * 1024 * this.maxMBFileSize
    },
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.click()
    },
    handleFileUpload(event) {
      const file = event.target.files[0]

      if (file.size > this.maxFileSize) {
        this.fileSizeError = true
        return
      }
      this.fileSizeError = false

      const reader = new FileReader()

      reader.onload = () => {
        // const base64String = reader.result
        const bigBase64Image = reader.result
        this.cropToSquare(bigBase64Image, 500, 500)
          .then((resizedBase64) => {
            // console.log(resizedBase64)
            this.base64Image = resizedBase64
            this.$emit("imageLoaded", this.base64Image)
          })
          .catch((error) => {
            console.error(error)
          })
      }

      reader.readAsDataURL(file)
    },
    removeAvatar() {
      this.base64Image = null
      this.$emit("imageRemoved")
    },
    resizeBase64Image(base64, width, height) {
      return resize(base64, width, height)
    },
    cropToSquare(base64, width, height) {
      return cropToBox(base64, width, height)
    },
  },
  watch: {
    base64_image: function () {
      this.base64Image = this.base64_image
    },
  },
}
</script>

<style scoped>
.red {
  color: var(--alert);
}
.avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 25px;
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.remove-image {
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 11px;
  color: var(--alert);
  cursor: pointer;
  text-decoration: underline;
}
</style>
