<template>
  <Layout url_prefix="admin">
    <template #page_header>
      <div class="h1">Профиль</div>
    </template>

    <template #page_content>
      <div class="right_shifted_form">
        <div class="form school_form">
          <div class="centered_form__input_row">
            <label for="email">Адрес почты</label>
            <input
              type="email"
              name="email"
              v-model="email"
              class="profile_input"
            />
          </div>
          <div class="centered_form__input_row">
            <label for="password">Новый пароль</label>
            <input
              type="password"
              name="password"
              v-model="password"
              class="profile_input"
            />
          </div>
          <div class="centered_form__input_row">
            <label for="password">Повторите пароль</label>
            <input
              type="password"
              name="password_repeat"
              v-model="password_repeat"
              class="profile_input"
            />
          </div>
          <div class="form_buttons">
            <div class="buttons__container__without_grow">
              <Button class="button_top" @click="save">Сохранить</Button>
              <Button
                @click="$router.push({ name: 'admin-index' })"
                class="button_gray"
                >Выйти без сохранения</Button
              >
            </div>
          </div>
        </div>
      </div>

      <Alert ref="alert" />
    </template>
  </Layout>
</template>

<script>
import { changeAdminEmail, changeOwnPassword, refreshProfile } from "@/api/auth"
import { mapState } from "vuex"
import Button from "@/components/UI/Button.vue"
import Layout from "@/components/Layouts/Layout.vue"
import AdaptiveModal from "@/components/UI/Modal_Adaptive.vue"
import Alert from "@/components/Functional/Alert.vue"

import { content } from "@/api"

export default {
  components: {
    Layout,
    Button,
    AdaptiveModal,
    Alert,
  },
  data() {
    return {
      email: "",
      password: "",
      password_repeat: "",
      leftColumn: "",
      rightColumn: "",
      contentIsReady: false,
    }
  },
  mounted() {},
  computed: {
    ...mapState(["userProfile"]),
  },
  methods: {
    async save() {
      if (this.password && this.password !== this.password_repeat) {
        this.$refs.alert.alert("Введённые пароли не совпадают.")
        return
      }
      try {
        await changeAdminEmail(this.email)
        if (this.password) await changeOwnPassword(this.password)
        await refreshProfile()
        this.$router.push({ name: "admin-index" })
      } catch (e) {
        this.$refs.alert.alert(
          "Возникла ошибка. Возможно, адрес электронной почты уже занят."
        )
      }
    },
  },
  watch: {
    userProfile: {
      handler(profile) {
        if (profile) this.email = profile.username
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
.centered_form__input_container {
  margin: auto;
  display: flex;
  flex-flow: column wrap;
  gap: 10px;
}
.buttons__container {
  padding-top: 37px;
  padding-left: 205px;
}
.profile_input {
  width: 310px;
}
.school_form {
  margin-left: -303px;
}
.form_buttons {
  margin-left: 330px;
  width: 600px;
  padding-top: 10px;
}
</style>
