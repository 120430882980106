const ireplace = (str, search, replace) => {
  if (!RegExp.escape) {
    RegExp.escape = function (value) {
      return value.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&")
    }
  }
  var regex = new RegExp("(" + RegExp.escape(search) + ")", "ig")
  let result = str.replace(regex, replace)
  return result
}

export default ireplace
