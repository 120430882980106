<template>
  <AdminLayout>
    <div class="page_header">
      <div class="h1">Создать школу</div>
    </div>
    <div class="page_content">
      <div class="right_shifted_form">
        <SchoolForm
          @form_data_changed="formDataChanged"
          ref="schoolCreate"
        ></SchoolForm>
        <div class="form_buttons">
          <p>
            После нажатия на “Создать школу” в системе будет сформирована
            карточка школы, а на указанный адрес почты отправятся реквизиты для
            входа.
          </p>
          <div class="buttons__container__without_grow">
            <Button class="button_top" @click="createSchool">
              Создать школу
            </Button>
            <Button
              class="button_gray"
              @click="$router.push({ name: 'admin-index' })"
            >
              Выйти без сохранения
            </Button>
          </div>
        </div>
      </div>
    </div>
    <Alert ref="alert" />
    <div class="spacer200"></div>
  </AdminLayout>
</template>

<script>
import { schools } from "@/api"
import Button from "@/components/UI/Button"
import AdminLayout from "@/components/Layouts/AdminLayout"
import SchoolForm from "@/components/Forms/SchoolForm.vue"
import Alert from "@/components/Functional/Alert.vue"

export default {
  components: {
    AdminLayout,
    SchoolForm,
    Button,
    Alert,
  },
  data() {
    return {
      school: null,
    }
  },
  methods: {
    formDataChanged(data) {
      this.school = data
    },
    async createSchool() {
      const form_checked = this.$refs.schoolCreate.checkFields()
      if (!form_checked) {
        this.$refs.alert.alert("Пожалуйста, заполните все обязательные поля.")
        return
      }
      if (form_checked && this.school) {
        try {
          const school = await schools.create(this.school)
          this.$refs.alert.alert("Школа создана", () => {
            this.$router.push({
              name: "admin-school-edit",
              params: { id: school.id },
            })
          })
        } catch (error) {
          console.log(error)
          if (error.response.status == 400) {
            if (error.response.data.inn) {
              this.$refs.alert.alert(
                "Школа с таким ИНН уже существует в базе данных."
              )
            } else {
              if (
                error.response.data.email &&
                error.response.data.email == "Email already exists"
              ) {
                this.$refs.alert.alert(
                  "Пользователь с такой электронной почтой уже существует в базе данных."
                )
              } else {
                this.$refs.alert.alert(
                  "Не удалось создать школу. Пожалуйста, заполните все поля правильно."
                )
              }
            }
          } else {
            this.$refs.alert.alert(
              "Возникла ошибка сервера 500 при создании школы. Пожалуйста, сообщите об этом разработчикам. Ошибка: " +
                error.response.data.message
            )
          }
        }
      }
    },
  },
}
</script>

<style></style>
