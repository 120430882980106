<template>
  <div class="h2 centered_header" style="margin-top: 50px">Назначить тест:</div>
  <div class="assignment_container">
    <div class="assignment_selects">
      <v-select
        class="vs_subject_select"
        :options="grades_options"
        :reduce="(option) => option.value"
        :disabled="disabled"
        :searchable="false"
        v-model="form_data.grade"
        placeholder="Выберите класс"
        :clearable="false"
      >
      </v-select>
      <CheckboxSelect
        class="vs_test_select"
        :options="students_options"
        placeholder="Весь класс"
        :disabled="disabled || !form_data.grade"
        v-model="form_data.students"
        label-name="fio"
        value-name="id"
        :hasVariants="hasVariants"
      />
      <v-select
        class="vs_limit_select"
        :options="time_limit_options"
        :reduce="(option) => option.value"
        v-model="form_data.time_limit"
        :disabled="disabled || !form_data.time_limit"
        :searchable="false"
        :clearable="false"
      >
      </v-select>
    </div>

    <Button class="button_bottom start" @click="createAssignment">СТАРТ</Button>
  </div>
</template>

<script>
import Button from "@/components/UI/Button"
import CheckboxSelect from "../UI/CheckboxSelect.vue"
import "vue-select/dist/vue-select.css"
import vSelect from "vue-select"
import { time_limit_options } from "@/utils/const.js"

export default {
  components: {
    Button,
    vSelect,
    CheckboxSelect,
  },
  props: ["students", "grades", "default_time_limit", "hasVariants"],
  emits: ["createAssignment"],
  data() {
    return {
      time_limit_options,
      form_data: {
        grade: null,
        students: [],
        time_limit: 40 * 60,
      },
      disabled: false,
    }
  },
  computed: {
    grades_options() {
      if (!this.grades) return []
      return this.grades.map((grade) => {
        return {
          label: grade.full_name,
          value: grade.id,
        }
      })
    },
    students_options() {
      if (!this.students || !this.form_data.grade) return []
      return this.students.filter((student) => {
        return student.grade == this.form_data.grade
      })
      // .map((student) => {
      //   return {
      //     label: student.fio,
      //     value: student.id,
      //   }
      // })
    },
  },
  mounted() {
    if (this.default_time_limit) {
      this.form_data.time_limit = this.default_time_limit
    }
  },
  methods: {
    createAssignment() {
      if (this.form_data.grade && this.form_data.time_limit) {
        this.$emit(
          "createAssignment",
          this.form_data.grade,
          this.form_data.students,
          this.form_data.time_limit
        )
        this.form_data.grade = null
        this.form_data.students = []
        this.form_data.time_limit = 40 * 60
      }
    },
  },
  watch: {
    "form_data.grade"() {
      this.form_data.students = []
    },
  },
}
</script>

<style scoped>
>>> {
  --vs-border-radius: 25px;
}
.start {
  width: 110px;
}
.assignment_container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  /* justify-content: space-between; */
  gap: 27px;
  flex-flow: row wrap;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.assignment_selects {
  display: flex;
  /* justify-content: center; */
  gap: 10px;
  max-width: 800px;
}
.vs_subject_select {
  width: 277px;
  height: 50px;
}
.vs_test_select {
  width: 400px;
  height: 50px;
}
.vs_limit_select {
  width: 200px;
  height: 50px;
}
/* 
.vs__search {
  width: 75px !important;
}
.vs__selected-options {
  width: 50px;
}
.v-select {
  font-size: 14px;
} */
</style>
<style>
.vs_limit_select .vs__dropdown-menu {
  max-width: 180px !important;
  min-width: unset !important;
}
</style>
