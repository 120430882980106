<template>
    <div>
        <div class="pagination">
            <div
                class="pagination__item"
                v-for="page in Math.ceil(count / page_size)"
                :key="page"
            >
                <a href="#" @click="changePage" :class="{active: current_page == page}">{{ page }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["count", "page_size"],
    data() {
        return {
            current_page: 1
        }
    },
    methods: {
        changePage(event) {
            event.preventDefault()
            this.current_page = parseInt(event.target.innerText)
            this.$emit("pageChange", this.current_page)
        }
    }
}
</script>

<style>
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination__item {
    margin: 0 5px;
}

.pagination__item a {
    text-decoration: none;
    padding: 5px;
}

.pagination__item a.active {
    background-color: var(--greenz);
    color: white;
    border-radius: 5px;
    cursor: default;
}
</style>