<template>
  <div class="h2 centered_header" style="margin-top: 50px">Назначить тест:</div>
  <div class="assignment_container">
    <div class="assignment_selects">
      <PixelionSelect
        class="vs_subject_select"
        :options="subject_options"
        :reduce="(option) => option.value"
        :disabled="disabled"
        :searchable="false"
        v-model="form_data.subject"
        :clearable="false"
        placeholder="Выберите предмет"
      />
      <PixelionSelect
        class="vs_test_select"
        :options="test_options"
        :reduce="(option) => option.value"
        v-model="form_data.test"
        :disabled="disabled || !form_data.subject"
        :searchable="false"
        :clearable="false"
        placeholder="Выберите тест"
      />

      <div
        v-if="!students && selectedTest(form_data.test)?.has_second_variant"
        class="variant_selector__container"
      >
        <div class="variant_selector">
          <div
            @click="form_data.variant = 1"
            :class="{ selected: form_data.variant == 1 }"
          >
            вар. 1
          </div>
          <div
            @click="form_data.variant = 2"
            :class="{ selected: form_data.variant == 2 }"
          >
            вар. 2
          </div>
        </div>
      </div>
      <PixelionSelect
        class="vs_limit_select"
        :options="time_limit_options"
        :reduce="(option) => option.value"
        v-model="form_data.time_limit"
        :disabled="disabled || !form_data.test"
        :searchable="false"
        :clearable="false"
      />
    </div>
    <div v-if="students" class="students_select">
      <CheckboxSelect
        class="vs_test_select"
        :options="students"
        placeholder="Весь класс"
        v-model="form_data.students"
        label-name="fio"
        value-name="id"
        :disabled="disabled || !form_data.subject"
        :hasVariants="selectedTest(form_data.test)?.has_second_variant"
      />
    </div>
    <Button class="button_bottom start" @click="createAssignment">СТАРТ</Button>
  </div>
</template>

<script>
import { uniqBy } from "lodash"
import Button from "@/components/UI/Button"
import "vue-select/dist/vue-select.css"
import vSelect from "vue-select"
import { time_limit_options } from "@/utils/const.js"
import PixelionSelect from "../UI/PixelionSelect.vue"
import CheckboxSelect from "../UI/CheckboxSelect.vue"

export default {
  components: {
    Button,
    vSelect,
    PixelionSelect,
    CheckboxSelect,
  },
  emits: ["createAssignment"],
  props: ["tests", "students"],
  data() {
    return {
      time_limit_options,
      form_data: {
        subject: null,
        test: null,
        students: [],
        time_limit: 40 * 60,
        variant: 1,
      },
      disabled: false,
    }
  },
  computed: {
    subject_options() {
      if (!this.tests) return []
      const uniqueSubjectsTests = uniqBy(this.tests, "subject.id")
      return uniqueSubjectsTests.map((test) => {
        return {
          label: test.subject.name,
          value: test.subject.id,
        }
      })
    },
    test_options() {
      if (!this.tests) return []
      return this.tests
        .filter((test) => {
          return test.subject.id == this.form_data.subject
        })
        .map((test) => {
          return {
            label: test.name,
            value: test.id,
          }
        })
    },
  },
  methods: {
    selectedTest(testId) {
      return this.tests?.find((test) => test.id == testId)
    },
    createAssignment() {
      if (
        this.form_data.subject &&
        this.form_data.test &&
        this.form_data.time_limit
      ) {
        this.$emit(
          "createAssignment",
          this.form_data.test,
          this.form_data.students,
          this.form_data.time_limit,
          this.form_data.variant
        )
      }
    },
  },
  watch: {
    "form_data.subject"() {
      this.form_data.test = null
      this.form_data.variant = 1
    },
    "form_data.test"(test_id) {
      if (test_id) {
        this.form_data.time_limit = this.tests.find(
          (test) => test.id == test_id
        ).default_time_limit
        this.form_data.variant = 1
      }
    },
  },
}
</script>

<style scoped>
.start {
  width: 110px;
}
.assignment_container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  /* justify-content: space-between; */
  gap: 27px;
  flex-flow: row wrap;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 55px;
  position: relative;
}
.assignment_selects {
  display: flex;
  /* justify-content: center; */
  gap: 10px;
  max-width: 800px;
}
.assignment_selects >>> .vs__selected-options {
  flex-wrap: nowrap;
}
.assignment_selects >>> .vs__selected {
  white-space: nowrap;
}
.assignment_selects >>> .vs_limit_select {
  width: 235px;
}
.students_select {
  position: absolute;
  top: 80px;
  left: 246px;
  width: fit-content;
}
.students_select >>> .multi-select {
  width: 342px;
}
.vs_subject_select {
  width: 277px;
}
.vs_test_select {
  width: 400px;
}
/* 
.vs__selected-options {
  width: 50px;
}
.v-select {
  font-size: 14px;
} */
</style>
<style>
.vs_limit_select .vs__dropdown-menu {
  max-width: 180px !important;
  min-width: unset !important;
}
.vs__dropdown-menu {
  border: none;
  /* box-sizing: none; */
}
</style>
<style scoped>
>>> {
  --vs-border-radius: 25px;
}
</style>

<style scoped>
.variant_selector {
  display: flex;
  padding-right: 10px;
}
.variant_selector div {
  border-bottom: 1px dashed #000000;
  white-space: nowrap;
  padding: 1px;
  margin-right: 5px;
  cursor: pointer;
}
.variant_selector div.selected {
  border-bottom: none;
  background-color: var(--greenz_link);
}
.variant_selector__container {
  display: flex;
  align-items: center;
}
</style>
