<template>
  <div class="answer_field" :class="{ checked: checked }">
    <!-- <input type="text" v-model="answer.text" @keyUp="change" /> -->
    <div class="top_answer_row">
      <textarea
        v-show="!showRenderedAnswerText"
        class="answer_text"
        ref="answer_text"
        rows="1"
        data-min-rows="1"
        placeholder=""
        v-model="answer.text"
        @keyup="change"
      ></textarea>
      <div
        class="answer_text rendered_answer_text"
        v-html="answer.text"
        v-show="showRenderedAnswerText"
        @click="openModalEditor"
      ></div>
      <div class="answer_control" v-click-outside="closeDropdown">
        <Button
          :class="{ selected: showDropdown }"
          class="answer_button_with_icon button_gray"
          @click="toggleDropdown"
        >
          <img src="@/assets/img/dots.svg" />
          <div class="dropdown-menu" v-if="showDropdown">
            <Button
              class="answer_button_with_icon button_gray"
              @click="photo"
              title="Загрузить фото"
            >
              <img src="@/assets/img/picture.svg" />
            </Button>
            <Button
              class="answer_button_with_icon button_gray"
              @click="video"
              title="Загрузить видео"
            >
              <img src="@/assets/img/video.svg" />
            </Button>
            <Button
              class="answer_button_with_icon button_gray"
              @click="openModalEditor"
            >
              <img src="@/assets/img/x2.svg" />
            </Button>
          </div>
        </Button>

        <!-- <Button class="answer_button_with_icon button_gray" @click="photo">
          <img src="@/assets/img/picture.svg" />
        </Button>
        <Button class="answer_button_with_icon button_gray" @click="video">
          <img src="@/assets/img/video.svg" />
        </Button> -->
        <Button
          class="answer_button_with_icon button_gray add_button"
          @click="add"
          v-if="!hideRemoveAdd"
          title="Добавить вариант"
        >
          <span class="icon icon-big_plus"></span>
        </Button>
        <Button
          class="answer_button_with_icon button_gray remove_button"
          @click="remove"
          title="Удалить вариант"
          v-if="!hideRemoveAdd && !hideRemove"
        >
          <span class="icon icon-trash"></span>
        </Button>
      </div>
    </div>

    <div class="media_container" v-if="answer.video_id || answer.photo_id">
      <div v-if="answer.photo_id" class="answer_photo">
        <img
          class="remove_media"
          src="@/assets/img/remove.png"
          @click="removePhoto"
          title="Удалить"
        />
        <img :src="media_url + answer.photo_url" alt="" />
      </div>
      <div v-if="answer.video_id" class="answer_video">
        <img
          class="remove_media"
          src="@/assets/img/remove.png"
          @click="removeVideo"
          title="Удалить"
        />
        <iframe
          width="240"
          :src="answer.video_url"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>

    <FileUpload
      ref="fileupload"
      url="/tester/upload/"
      uploadFieldName="image"
      :hidden="true"
      accept="image/jpg, image/jpeg, image/png, image/gif, image/webp, image/svg, image/svg+xml"
      @success="uploadSuccess"
    ></FileUpload>

    <AdaptiveModal class="alert_modal text_editor_container" ref="modal">
      <editor
        class="table_editor"
        v-model="editorModalData"
        api-key="8b01uvmy0ecrxmm4tpf682w4yxv238by1oc574229ewglbbt"
        :init="{
          height: 400,
          menubar: false,
          language: 'ru',
          statusbar: false,
          plugins: ['table'],
          toolbar: 'table | subscript | superscript ',
        }"
      />
      <Button @click="saveEditorData">Продолжить</Button>
    </AdaptiveModal>
  </div>
</template>

<script>
import FileUpload from "@/components/Functional/FileUpload.vue"
import Button from "@/components/UI/Button.vue"
import vClickOutside from "click-outside-vue3"
import AdaptiveModal from "@/components/UI/Modal_Adaptive.vue"
import Editor from "@tinymce/tinymce-vue"

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: ["answer", "hideRemoveAdd", "hideRemove", "checked"],
  components: {
    FileUpload,
    Button,
    AdaptiveModal,
    Editor,
  },
  data() {
    return {
      media_url: process.env.VUE_APP_MEDIA_URL,
      showDropdown: false,
      editorModalData: "",
      showRenderedAnswerText: false,
    }
  },
  computed: {
    showRenderedAnswerText() {
      var html = this.answer.text
      var regex = /(<([^>]+)>)/gi
      var match = html.match(regex)
      if (match) {
        // console.log("Теги найдены:", match);
        return true
      } else {
        // console.log("Теги не найдены.");
        return false
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.expandField(this.$refs.answer_text)
    })
  },
  methods: {
    photo() {
      this.$refs.fileupload.hit()
      this.closeDropdown()
    },
    video() {
      let link = prompt("Введите ссылку на видео")
      if (link) {
        if (!link.startsWith("https")) {
          link = "https://" + link
        }
        if (link.indexOf("youtube.com") > -1 || link.indexOf("youtu.be") > -1) {
          const r = link.match(
            /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/
          )
          if (r[1]) {
            this.answer.video_id = r[1]
            this.answer.video_url = "https://www.youtube.com/embed/" + r[1]
            this.change()
          }
        } else {
          alert("Введена некорректная ссылка")
          return
        }
      }
    },
    change(event) {
      this.$emit("change", this.answer)
      if (event) {
        this.expandField(event.target)
      }
    },
    remove() {
      this.$emit("remove", this.answer)
    },
    add() {
      this.$emit("add", this.answer)
    },
    removePhoto() {
      delete this.answer.photo_id
      delete this.answer.photo_url
      this.change()
    },
    removeVideo() {
      delete this.answer.video
      this.change()
    },
    uploadSuccess(response) {
      this.answer.photo_id = response[0].id
      this.answer.photo_url = response[0].url
      this.$refs.fileupload.reset()
      this.change()
    },
    getScrollHeight(elm) {
      var savedValue = elm.value
      elm.value = ""
      elm._baseScrollHeight = elm.scrollHeight
      elm.value = savedValue
    },
    expandField(elm) {
      var minRows = elm.getAttribute("data-min-rows") | 0
      !elm._baseScrollHeight && this.getScrollHeight(elm)
      elm.rows = minRows
      let rows = Math.ceil((elm.scrollHeight - elm._baseScrollHeight) / 18)
      elm.rows = minRows + rows
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    closeDropdown() {
      this.showDropdown = false
      console.log(this.showDropdown)
    },
    saveEditorData() {
      this.change()
      this.answer.text = this.editorModalData
      this.$refs.modal.close()
      console.log(this.editorModalData)
    },
    openModalEditor() {
      this.editorModalData = this.answer.text
      this.$refs.modal.open()
    },
  },
}
</script>

<style scoped>
.answer_text {
  /* display: block;
  box-sizing: padding-box;
  overflow: hidden; */
  font-size: 17px !important;
  width: 100%;
  font-size: 14px;
  border: 0;
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 6px;
  outline: none;
  margin-top: 5px;
  margin-bottom: 0px;
  background: none;
  overflow-y: hidden;
  resize: none;
}
.answer_text:focus {
  border: none;
  outline: none;
}
.answer_field {
  width: 100%;
  background: #f9f9f9;
  box-shadow: inset 0px 2px 4px rgb(0 0 0 / 10%);
  border-radius: 25px;
  border: none;
  padding-left: 24px;
  min-height: 50px;
  outline: none;
  margin-top: 0px;
  margin-bottom: 0px;
}
.top_answer_row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 50px;
}
.answer_control {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 5px;
  padding-right: 5px;
  margin-left: 5px;
  padding-top: 5px;
  /* position: relative; */
}

.answer_button_with_icon {
  height: 40px;
  width: 40px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0;
  margin: 0;
  background-color: var(--gray6) !important;
  position: relative;
}
.answer_control >>> .answer_button_with_icon:hover,
.answer_control >>> .answer_button_with_icon.selected {
  background-color: var(--greenz_link) !important;
}
.answer_control >>> .remove_button:hover {
  background-color: var(--alert) !important;
}
.answer_control >>> .remove_button:hover .icon-trash:before,
.answer_control >>> .add_button:hover .icon-big_plus:before {
  color: white;
}
.media_container {
  width: 260px;
  display: flex;
  justify-content: space-around;
  flex-flow: column wrap;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.answer_photo img {
  /* width: 100%; */
  max-width: 240px;
}
.answer_field.checked {
  background-color: var(--greenz) !important;
}
.answer_photo,
.answer_video {
  position: relative;
}
.remove_media {
  position: absolute;
  right: 20px;
  top: 0;
  width: 30px;
  height: 30px;
}
.dropdown-menu {
  position: absolute;
  top: 42px; /* Расположение выпадающего меню относительно кнопки */
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 8px;
  background-color: var(--white); /* Цвет фона выпадающего меню */
  border-radius: 28px;
  z-index: 10;
}
.text_editor_container >>> .tox.tox-tinymce {
  border-radius: 0 0 25px 25px;
  border: 0;
}

.text_editor_container >>> .tox-editor-header {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.text_editor_container >>> .tox .tox-edit-area__iframe {
  background-color: #f9f9f9;
}
</style>
