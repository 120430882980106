<template>
  <div class="answers_form">
    <div class="answers_form__header">Ответы:</div>
    <div class="answers_hint">
      <div class="answers_hint_text">
        задайте правильную последовательность ответов
      </div>
    </div>
    <div class="answer_hint_2">
      (ответы будут показаны ученикам в случайном порядке)
    </div>
    <template v-if="answers">
      <div
        class="answer_row"
        v-for="(answer, index) in answers"
        :key="answer.id"
      >
        <div class="answer_index">№ {{ answer.id }}</div>
        <AnswerField
          :answer="answer"
          @remove="remove"
          @add="add"
          @change="emitUpdate"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { cloneDeep } from "lodash"
import AnswerField from "@/components/Forms/TestForm/AnswerField.vue"

export default {
  props: ["question"],
  emits: ["update"],
  components: {
    AnswerField,
  },
  data() {
    return {
      answers: [
        { id: "1", text: "" },
        { id: "2", text: "" },
        { id: "3", text: "" },
      ],
      correct: [],
    }
  },
  mounted() {
    if (
      this.question.correct &&
      this.question.answers &&
      Array.isArray(this.question.correct) &&
      Array.isArray(this.question.answers)
    ) {
      let newAnswers = []
      this.question.correct.forEach((correct, index) => {
        let answer = cloneDeep(
          this.question.answers.find((a) => a.id == correct)
        )
        answer.id = (index + 1).toString()
        newAnswers.push(answer)
      })
      this.answers = newAnswers
      this.correct = newAnswers.map((a) => a.id)
    }
  },
  methods: {
    render() {
      const answers = cloneDeep(this.answers)
      const ids = answers.map((a) => parseInt(a.id))

      // randomize ids order
      ids.sort(() => Math.random() - 0.5)

      // assign new ids to answers
      answers.forEach((a, index) => {
        a.id = ids[index].toString()
      })

      // generate new correct answers list
      const correct = answers.map((a) => a.id)

      // randomize answers order
      answers.sort(() => Math.random() - 0.5)

      return {
        answers: answers,
        correct: correct,
      }
    },
    emitUpdate() {
      this.$emit("update", this.render())
    },
    remove(answer) {
      if (this.answers.length == 1) return
      this.answers = this.answers.filter((a) => a.id !== answer.id)
      this.enumerate()
      this.emitUpdate()
    },
    add(answer) {
      const idsList = this.answers.map((a) => parseInt(a.id))
      const newId = "" + (Math.max(...idsList) + 1)
      const newIndex = this.answers.indexOf(answer) + 1
      this.answers.splice(newIndex, 0, { id: newId, text: "" })
      this.enumerate()
      this.emitUpdate()
    },
    enumerate() {
      this.answers.forEach((a, index) => {
        a.id = index + 1
      })
      this.correct = this.answers.map((a) => a.id)
      this.emitUpdate()
    },
  },
}
</script>
<style scoped>
.answer_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-left: 242px;
  padding-bottom: 10px;
}
.answer_index {
  white-space: nowrap;
}
.answer_hint_2 {
  position: absolute;
  max-width: 225px;
  text-align: right;
}
</style>
