<template>
  <div class="answers_form">
    <div class="answers_form__header">Ответы:</div>
    <template v-if="groups">
      <div class="table_answer_groups" v-for="(group, groupIndex) in groups">
        <div class="divider_with_buttons__container">
          <div class="divider_with_buttons__line"></div>
          <div class="divider_with_buttons__header">группа вопрос-ответов</div>
          <div class="divider_with_buttons__buttons_container">
            <Button
              v-if="groups.length > 1"
              class="answer_button_with_icon shadow remove_button"
              @click="removeGroup(group)"
              title="Удалить группу"
            >
              <span class="icon icon-trash"></span>
            </Button>
            <Button
              @click="addGroup(group)"
              class="answer_button_with_icon add_button shadow button_white"
              title="Добавить группу"
            >
              <span class="icon icon-big_plus"></span>
            </Button>
          </div>
        </div>

        <div class="table_answer_group">
          <div class="table_answer_group__hint">
            <div>Название группы:</div>
            <div>
              <small>(название группы совпадает с названием ячейки)</small>
            </div>
          </div>
          <div class="table_answer_group__fields">
            <input
              type="text"
              class="answer_input"
              v-model="group.name"
              @change="emitUpdate"
            />
            <div class="table_answer_group__answers">
              <div class="table_answer_group__answers_header">
                <span class="answers_header__text">№ строки</span>
                <span class="answers_header__text">правильный ответ</span>
              </div>
              <div
                class="table_answer_group__answer_row"
                v-for="row in group.rows"
              >
                <input
                  type="text"
                  class="answer_input"
                  :value="row"
                  @keyup="editRowNumber(group, row, $event)"
                />
                <input
                  type="text"
                  class="answer_input"
                  :value="correct[groupIndex][row]"
                  @keyup="editRowAnswer(group, row, $event)"
                />
                <div class="table_answer_group__answer_row__controls">
                  <Button
                    class="answer_button_with_icon shadow remove_button"
                    @click="removeRow(group, row)"
                    v-if="group.rows.length > 1"
                    title="Удалить"
                  >
                    <span class="icon icon-trash"></span>
                  </Button>
                  <Button
                    class="answer_button_with_icon add_button shadow button_white"
                    @click="addRow(group, row)"
                    title="Добавить вариант"
                  >
                    <span class="icon icon-big_plus"></span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { cloneDeep, each } from "lodash"
import Button from "@/components/UI/Button.vue"

import CheckMark from "../CheckMark.vue"
import AnswerField from "@/components/Forms/TestForm/AnswerField.vue"

// "groups": [
//     {
//         "name": "Группа / столбец 1",
//         "rows": ["3", "4"]
//     },
//     {
//         "name": "Группа / столбец 2",
//         "rows": ["3", "4", "5"]
//     }
// ],
// "correct": [
//     { "3": "33", "4": "44" },
//     { "3": "333", "4": "444", "5": "555" },
// ],

export default {
  props: ["question"],
  emits: ["update"],
  components: {
    AnswerField,
    CheckMark,
    Button,
  },
  data() {
    return {
      groups: [
        {
          name: "1",
          rows: ["3", "4"],
        },
        {
          name: "2",
          rows: ["3", "4"],
        },
      ],
      correct: [
        { 3: "", 4: "" },
        { 3: "", 4: "" },
      ],
    }
  },
  mounted() {
    if (
      this.question.groups &&
      Array.isArray(this.question.groups) &&
      Array.isArray(this.question.groups[0].rows)
    ) {
      this.groups = this.question.groups
    }
    if (
      this.question.correct &&
      Array.isArray(this.question.correct) &&
      typeof this.question.correct[0] == "object"
    ) {
      this.correct = this.question.correct
    }
  },
  methods: {
    render() {
      return cloneDeep({
        groups: this.groups,
        correct: this.correct,
      })
    },
    emitUpdate() {
      this.$emit("update", this.render())
    },
    generateEmptyGroup(groupBefore) {
      return {
        name: "",
        rows: groupBefore ? groupBefore.rows : [1],
      }
    },
    generateEmptyCorrectRow(group) {
      let row = {}
      each(group.rows, (groupRowNumber) => {
        row[groupRowNumber] = ""
      })
      return row
    },
    addGroup(groupBefore) {
      const newIndex = this.groups.indexOf(groupBefore) + 1
      const newGroup = this.generateEmptyGroup(groupBefore)
      this.groups.splice(newIndex, 0, newGroup)
      this.correct.splice(newIndex, 0, this.generateEmptyCorrectRow(newGroup))
      this.emitUpdate()
    },
    removeGroup(group) {
      // Disallow to remove the only group
      if (this.groups.length == 1) return
      // Delete group by its index
      const index = this.groups.indexOf(group)
      if (index !== -1) {
        this.groups.splice(index, 1)
        this.correct.splice(index, 1)
        // Propagate updates
        this.emitUpdate()
      }
    },
    addRow(group, rowBefore) {
      const rowNumbersList = group.rows.map((a) => parseInt(a))
      const newRowNumber = "" + (Math.max(...rowNumbersList) + 1)
      const newRowIndex = group.rows.indexOf(rowBefore) + 1
      const groupIndex = this.groups.indexOf(group)
      group.rows.splice(newRowIndex, 0, newRowNumber)
      this.correct[groupIndex][newRowNumber] = ""
      this.emitUpdate()
    },
    removeRow(group, row) {
      if (group.rows.length == 1) return
      const rowIndex = group.rows.indexOf(row)
      if (rowIndex !== -1) {
        group.rows.splice(rowIndex, 1)
        const groupIndex = this.groups.indexOf(group)
        delete this.correct[groupIndex][row]
        this.emitUpdate()
      }
    },
    editRowNumber(group, row, event) {
      const rowIndex = group.rows.indexOf(row)
      if (rowIndex !== -1) {
        group.rows[rowIndex] = event.target.value
        const groupIndex = this.groups.indexOf(group)
        this.correct[groupIndex][event.target.value] =
          this.correct[groupIndex][row]
        delete this.correct[groupIndex][row]
        this.emitUpdate()
      }
    },
    editRowAnswer(group, row, event) {
      const rowIndex = group.rows.indexOf(row)
      if (rowIndex !== -1) {
        const groupIndex = this.groups.indexOf(group)
        this.correct[groupIndex][row] = event.target.value
        this.emitUpdate()
      }
    },
  },
}
</script>

<style scoped>
.answer_input {
  width: 100%;
  font-size: 17px;
}
.answer_button_with_icon {
  height: 40px;
  width: 40px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0;
  margin: 0;
  background-color: var(--white) !important;
  position: relative;
}
.divider_with_buttons__container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  gap: 15px;
}
.divider_with_buttons__buttons_container {
  display: flex;
  align-items: center;
  gap: 12px;
}
.divider_with_buttons__header {
  display: inline-block;
}

.divider_with_buttons__header {
  font-family: "PTSansItalic";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
}
.divider_with_buttons__line {
  flex-grow: 1;
  border-bottom: 1px solid #e0e0e0;
  height: 1px;
}

.answer_row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 242px;
  padding-right: 21px;
  padding-bottom: 10px;
}
.answer_hint_2 {
  max-width: 225px;
  text-align: right;
}
.table_answers {
  width: 100%;
}

.table_answer_group {
  display: flex;
  padding-top: 10px;
  padding-right: 70px;
}

.table_answer_group >>> .answer_input {
  box-sizing: border-box;
}
.table_answer_group__hint {
  width: 250px;
  text-align: right;
  padding-right: 20px;
  box-sizing: border-box;
  padding-top: 17px;
  display: flex;
  flex-flow: column;
  gap: 3px;
}

.table_answer_group__answers {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
}
.table_answer_group__fields {
  flex: 1;
  padding-top: 3px;
}
.table_answer_group__fields input {
  height: 50px;
}
.table_answer_group__answer_row {
  display: flex;
  gap: 10px;
  position: relative;
}
.table_answer_group__answer_row > *:first-child,
.table_answer_group__answers_header > *:first-child {
  width: 80px;
  text-align: center;
}
.table_answer_group__answer_row > *:first-child {
  padding-left: 0;
}

.table_answer_group__answer_row > *:last-child,
.table_answer_group__answers_header > *:last-child {
  flex: 1;
}

.table_answer_group__answers_header > *:last-child {
  padding-left: 15px;
}
.table_answer_group__answers_header {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}
.answers_header__text {
  font-family: "PTSansItalic";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
  text-align: center;

  display: flex;
  padding-left: 5px;
}
.table_answer_group__answer_row__controls {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
}

.table_answer_groups >>> .remove_button:hover {
  background-color: var(--alert) !important;
}
.table_answer_groups >>> .add_button:hover {
  background-color: var(--greenz) !important;
}
.table_answer_groups >>> .remove_button:hover .icon-trash:before,
.table_answer_groups >>> .add_button:hover .icon-big_plus:before {
  color: white;
}
.table_answer_groups {
  margin-bottom: 20px;
}
</style>
