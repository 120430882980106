export const time_limit_options = [
  { label: "15 минут", value: 15 * 60 },
  { label: "20 минут", value: 20 * 60 },
  { label: "25 минут", value: 25 * 60 },
  { label: "30 минут", value: 30 * 60 },
  { label: "35 минут", value: 35 * 60 },
  { label: "40 минут", value: 40 * 60 },
  { label: "45 минут", value: 45 * 60 },
  { label: "50 минут", value: 50 * 60 },
  { label: "55 минут", value: 55 * 60 },
  { label: "60 минут", value: 60 * 60 },
]

export const defaultTable = `
<table style="border-collapse: collapse; width: 100%" border="1">
  <tbody>
    <tr>
      <td style="width: 10%"><strong>№</strong></td>
      <td><strong>Исх</strong></td>
      <td><strong>тип 1</strong></td>
      <td><strong>тип 2</strong></td>
    </tr>
    <tr>
      <td style="width: 10%">1</td>
      <td>сто один</td>
      <td>101</td>
      <td>3</td>
    </tr>
    <tr>
      <td style="width: 10%">2</td>
      <td>четыреста восемь</td>
      <td>408</td>
      <td>3</td>
    </tr>
    <tr>
      <td style="width: 10%">3</td>
      <td>десять</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
    </tr>
    <tr>
      <td style="width: 10%">4</td>
      <td>два</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
    </tr>
  </tbody>
</table>
`
