<template>
  <Layout url_prefix="teacher">
    <template #page_header>
      <!-- <div class="h1">Профиль</div> -->
      <div class="header_container" v-if="finishedLoading">
        <div class="h1">{{ grade.full_name }} класс</div>
        <div class="grade_comment" v-if="grade.comment">
          — {{ grade.comment }}
        </div>
      </div>
    </template>
    <template #page_content>
      <div v-if="finishedLoading">
        <div class="edit_grade_container">
          <router-link
            class="edit_grade"
            :to="{ name: 'teacher-grade-edit', params: grade.id }"
          >
            редактировать класс
          </router-link>
        </div>

        <!-- +++ There is ACTIVE assignment +++ -->
        <template v-if="activeAssignment">
          <ActiveAssignment
            :assignment="activeAssignment"
            :subject="activeAssignment.test.subject"
            :test="activeAssignment.test"
            :time_limit="activeAssignment.time_limit"
            @pauseAll="pauseAll"
          ></ActiveAssignment>

          <!-- ACTIVE assignment statistics table-->
          <table class="table">
            <thead>
              <tr>
                <th>ФИО ученика</th>
                <th>Вариант</th>
                <th>Текущий статус</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="session in activeAssignment.sessions"
                :key="session.id"
              >
                <td>
                  <router-link
                    class="link"
                    :to="{
                      name: 'teacher-student-edit',
                      params: { id: session.student.id },
                    }"
                  >
                    {{ session.student.fio }}
                  </router-link>
                </td>
                <td>{{ session.variant }}</td>
                <td>
                  <TestResultStatus
                    :session="session"
                    @show-mistakes="showMistakes"
                    :show_intermediate_results="true"
                  ></TestResultStatus>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <!-- --- There is NO active assignment --- -->
        <template v-else>
          <!-- Assignment -->
          <CreateAssignmentSubjectsTests
            :tests="tests"
            :students="students"
            @createAssignment="createAssignment"
          ></CreateAssignmentSubjectsTests>

          <!-- Detailed statistics for given assignment -->
          <Statistics
            :grade="grade"
            :tests="tests"
            :assignments="assignments"
            @buttonClicked="showFullStatistics"
          ></Statistics>

          <!-- Summary stats table (if no detailed statistics shown) -->
          <table class="table" v-if="!statistics">
            <thead>
              <tr>
                <th>ФИО ученика</th>
                <th>Успеваемость общая</th>
                <th>Среднее время</th>
                <th>Пройдено тестов</th>
                <th>На паузе</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="summary in summaryStatistics.results"
                :key="summary.student_id"
              >
                <td>
                  <router-link
                    class="link"
                    :to="{
                      name: 'teacher-student-edit',
                      params: { id: summary.student_id },
                    }"
                    >{{ summary.student_fio }}</router-link
                  >
                </td>
                <template v-if="summary.finished_sessions_count > 0">
                  <td>
                    <div class="progress">
                      <ProgressBar
                        :value="summary.performance"
                        bg_color="#FF0952"
                      ></ProgressBar>
                      <div style="line-height: 13px">
                        {{ summary.performance.toFixed(1) }}%
                      </div>
                    </div>
                  </td>
                  <td><TimeFromSeconds :time="summary.avg_seconds" /></td>
                  <td>{{ summary.finished_sessions_count }}</td>
                  <td>{{ summary.paused_sessions_count }}</td>
                </template>
                <template v-else>
                  <td>
                    <div class="progress">
                      <ProgressBar
                        :value="summary.performance"
                        bg_color="#F2F2F2"
                      ></ProgressBar>
                      <div style="line-height: 10px">—</div>
                    </div>
                  </td>
                  <td>—</td>
                  <td>—</td>
                  <td>—</td>
                </template>

                <!-- тут еще в ряде случаев вместо значений нужно выводить прочерки... -->
              </tr>
            </tbody>
          </table>

          <!-- Detailed statistics table -->
          <table class="table" v-if="statistics">
            <thead>
              <tr>
                <th>ФИО ученика</th>
                <!-- <th>на паузе</th> -->
                <th>Результат посл. теста</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="session in statistics.sessions" :key="session.id">
                <td>
                  <router-link
                    class="link"
                    :to="{
                      name: 'teacher-student-edit',
                      params: { id: session.student.id },
                    }"
                  >
                    {{ session.student.fio }}
                  </router-link>
                </td>
                <td>
                  <TestResultStatus
                    :session="session"
                    @show-mistakes="showMistakes"
                  ></TestResultStatus>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <MistakesModal ref="mistakesModal" />
        <Alert ref="alert" />

        <div class="spacer200"></div>
      </div>
    </template>
  </Layout>
</template>

<script>
import { grades, tests, assignments, students } from "@/api"
import { find } from "lodash"
import { io } from "socket.io-client"

import Layout from "@/components/Layouts/Layout.vue"
import TestResultStatus from "@/components/Functional/TestResultStatus.vue"

import AdaptiveModal from "@/components/UI/Modal_Adaptive.vue"
import ActiveAssignment from "@/components/Forms/ActiveAssignment.vue"
import Button from "@/components/UI/Button.vue"
import CreateAssignmentSubjectsTests from "@/components/Forms/CreateAssignmentSubjectsTests.vue"
import GradeForm from "@/components/Forms/GradeForm.vue"
import Statistics from "@/components/Forms/Statistics.vue"
import MistakesModal from "@/components/Functional/MistakesModal.vue"
import TimeFromSeconds from "@/components/UI/TimeFromSeconds.vue"
import ProgressBar from "@/components/UI/ProgressBar.vue"
import Alert from "@/components/Functional/Alert.vue"

export default {
  props: ["id"],
  components: {
    Layout,
    Button,
    GradeForm,
    CreateAssignmentSubjectsTests,
    Statistics,
    TestResultStatus,
    ActiveAssignment,
    AdaptiveModal,
    MistakesModal,
    TimeFromSeconds,
    ProgressBar,
    Alert,
  },
  data() {
    return {
      grade: null,
      tests: null,
      assignments: null,
      finishedLoading: false,
      statistics: null,
      summaryStatistics: null,
      activeAssignment: null,
      socket: null,
      sessionForModal: null,
      wrongAnsweredQuestions: null,
    }
  },
  mounted() {
    this.getAllData()
  },
  beforeUnmount() {
    if (this.socket) {
      this.socket.close()
    }
  },
  methods: {
    async getAllData() {
      this.grade = await grades.get(this.id)
      this.tests = await tests.list(this.id)
      this.assignments = await assignments.list({ grade: this.id })
      this.students = await students.list({ grade_id: this.id })

      // Check if there is an active assignment
      const activeAssignment = find(this.assignments, { finished_at: null })
      if (activeAssignment) {
        this.activeAssignment = await assignments.get(activeAssignment.id)
        // sort sessions by student.fio ascending
        this.sortStudentsByFio()
      }

      // Summary statistics
      this.summaryStatistics = await grades.statistics(this.id)

      this.finishedLoading = true
    },
    sortStudentsByFio() {
      if (this.activeAssignment) {
        this.activeAssignment.sessions.sort((a, b) => {
          if (a.student.fio < b.student.fio) return -1
          if (a.student.fio > b.student.fio) return 1
          return 0
        })
      }
    },
    async createAssignment(test_id, students, time_limit) {
      if (students.length) {
        await tests.assign_to_students(test_id, students, time_limit)
      } else {
        const response = await tests.assign_to_grade(
          test_id,
          this.id,
          time_limit
        )
        this.activeAssignment = response
      }

      this.$refs.alert.alert("Тест успешно назначен")

      this.sortStudentsByFio()
    },
    async showFullStatistics(test_id) {
      const lastAssignment = find(this.assignments, { test: test_id })
      if (lastAssignment) {
        this.statistics = await assignments.get(lastAssignment.id)
        this.sortStudentsByFio()
      } else {
        this.statistics = null
      }
    },
    async pauseAll() {
      const response = await assignments.pause_all(this.activeAssignment.id)
    },
    showMistakes(session) {
      this.$refs.mistakesModal.showMistakesForSession(session)
    },
  },
  watch: {
    activeAssignment(assignment) {
      if (assignment) {
        const ws_url = process.env.VUE_APP_WS_URL
        const ws_path = process.env.VUE_APP_WS_PATH
        const socket = io(ws_url, {
          path: ws_path,
          transports: ["websocket"],
          withCredentials: true,
        })

        this.socket = socket

        socket.on("connect", (s) => {
          socket.emit("join", assignment.secret)
        })
        socket.on("message", function (data) {
          data = JSON.parse(data)

          const action = data["action"]
          const session_id = data["session"]
          const updates = data["updates"]

          const session = find(assignment.sessions, {
            id: session_id,
          })

          switch (action) {
            case "start":
              session.status = "S"
              break
            case "answer":
              session.intermediate_result = updates
              break
            case "pause":
              session.status = "P"
              break
            case "resume":
              session.status = "S"
              break
            case "finish":
              session.result = updates
              session.status = updates.status
              break
          }
        })
      } else {
        if (this.socket) {
          this.socket.close()
        }
      }
    },
  },
}
</script>

<style scoped>
.edit_grade_container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.edit_grade {
  text-decoration: none;
  color: var(--greenz_link);
  font-size: 16px;
  border-bottom: 2px dashed var(--greenz_link);
  text-transform: uppercase;
}
.header_container {
  position: relative;
  width: 100%;
}
.grade_comment {
  position: absolute;
  width: fit-content;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* bottom: -8px; */
  transform: translateY(-50%);
  background: var(--purple);
  color: white;
  padding-top: 1px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 25px;
  font-style: italic;
}
.progress {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}
/* .h1 {
  margin-bottom: unset !important;
  margin-right: 20px;
} */
/* .header_container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
} */
/* .dotted_link {
  text-decoration: none;
} */
.table th {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.table th:nth-child(n + 2),
.table tr td:nth-child(n + 2) {
  text-align: center !important;
}

.table tr td:nth-child(2) {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: left !important; */
}

.table >>> .progress {
  justify-content: start;
  width: 152px;
  white-space: nowrap;
}
</style>
