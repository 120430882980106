<template>
  <!-- <div class="assignment_container">
    <div>
      <div class="h2 centered_header">Статистика теста</div>
      <p>Выберите интересующий тест из пройденных ранее.</p>
    </div>

    <div class="assignment_selects">
      <PixelionSelect
        class="vs_subject_select"
        :options="subject_options"
        :reduce="(option) => option.value"
        v-model="form_data.subject"
        :disabled="disabled"
        :searchable="false"
        :clearable="false"
        placeholder="Выберите предмет"
      />
      <PixelionSelect
        class="vs_test_select"
        :options="test_options"
        :reduce="(option) => option.value"
        v-model="form_data.test"
        :disabled="disabled"
        :searchable="false"
        :clearable="false"
        placeholder="Выберите тест"
        empty-text="Нет данных"
      />

      <Button
        class="button_purple"
        :style="{ visibility: form_data.test ? 'visible' : 'hidden' }"
        @click="buttonClicked"
      >
        Продолжить
      </Button>
    </div>

    <div class="statistics_comment" v-if="last_assignment_date">
      Прошлый раз назначен для {{ grade.full_name }}:
      {{ formatDate(last_assignment_date) }}
    </div>
  </div> -->
</template>

<script>
import "vue-select/dist/vue-select.css"
import vSelect from "vue-select"

import { filter, uniqBy } from "lodash"
import formatDate from "@/utils/formatDate"

import Button from "@/components/UI/Button"
import PixelionSelect from "../UI/PixelionSelect.vue"

export default {
  props: ["tests", "assignments", "grade"],
  components: {
    Button,
    vSelect,
    PixelionSelect,
  },
  data() {
    return {
      formatDate,
      form_data: {
        subject: null,
        test: null,
      },
      last_assignment_date: null,
      disabled: false,
    }
  },
  computed: {
    subject_options() {
      if (!this.tests) return []
      const uniqueSubjectsTests = uniqBy(this.tests, "subject.id")
      return uniqueSubjectsTests.map((test) => {
        return {
          label: test.subject.name,
          value: test.subject.id,
        }
      })
    },
    test_options() {
      if (!this.tests) return []
      return this.tests
        .filter((test) => {
          return test.subject.id == this.form_data.subject
        })
        .map((test) => {
          return {
            label: test.name,
            value: test.id,
          }
        })
    },
  },
  methods: {
    buttonClicked() {
      this.$emit("buttonClicked", this.form_data.test)
    },
  },
  watch: {
    "form_data.subject"() {
      this.form_data.test = null
    },
    "form_data.test"() {
      const filteredAssignments = filter(this.assignments, {
        test: this.form_data.test,
      })
      if (filteredAssignments.length > 0) {
        this.last_assignment_date = filteredAssignments[0].started_at
      } else {
        this.last_assignment_date = null
      }
    },
  },
}
</script>

<style scoped>
.h2 {
  margin-bottom: 0 !important;
  margin-top: 45px !important;
}
.assignment_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  flex-flow: column wrap;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 50px;
}
.assignment_selects {
  display: flex;
  /* justify-content: center; */
  flex-flow: row nowrap;
  gap: 10px;
  /* max-width: 522px; */
  position: relative;
}
.statistics_comment {
  /* position: absolute;
  bottom: -50px; */
  width: 100%;
  text-align: center;
}
.vs_subject_select {
  width: 270px !important;
  width: fit-content;
}
.vs_test_select {
  width: 400px !important;
  width: fit-content;
}
.vs_limit_select {
  min-width: 100px;
  width: fit-content;
}
.h3 {
  font-family: PTSansWebRegular;
}
</style>

<style>
.vs_limit_select .vs__dropdown-menu {
  max-width: 100px !important;
  min-width: unset !important;
}
</style>
