<template>
  <Layout url_prefix="admin">
    <template #page_header>
      <div class="h1" v-if="finishedLoading">
        {{ school.name_short }}
      </div>
    </template>

    <template #page_content>
      <div class="buttons_with_legend__container">
        <div class="button_with_legend">
          <Button @click="loginAsDirector">Зайти как директор</Button>
        </div>
        <div class="button_with_legend">
          <Button
            class="button_purple button_outline"
            @click="$refs.passwordModal.open()"
          >
            Сменить пароль
          </Button>
          <p>Будут изменены пароли всех учетных записей школы.</p>
        </div>
        <div class="button_with_legend">
          <Button
            @click="toggleSchoolDisabled"
            class="button_gray button_with_icon"
          >
            <img src="@/assets/img/icon_disable.svg" />
            <template v-if="school.is_disabled"> Включить </template>
            <template v-else>Отключить школу </template>
          </Button>
          <p>Пароли сохранятся, но никто не сможет зайти, кроме админа</p>
        </div>
      </div>

      <div class="right_shifted_form" v-if="finishedLoading">
        <SchoolForm
          v-if="school"
          :school="school"
          @form_data_changed="formDataChanged"
          ref="schoolForm"
          class="mb30"
        ></SchoolForm>
        <div class="form_buttons">
          <div class="buttons__container__without_grow">
            <Button class="button_top" @click="save"
              >Сохранить изменения</Button
            >
            <Button @click="exit" class="button_gray"
              >Выйти без сохранения</Button
            >
          </div>
        </div>
      </div>
      <div class="spacer200"></div>
      <AdaptiveModal ref="passwordModal">
        <ChangePasswordForm
          @exit="$refs.passwordModal.close()"
          @save="changePassword"
        ></ChangePasswordForm>
      </AdaptiveModal>
      <Alert ref="alert" />
    </template>
  </Layout>
</template>

<script>
import { schools } from "@/api"
import { changeSubordinatePassword, loginAs } from "@/api/auth"

import Button from "@/components/UI/Button"
import Layout from "@/components/Layouts/Layout"
import SchoolForm from "@/components/Forms/SchoolForm"
import AdaptiveModal from "@/components/UI/Modal_Adaptive.vue"
import Alert from "@/components/Functional/Alert.vue"

import ChangePasswordForm from "@/components/Forms/ChangePasswordForm"

export default {
  components: {
    Layout,
    SchoolForm,
    Button,
    AdaptiveModal,
    ChangePasswordForm,
    Alert,
  },
  props: ["id"],
  data() {
    return {
      school: {
        id: null,
        inn: "",
        region: null,
        city: "",
        director_fio: "",
        contact_person: "",
        name_short: "",
        name_full: "",
        email: "",
        phone: "",
        created_at: "",
        is_disabled: false,
        region_verbose: "",
      },
      finishedLoading: false,
    }
  },
  mounted() {
    this.loadSchool()
  },
  methods: {
    async loadSchool() {
      this.school = await schools.get(this.id)
      this.finishedLoading = true
    },
    formDataChanged(data) {
      this.school = data
    },
    async save() {
      const form_checked = this.$refs.schoolForm.checkFields()
      if (!form_checked) {
        this.$refs.alert.alert("Пожалуйста, заполните все обязательные поля.")
        return
      }

      try {
        const response = await schools.update(this.school.id, this.school)
        this.$router.push({ name: "admin-index" })
      } catch (error) {
        console.log(error)
        if (error.response.status == 400) {
          if (error.response.data.inn) {
            this.$refs.alert.alert("Школа с таким ИНН уже существует.")
          } else {
            if (
              error.response.data.email &&
              error.response.data.email == "Email already exists"
            ) {
              this.$refs.alert.alert(
                "Пользователь с такой электронной почтой уже существует в базе данных."
              )
            } else {
              this.$refs.alert.alert(
                "Не удалось создать школу. Пожалуйста, заполните все поля правильно."
              )
            }
          }
        } else {
          this.$refs.alert.alert(
            "Возникла ошибка сервера 500 при редактировании школы. Пожалуйста, сообщите об этом разработчикам. Ошибка: " +
              error.response.data.message
          )
        }
      }
    },
    async toggleSchoolDisabled() {
      this.school.is_disabled = !this.school.is_disabled
      await schools.update(this.school.id, {
        is_disabled: this.school.is_disabled,
      })
    },
    async changePassword(password) {
      try {
        const response = await changeSubordinatePassword(
          this.school.director_user_id,
          password
        )
        this.$refs.passwordModal.close()
        this.$refs.alert.alert("Пароль успешно изменен.")
      } catch (error) {
        console.log(error)
        this.$refs.passwordModal.close()
        this.$refs.alert.alert(
          "Возникла ошибка сервера при изменении пароля. Пожалуйста, сообщите об этом разработчикам. Ошибка: " +
            error.response.data.message
        )
      }
    },
    async loginAsDirector() {
      // if (this.school.is_disabled) {
      //   this.$refs.alert.alert("Нельзя зайти в отключенную школу.")
      //   return
      // }
      try {
        await loginAs(this.school.director_user_username)
        this.$router.replace({ name: "director-index" })
      } catch (error) {
        console.log(error)
        this.$refs.alert.alert(
          "Ошибка сервера при логине как директор. Ошибка: " +
            error.response.data.message
        )
      }
    },
    exit() {
      this.$router.push({ name: "admin-index" })
    },
  },
}
</script>

<style scoped>
.buttons_with_legend__container {
  display: flex;
  justify-content: center;
  gap: 35px;
  max-width: 1000px;
}
.button_with_legend {
  display: flex;
  flex-flow: column;
  width: 310px;
}
.button_with_legend .button {
  white-space: nowrap;
}
.centered_form__input_row label {
  padding-right: 35px;
  padding-bottom: 2px;
}
</style>
