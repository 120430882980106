<template>
  <div class="half_screen_bg">
    <div class="background">
      <img class="answer_button" src="@/assets/img/login_bg.png" />
    </div>
    <div class="centered_form__container">
      <img
        class="st-logo"
        src="@/assets/img/st_logo.png"
        @click="$router.push({ name: 'login' })"
      />
      <div class="centered_form">
        <div class="centered_form__header h1">Введите новый пароль</div>
        <div class="centered_form__input_container">
          <input
            type="password"
            placeholder="Новый пароль"
            v-model="password"
            :autofocus="true"
          />
          <input
            type="password"
            placeholder="Повторите пароль"
            v-model="password_repeat"
          />
          <Button class="login_button button_top" @click="changePassword">
            Изменить пароль
          </Button>
        </div>
      </div>
    </div>

    <Alert ref="alert" />
  </div>
</template>

<script>
import { recoveryProceed } from "@/api/auth"
import redirectProfile from "@/router/redirectProfile"
import Alert from "@/components/Functional/Alert.vue"
import Button from "@/components/UI/Button.vue"

export default {
  components: {
    Alert,
    Button,
  },
  data() {
    return {
      token: "",
      password: "",
      password_repeat: "",
    }
  },
  mounted() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token
    } else {
      this.$router.push({ name: "login" })
    }
  },
  methods: {
    async changePassword() {
      if (this.password !== this.password_repeat) {
        this.$refs.alert.alert("Пароли не совпадают")
        return
      }
      try {
        const profile = await recoveryProceed(this.token, this.password)
        redirectProfile(profile, "home")
      } catch (error) {
        this.$refs.alert.alert(
          "Возникла ошибка. Возможно, ссылка на восстановление пароля уже устарела."
        )
      }
    },
  },
}
</script>

<style></style>

<style scoped>
.centered_form {
  /* padding-top: 200px; */
  width: 500px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}
.centered_form__input_container {
  width: 310px;
  display: flex;
  flex-flow: column wrap;
  gap: 10px;
  /* justify-content: center; */
}
.centered_form__input_container .button {
  margin-top: 15px;
  align-self: flex-start;
}
.half_screen_bg {
  display: flex;
  flex-flow: row;
  flex-basis: 50%;
  margin-left: -50px;
  margin-right: -50px;
}
.background {
  height: 100vh;
  width: 100%;
  background-color: aquamarine;
}
.background img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: right;
}
.centered_form__container {
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  padding-left: 122px;
  align-items: flex-start;
  height: 100vh;
  width: 80%;
  gap: 180px;
}
.st-logo {
  padding-top: 64px;
}

.password_recovery_link {
  padding-left: 24px;
  color: var(--greenz_link);
}
.login_button {
  width: 150px;
}
</style>
